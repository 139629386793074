import { Button, Card, Col, DatePicker, Form, Input, Row, message, Select } from "antd";
import { FiList } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import Layout from "../../Components/Layout";
import { useState } from "react";
import axios from "../../config/axios";

const CreateExpense = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const handleExpenseCreate = async (values) => {
        values.date = values.date.format("YYYY-MM-DD");
        try {
            setLoading(true);
            await axios.post("/admin/inventory/expenses/v1", values, {withCredentials: true});
            message.success("Expense record created successfully!");
            history.push("/expenses");
        } catch (error) {
            message.error("Something went wrong! Please try again later.");
        } finally {
            setLoading(false);
            form.resetFields();
        }
    };

    return (
        <Layout>
            <Card
                bordered={false}
                bodyStyle={{
                    padding: 15,
                }}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">Create New Expense Record</h3>
                    <Link to={"/expenses"}>
                        <Button type="primary" className="d-flex align-items-center justify-content-center gap-2">
                            <FiList size={20}/>
                            <span>See All Expenses</span>
                        </Button>
                    </Link>
                </div>
            </Card>
            <br/>
            <Card
                bordered={false}
                bodyStyle={{
                    padding: 15,
                }}
            >
                <Form
                    onFinish={handleExpenseCreate}
                    autoComplete="off"
                    layout="vertical"
                    requiredMark={false}
                    scrollToFirstError
                    form={form}
                >
                    <Row gutter={[20]}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Category *"
                                name="category"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input category!",
                                    },
                                ]}
                            >
                                {/*<Input placeholder="buy ingredient" size="large" />*/}
                                <Select placeholder={'Select Expense Category'} size={'large'}>
                                    <Select.Option value={'Daily Shop Expense'}>Daily Shop Expense</Select.Option>
                                    <Select.Option value={'Inventory'}>Inventory</Select.Option>
                                    <Select.Option value={'Fixed Expense'}>Fixed Expense</Select.Option>
                                    <Select.Option value={'Others'}>Others</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Amount *"
                                name="amount"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input amount!",
                                    },
                                ]}
                            >
                                <Input type="number" step={"any"} placeholder="200" size="large"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Form.Item
                                label="Amount Source *"
                                name="amount_source"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input amount source!",
                                    },
                                ]}
                            >
                                {/*<Input placeholder="petty cash" size="large" />*/}
                                <Select placeholder={'Select Amount Source'} size={'large'}>
                                    <Select.Option value={'Petty Cash'}>Petty Cash</Select.Option>
                                    <Select.Option value={'Bank'}>Bank</Select.Option>
                                    <Select.Option value={'External Input'}>External Input</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Form.Item
                                label="Expense For *"
                                name="expense_for"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Expense For",
                                    },
                                ]}
                            >
                                <Select placeholder={'Select Expense For'} size={'large'}>
                                    <Select.Option value={'food'}>Food</Select.Option>
                                    <Select.Option value={'disposable'}>Disposable</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Form.Item
                                label="Date *"
                                name="date"
                                rules={[
                                    {
                                        type: "object",
                                        required: true,
                                        message: "Please select date!",
                                    },
                                ]}
                            >
                                <DatePicker className="w-100" format={"DD-MM-YYYY"} size="large"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item label="Description" name="description">
                                <Input.TextArea rows={4} placeholder="Write description..."/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    block
                                    size="large"
                                    loading={loading}
                                    disabled={loading}
                                >
                                    Create New Expense Record
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Layout>
    );
};

export default CreateExpense;
