import React from 'react';
import { useState, useEffect } from 'react';
import axios from '../../config/axios';

//imoprting all components
import {
    Col,
    Drawer,
    Row,
    Select,
    Tag,
    Badge,
    message,
    Timeline, Button, Modal, Input
} from 'antd';
import {
    ShoppingCartOutlined,
    UserOutlined,
    DropboxOutlined,
    CreditCardOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import OrderCard from './OrderCard';
import { Link } from "react-router-dom";

const SingleOrder = ({singleOrderID, setSingleOrderID, singleOrder, setSingleOrder, refresh}) => {

    const [order, setOrder] = useState();
    const [visibility, setVisibility] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showTrackModal, setShowTrackModal] = useState(false);

    const [pRefresh, setPRefresh] = useState(null);

    const [code, setCode] = useState('');
    const [courier, setCourier] = useState('');
    const [trackingData, setTrackingData] = useState([]);
    const [refundLoading, setRefundLoading] = useState(false);

    useEffect(() => {
        console.log('re render');
        if (singleOrder) {
            setVisibility(true);
            setOrder(singleOrder);
            setLoading(false);
            handleOrderTracking(singleOrder._id);

        } else if (singleOrderID) {
            //opening drawer if orderID prop is passed
            setVisibility(true);
            //setLoading(true);

            axios.get(`/admin/orders/v1/filterByOrderID/${singleOrderID}`, {withCredentials: true}).then(response => {

                setOrder(response.data.order);
                console.log(response.data.order);
                setLoading(false);
                handleOrderTracking(response.data.order._id);

            }).catch(error => {
                if (error.response.status === 404) {
                    message.error({
                        content: `No order associated with this id was found!`,
                    })
                }
            })
        }

        setTrackingData([]);

    }, [singleOrderID, singleOrder, pRefresh]);

    function handleOrderTracking(orderID) {
        axios.get(`/public/orders/v1/tracking/${orderID}`, {withCredentials: true}).then(response => {
            if (!response.data.err) {
                setTrackingData(response.data.results);
            }

        }).catch(error => {
            message.error(error.response?.data?.msg ? error.response?.data?.msg : 'A Server Side Error Occurred');
        })
    }

    //defining onClose function
    const onCloseHandler = () => {

        if (setSingleOrderID) {
            setSingleOrderID(null);
        }

        if (setSingleOrder) {
            setSingleOrder(null);
        }

        setVisibility(false);
        setLoading(true);

    }


    //defining printLabel function
    const printInvoice = (orderID) => {
        axios(`/admin/microservices/v1/generate-order-invoice/${orderID}`, {
            method: 'GET',
            responseType: 'blob',
            withCredentials: true
        }).then(response => {
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data],
                {type: 'application/pdf'});
            //Build a URL from the file
            var fileURL = URL.createObjectURL(file);
            //Open the URL on new Window

            document.querySelector('#pdf-frame').src = '';
            document.querySelector('#pdf-frame').src = fileURL;
            fileURL = URL.revokeObjectURL(file);

            window.setTimeout(function () {
                document.querySelector('#pdf-frame').contentWindow.print();
            }, 100)

        }).catch(error => {
            console.log(error);
        })
    }

    const updateOrderStatus = status => {
        axios.patch(`/admin/orders/v1/payment-status/${order._id}`, {payment_status: status}, {withCredentials: true}).then(response => {
            message.success('Order Payment Status Updated Successfully!');
        }).catch(error => {
            message.error('A Server Side Error Occurred! Try again later');
        })
    }

    const updateOrderTrackingInfo = () => {
        if (!courier) {
            message.error('Please select a courier');
            return;
        }

        if (!code) {
            message.error('Please enter a valid code');
            return;
        }

        let payload = {
            code,
            courier
        }

        axios.patch(`/admin/orders/v1/tracking/${order._id}`, payload, {withCredentials: true}).then(response => {
            message.success('Order Tracking Code Updated and SMS sent to user');

            window.open(`/label/${order.orderID}`);

            setPRefresh(Math.random());
            refresh();

            setShowTrackModal(false);

            if (setSingleOrderID) {
                setSingleOrderID(null);
            }

            if (setSingleOrder) {
                setSingleOrder(null);
            }

            setVisibility(false);
            setLoading(true);
        }).catch(error => {
            message.error(error.response?.data?.msg ? error.response?.data?.msg : 'A Server Side Error Occurred');
        });
    }

    const bkashRefund = () => {
        setRefundLoading(true);
        axios.post(`/admin/orders/refund/bkash/${order.orderID}`, {amount: order.totalBill.toString()}, {withCredentials: true}).then(response => {
            message.success(response.data.msg);
            axios.get(`/admin/orders/v1/filterByOrderID/${order.orderID}`, {withCredentials: true}).then(response => {

                setOrder(response.data.order);
                console.log(response.data.order);
                setLoading(false);
                handleOrderTracking(response.data.order._id);

            }).catch(error => {
                if (error.response.status === 404) {
                    message.error({
                        content: `No order associated with this id was found!`,
                    })
                }
            })
        }).catch(err => {
            message.error(err.response.data.msg)
            setRefundLoading(false);
        }).finally(() => {
            setRefundLoading(false);
        })
    }

    return (
        <>

            <iframe id="pdf-frame"
                    style={{display: 'none'}}
            ></iframe>

            <Drawer
                width={'90%'}
                placement="right"
                closable={true}
                onClose={onCloseHandler}
                visible={visibility}
            >
                {
                    loading ?
                        <div style={{height: '60vh'}} className="loader"><img src="/loader.svg" alt=""/></div>
                        :
                        <div>
                            {
                                order.sales_channel === 'online' ?
                                    null
                                    :
                                    <h3 className="drawer-title">Token#{order.token} - {order.contact?.name}</h3>
                            }

                            <div className="ant-drawer-body-wrapper">

                                <p className="title-primary">
                                    <ShoppingCartOutlined/>
                                    Order Info
                                    <a href={`https://vapexbd.com/inv/${order.orderID}`} target={'_blank'} style={{
                                        textAlign: 'right',
                                        marginLeft: 'auto',
                                        color: 'var(--color-red)',
                                        cursor: 'pointer'
                                    }}>
                                        <span
                                            className="am"
                                        >
                                            <svg viewBox="0 0 24 24" width={24} height={24} stroke="currentColor"
                                                 strokeWidth={2} fill="none" strokeLinecap="round"
                                                 strokeLinejoin="round"><polyline
                                                points="6 9 6 2 18 2 18 9"/><path
                                                d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"/><rect
                                                x={6} y={14} width={12} height={8}/></svg>
                                        </span>
                                    </a>
                                </p>
                                <Row>

                                    <Col md={24} lg={12}>


                                        <div className="info">
                                            <span className="info__1">Order ID: </span>
                                            <span className="info__2">{order.orderID}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Timestamp: </span>
                                            <span
                                                className="info__2">{moment(order.timestamp).format('MMM D, YYYY - LT')}</span>
                                        </div>
                                        {/* <div className="info">
                                            <span className="info__1">Order Status: </span>
                                            <Select value={order.status} style={{ width: 300, border: order.status === 7 ? '1px solid red' : null, marginTop: '1rem' }}>
                                                <Option value={0}>On Hold</Option>
                                                <Option value={1}>Ordered</Option>
                                                <Option value={2}>Processing</Option>
                                                <Option value={3}>In Transit</Option>
                                                <Option value={4}>RBC</Option>
                                                <Option value={5}>Picked</Option>
                                                <Option value={6}>Delivered</Option>
                                                <Option value={7}>Cancelled</Option>
                                            </Select>
                                        </div> */}
                                        <div className="info">
                                            <span className="info__1">Sub Total: </span>
                                            <span className="info__2">
                                                <Badge overflowCount={100000000000}
                                                       style={{backgroundColor: 'var(--color-awesome)'}}
                                                       count={order.totalBill - order.deliveryCharge + ' BDT'}/>
                                            </span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Delivery Charge: </span>
                                            <span className="info__2">
                                                <Badge overflowCount={100000000000}
                                                       style={{backgroundColor: 'var(--color-awesome)'}}
                                                       count={order.deliveryCharge + ' BDT'}/>
                                            </span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Discount: </span>
                                            <span className="info__2">
                                                <Badge overflowCount={100000000000}
                                                       style={{backgroundColor: 'var(--color-red)'}}
                                                       count={order.discount + ' BDT'}/>
                                            </span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Total Bill: </span>
                                            <span className="info__2">
                                                <Badge overflowCount={100000000000}
                                                       style={{backgroundColor: 'var(--color-awesome)'}}
                                                       count={order.totalBill + ' BDT'}/>
                                            </span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Notes: </span>
                                            <span className="info__2">
                                                {order.orderNote}
                                            </span>
                                        </div>

                                    </Col>
                                    <Col md={24} lg={12}>

                                    </Col>
                                </Row>


                                <p style={{marginTop: '2rem'}} className="title-primary"><DropboxOutlined/>Order Items
                                </p>

                                <Row>
                                    <Col md={24} lg={24}>


                                        <OrderCard
                                            products={order.products}
                                        />

                                    </Col>
                                </Row>

                                <p style={{marginTop: '2rem'}} className="title-primary">
                                    <UserOutlined/>
                                    Customer Info
                                </p>

                                <Row>
                                    <Col md={24} lg={12}>
                                        <div className="info">
                                            <span className="info__1">Name: </span>
                                            <span className="info__2">{order.contact.name}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Phone Number: </span>
                                            <span className="info__2">0{order.contact.phoneNumber}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">District: </span>
                                            <span className="info__2">{order.deliveryAddress?.district}</span>
                                        </div>
                                    </Col>
                                    <Col md={24} lg={12}>
                                        <div className="info">
                                            <span className="info__1">Sub District: </span>
                                            <span className="info__2">{order.deliveryAddress?.subDistrict}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Address Line One: </span>
                                            <span className="info__2">{order.deliveryAddress?.addressLineOne}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Address Line Two: </span>
                                            <span
                                                className="info__2">{order.deliveryAddress?.addressLineTwo ? order.deliveryAddress?.addressLineTwo : "N/A"}</span>
                                        </div>
                                    </Col>
                                </Row>
                                <p style={{marginTop: '2rem'}} className="title-primary"><CreditCardOutlined/> Payment
                                    Details </p>
                                <Row>
                                    <Col md={24} lg={12}>
                                        <div className="info">
                                            <span className="info__1">Payment Status: </span>

                                            <Select onChange={status => updateOrderStatus(status)}
                                                    defaultValue={order.payment.status}>
                                                <Select.Option value={'paid'}>Paid</Select.Option>
                                                <Select.Option value={'due'}>Due</Select.Option>
                                            </Select>

                                        </div>
                                        <div className="info">
                                            <span className="info__1">Payment Method: </span>
                                            <span className="info__2">
                                                <Tag
                                                    className={'text-uppercase'}
                                                    color={order.payment.method ? 'success' : 'error'}>
                                                    {order.payment.method || 'N/A'}
                                                </Tag>
                                            </span>
                                        </div>
                                        {
                                            order.redeemed_promo &&
                                            <div className="info">
                                                <span className="info__1">Redeemed Promo: </span>
                                                <span className="info__2"> <Tag
                                                    className={'text-uppercase'}>{order.redeemed_promo} - {order.discount} BDT</Tag> </span>
                                            </div>
                                        }

                                        {
                                            order.sales_channel === 'online' && order.payment.method === 'bKash' && order.payment.bkashResponse ?
                                                <div>
                                                    {
                                                        Object.entries(JSON.parse(order.payment.bkashResponse)).map((item, index) => {
                                                            return <div className="info">
                                                                <span style={{textTransform: 'capitalize'}}
                                                                      className="info__1">{item[0]}: </span>
                                                                <span className="info__2"> <Tag
                                                                    className={'text-uppercase'}>{item[1]}</Tag> </span>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                :
                                                null
                                        }


                                    </Col>
                                    <Col md={24} lg={12}>
                                        {
                                            order.sales_channel === 'online' && order.payment.method === 'bKash' && !order.refundResponse &&
                                            <Button
                                                danger={true}
                                                type={'primary'}
                                                onClick={bkashRefund}
                                                loading={refundLoading}
                                                disabled={refundLoading}
                                            >
                                                Make bKash Refund
                                            </Button>
                                        }
                                         {
                                            order.refundResponse ?
                                                <div>
                                                    <span style={{marginBottom: '2rem', fontWeight: 'bold', display: 'block'}}>Refund TRX Details:</span>
                                                    {
                                                        Object.entries(JSON.parse(order.refundResponse)).map((item, index) => {
                                                            return <div className="info">
                                                                <span style={{textTransform: 'capitalize'}}
                                                                      className="info__1">{item[0]}: </span>
                                                                <span className="info__2"> <Tag
                                                                    className={'text-uppercase'}>{item[1]}</Tag> </span>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                :
                                                null
                                        }

                                    </Col>
                                </Row>

                                <p style={{marginTop: '2rem'}} className="title-primary"><ClockCircleOutlined/> Order
                                    Tracking </p>
                                <Button
                                    type={'primary'}
                                    onClick={() => setShowTrackModal(true)}>
                                    Add Tracking Code
                                </Button>
                                <br/>
                                <br/>

                                <Row>
                                    {
                                        order.tracking?.courier &&
                                        <Col md={24} lg={12}>
                                            <a href={`/label/${order.orderID}`} target={'_blank'}>
                                                <Button danger={true}>Print Label</Button>
                                            </a>
                                            <br/>
                                            <br/>
                                            <div className="info">
                                                <span style={{textTransform: 'capitalize'}}
                                                      className="info__1 text-capitalize">Courier: </span>
                                                <span className="info__2 text-uppercase">{order.tracking.courier}</span>
                                            </div>
                                            <div className="info">
                                                <span className="info__1">Tracking Code: </span>
                                                <span className="info__2">{order.tracking.code}</span>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                                <br/>
                                <Row>
                                    <Col md={24} lg={24}>

                                        <Timeline mode={'left'}>
                                            {
                                                trackingData.map(event => {
                                                    return <Timeline.Item key={event.timestamp}
                                                                          label={moment(event.timestamp).format('MMMM D, YYYY-LT')}> {event.msg} </Timeline.Item>
                                                })
                                            }

                                        </Timeline>

                                    </Col>
                                </Row>
                            </div>
                        </div>
                }
            </Drawer>

            <Modal
                open={showTrackModal}
                onCancel={() => setShowTrackModal(false)}
                footer={null}
                title={'Add Tracking Info'}
            >
                <label htmlFor="">Courier</label>
                <Select value={courier} onChange={e => setCourier(e)} style={{width: '100%'}}
                        placeholder={'Select Courier'}>
                    <Select.Option value={'redx'}>RedX</Select.Option>
                    <Select.Option value={'pathao'}>Pathao</Select.Option>
                </Select>
                <br/><br/>

                <label htmlFor="">Tracking Code</label>
                <Input value={code} onChange={e => setCode(e.target.value)} type={'text'} placeholder={'Enter Code'}/>
                <br/><br/>

                <Button onClick={updateOrderTrackingInfo} type={'primary'}>Add Tracking Info and Notify User</Button>

            </Modal>

        </>
    );

}

export default SingleOrder;