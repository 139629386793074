import React, { useEffect, useState } from 'react';
import axios from '../../config/axios';
import {
    message,
    Popconfirm, Switch
} from 'antd';
import { numberInputOnWheelPreventChange } from "../../utils";

const VariantItem = ({ variant, productID, refresh }) => {

    const [stockValue, setStockValue] = useState(0);
    const [discountValue, setDiscountValue] = useState(0);
    const [pxp, setPxp] = useState(0);

    useEffect(() => {
        console.log(variant.discount);
        setStockValue(variant.stock);
        setDiscountValue(variant.discount ? variant.discount : 0);
        setPxp(variant.pxp);
    }, []);


    //defining onChangeVariantStockHandler function
    const onChangeVariantStockHandler = (e) => {
        e.preventDefault();

        setStockValue(e.target.value);
    }

     const onChangeVariantDiscountHandler = (e) => {
        e.preventDefault();

        setDiscountValue(e.target.value);
    }


    //defining onChangeVariantStockHandler function
    const stockUpdateHandler = (SKU) => {
        console.log(stockValue, SKU);

        if (!stockValue || stockValue == '') {
            message.error({
                content: 'Minimum stock value is 0'
            })
        } else {
            let formData = new FormData;
            formData.append('stock', stockValue);
            formData.append('sku', SKU);

            axios.put(`/admin/products/v1/stock-update/${productID}`, formData, { withCredentials: true }).then(response => {

                message.success({
                    content: 'Product stock was updated'
                })

            })
        }
    }

    const discountUpdateHandler = (SKU) => {

        if (!discountValue || discountValue == '') {
            message.error({
                content: 'Invalid Discount Amount'
            })
        } else {
            let formData = new FormData;
            formData.append('discount', discountValue);
            formData.append('sku', SKU);

            axios.put(`/admin/products/v1/discount-update/${productID}`, formData, { withCredentials: true }).then(response => {

                message.success({
                    content: 'Variant discount was updated'
                })

            })
        }
    }

    //defining confirm and cancel function
    const deleteVariantHandler = (sku) => {

        let formData = new FormData;
        formData.append('sku', sku);

        axios.put(`/admin/products/v1/variant-delete/${productID}`, formData, { withCredentials: true }).then(response => {
            console.log(response.data);
            refresh();
            message.success({
                content: `Variant Was Deleted Successfully!`,

            })
        })

    }

    const cancel = e => {
        console.log("No");
    }

    const variantUpdateHandler = (payload) =>{
         axios.put(`/admin/products/v1/update-variant/${productID}`, payload, { withCredentials: true }).then(response => {
            // console.log(response.data);
            refresh();
            message.success({
                content: `Variant Was Updated Successfully!`,

            })
        })
    }



    return (
        <>
            <tr className="align-middle">
                <td> {variant.sku} </td>
                <td> {variant.color} </td>
                <td>
                    <input onWheel={numberInputOnWheelPreventChange} style={{ width: '70px' }} onChange={(e)=>setPxp(e.target.value)} value={pxp} type="number" className="binput"/>
                    <svg onClick={() => {
                        variantUpdateHandler({
                            sku: variant.sku,
                            pxp: pxp
                        })
                    }} viewBox="0 0 24 24" style={{ color: '#B7EB8F', cursor: 'pointer', marginLeft: '1rem' }} width={18} height={18} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round" ><polyline points="9 11 12 14 22 4" /><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" /></svg>
                </td>
                <td>
                    <Switch
                        checkedChildren={'Hidden'}
                        unCheckedChildren={'Not Hidden'}
                        defaultChecked={variant.isHidden}
                        onChange={value=>{
                          variantUpdateHandler({
                            sku: variant.sku,
                            isHidden: value
                        })
                        }}
                    />
                </td>
                <td>
                    <input style={{ width: '70px' }} onChange={onChangeVariantStockHandler} value={stockValue} type="number" className="binput" id={variant.sku} />
                    <svg onClick={() => stockUpdateHandler(variant.sku)} viewBox="0 0 24 24" style={{ color: '#B7EB8F', cursor: 'pointer', marginLeft: '1rem' }} width={18} height={18} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round" ><polyline points="9 11 12 14 22 4" /><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" /></svg>
                </td>
                <td>
                    <input style={{ width: '70px' }} onChange={onChangeVariantDiscountHandler} value={discountValue} type="number" className="binput" />
                <svg onClick={() => discountUpdateHandler(variant.sku)} viewBox="0 0 24 24" style={{ color: '#B7EB8F', cursor: 'pointer', marginLeft: '1rem' }} width={18} height={18} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round" ><polyline points="9 11 12 14 22 4" /><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11" /></svg>
                </td>
                <td>

                    <Popconfirm
                        title="Sure about deleting this variant?"
                        onConfirm={() => deleteVariantHandler(variant.sku)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <svg style={{ color: 'red', cursor: 'pointer', marginLeft: '1rem' }} viewBox="0 0 24 24" width={18} height={18} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /><line x1={10} y1={11} x2={10} y2={17} /><line x1={14} y1={11} x2={14} y2={17} /></svg>
                    </Popconfirm>
                </td>
            </tr>
        </>
    );
}

export default VariantItem;