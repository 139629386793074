import React, { useEffect, useState } from 'react';
import axios from '../config/axios';


//importing all components
import { Button, Col, Form, Input, InputNumber, Pagination, Row } from 'antd';
import Layout from '../Components/Layout';
import CustomerItem from '../Components/Customer/CustomerItem';
import CustomerDetails from '../Components/Customer/CustomerDetails';

const Customers = (props) => {

    const [form] = Form.useForm();

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [drawerOuter, setDrawerOuter] = useState('pop-drawer closed');
    const [drawerInner, setDrawerInner] = useState('pop-drawer__body-closed');
    const [curretPage, setCurrentPage] = useState(1);
    const [stat, setStat] = useState({totalPage: '', totalData: ''});
    const [singleUser, setSingleUser] = useState(null);
    const [filters, setFilters] = useState({});

    const refreshData = () => {
        setLoading(true);
        axios.get(`/admin/users/v1/getUsers/${curretPage}`, {
            withCredentials: true, params: {
                phone_number: filters.phone_number ? filters.phone_number : null,
                name: filters.name ? filters.name : null
            }
        }).then(response => {
            setUsers(response.data.users);
        }).finally(() => {
            setLoading(false);
        })
    }


    useEffect(() => {
        window.scrollTo(0,0);
        setLoading(true);
        axios.get(`/admin/users/v1/getUsers/${curretPage}`, {
            withCredentials: true, params: {
                phone_number: filters.phone_number ? filters.phone_number : null,
                name: filters.name ? filters.name : null
            }
        }).then(response => {


            setUsers(response.data.users);

            setStat({
                totalPage: response.data.totalPage,
                totalData: response.data.totalUsers
            });

            setLoading(false);
        });
    }, [filters, curretPage]);

    //defining openDrawer function
    const openDrawer = () => {
        setDrawerOuter('pop-drawer');
        setDrawerInner('pop-drawer__body');
    }

    //defining closeDrawer function
    const closeDrawer = () => {
        setDrawerOuter('pop-drawer closed');
        setDrawerInner('pop-drawer__body-closed');
    }

    //defining paginationHangler function
    const paginationHangler = (page) => {
        setCurrentPage(page);
    }


    return (
        <>

        <Layout>
            <h2 className="breadcrumb-custom"><span>Dashboard</span> {'>'} <span>Products</span> {'>'} <span> Customer Managment</span>
            </h2>


            <div className="dashboard-page-header">
                <div className="dashboard-page-header__left">
                    <h3 className="dashboard-page-header__left--title">All Customers</h3>
                </div>
                <div className="dashboard-page-header__right">
                    <Form
                        layout={'vertical'}
                        className={'d-flex align-items-center'}
                        form={form}
                        onFinish={(values) => setFilters(values)}
                    >
                        <Row gutter={[10,0]}>
                            <Col xs={12}>
                                <Form.Item name={'phone_number'} label={'Phone Number'}>
                                    <Input inputMode={'numeric'} placeholder={'Search by Phone Number'}/>
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item name={'name'} label={'Name'}>
                                    <Input type={'text'} placeholder={'Search by Name'}/>
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item label={'Filter'}>
                                    <Button htmlType={'submit'} type={'primary'}>Apply</Button>
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <Form.Item label={'Action'}>
                                    <Button onClick={() => {
                                        setFilters({});
                                        form.resetFields();
                                    }} danger={true}>Clear</Button>
                                </Form.Item>
                            </Col>
                            </Row>
                    </Form>
            </div>
        </div>


        {/* All Customers Here */}
        <div className="dashboard-table-wrapper">
            {
                loading ?
                    <div style={{height: '60vh'}} className="loader"><img src="/loader.svg" alt=""/></div>
                    :
                    <table className="table">
                        <tbody>
                        <tr style={{border: "1px solid var(--color-grey-green)"}}>
                            <th scope="row">Reg Date</th>
                            <th scope="row">Name</th>
                            <th scope="row">Cell Number</th>
                            {/*<th scope="row">Email</th>*/}
                            {/*<th style={{width: '120px'}} scope="row">Order(s)</th>*/}
                            {/*<th scope="row">Address</th>*/}
                            <th>Promo</th>
                            <th scope="row">More Details</th>
                            {/* <th style={{ width: '120px' }} scope="row">Status</th>
                <th scope="row">Order Details</th> */}
                        </tr>

                        {/* 🔥 Doing the loop here 🔥 */}
                        {
                            users.map(user => {
                                return <CustomerItem
                                    key={user._id}
                                    user={user}
                                    setSingleUser={setSingleUser}
                                    refreshData={refreshData}
                                />
                            })
                        }


                        </tbody>
                    </table>
            }


            <div className="pagination">
                <Pagination pageSize={20} current={curretPage} showSizeChanger={false} total={stat.totalData} onChange={paginationHangler}/>

                <span> Total <span className='pagination-span'>{stat.totalData}</span> Customers </span>
            </div>
        </div>


        {/* Specific Customer Details Section */}
        <CustomerDetails
            singleUser={singleUser}
            setSingleUser={setSingleUser}
        />


        </Layout>
</>
)
    ;
}

export default Customers;