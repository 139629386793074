import { useEffect, useState } from "react";
import axios from "../../config/axios";
import { useContext } from "react";
import { Button, Input, message, Select } from "antd";
import Layout from "../../Components/Layout";

const CreateCustomer = (props) => {
    const [user, setUser] = useState({
        name: "",
        email: "",
        district: "",
        subDistrict: "",
        addressLineOne: "",
        addressLineTwo: "",
    });
    const [loading, setLoading] = useState(false);
    const {Option} = Select;
    const [subDistricts, setSubDistricts] = useState([]);


    //defining onChangeHandler function
    const onChangeHandler = (e) => {
        setUser({...user, [e.target.name]: e.target.value});
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await axios.put(`/user/v1`, user, {withCredentials: true});
            message.success({
                content: `Account details updated successfully!`,
            });


        } catch (error) {
            message.error({
                content: `An error occurred! Please check that you've provided all the requested information properly.`,
            });
        } finally {
            setLoading(false);
        }
    };

    //defining onChangeDistrictHandler function
    function onChangeDistrictHandler(value) {
        setUser({
            name: user.name,
            email: user.email,
            password: user.password,
            confirmPassword: user.confirmPassword,
            district: value,
            subDistrict: user.subDistrict,
            addressLineOne: user.addressLineOne,
            addressLineTwo: user.addressLineTwo,
        });

        axios.get(`${process.env.server}/public/microservices/v1/getSubDistricts/${value}`).then((response) => {
            setSubDistricts(response.data.subDistricts);
        });
    }

    //defining onChangeSubDistrictHandler function
    const onChangeSubDistrictHandler = (value) => {
        setUser({
            name: user.name,
            email: user.email,
            password: user.password,
            confirmPassword: user.confirmPassword,
            district: user.district,
            subDistrict: value,
            addressLineOne: user.addressLineOne,
            addressLineTwo: user.addressLineTwo,
        });
    };

    function onSearch(val) {
        console.log("search:", val);
    }

    return (
        <Layout>
            <div style={{padding: 0, marginBottom: "0rem"}} className="card-primary">
                <div style={{marginTop: "0rem"}} className="card-primary__body">
                    <label className="label-primary" htmlFor="">
                        Your Name
                    </label>
                    <Input
                        onChange={onChangeHandler}
                        name="name"
                        value={user.name}
                        className="input-primary"
                        type="text"
                    />

                    <label className="label-primary" htmlFor="">
                        Your email Address (Optional)
                    </label>
                    <Input
                        onChange={onChangeHandler}
                        name="email"
                        value={user.email}
                        className="input-primary"
                        type="email"
                        min="11"
                        max="11"
                    />

                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div style={{flex: "0 0 45%"}}>
                            <label className="label-primary" htmlFor="">
                                Your District
                            </label>
                            <Select
                                showSearch
                                popupClassName="dropdown-primary"
                                placeholder="Select here"
                                optionFilterProp="children"
                                onChange={onChangeDistrictHandler}
                                onSearch={onSearch}
                                style={{marginBottom: "2rem", width: "100%"}}
                                value={user.district}
                            >
                                <Option value="Comilla">Comilla</Option>
                                <Option value="Feni">Feni</Option>
                                <Option value="Brahmanbaria">Brahmanbaria</Option>
                                <Option value="Rangamati">Rangamati</Option>
                                <Option value="Noakhali">Noakhali</Option>
                                <Option value="Chandpur">Chandpur</Option>
                                <Option value="Lakshmipur">Lakshmipur</Option>
                                <Option value="Chattogram">Chattogram</Option>
                                <Option value="Coxsbazar">Coxsbazar</Option>
                                <Option value="Khagrachhari">Khagrachhari</Option>
                                <Option value="Bandarban">Bandarban</Option>
                                <Option value="Sirajganj">Sirajganj</Option>
                                <Option value="Pabna">Pabna</Option>
                                <Option value="Bogura">Bogura</Option>
                                <Option value="Rajshahi">Rajshahi</Option>
                                <Option value="Natore">Natore</Option>
                                <Option value="Joypurhat">Joypurhat</Option>
                                <Option value="Chapainawabganj">Chapainawabganj</Option>
                                <Option value="Naogaon">Naogaon</Option>
                                <Option value="Jashore">Jashore</Option>
                                <Option value="Satkhira">Satkhira</Option>
                                <Option value="Meherpur">Meherpur</Option>
                                <Option value="Narail">Narail</Option>
                                <Option value="Chuadanga">Chuadanga</Option>
                                <Option value="Kushtia">Kushtia</Option>
                                <Option value="Magura">Magura</Option>
                                <Option value="Khulna">Khulna</Option>
                                <Option value="Bagerhat">Bagerhat</Option>
                                <Option value="Jhenaidah">Jhenaidah</Option>
                                <Option value="Jhalakathi">Jhalakathi</Option>
                                <Option value="Patuakhali">Patuakhali</Option>
                                <Option value="Pirojpur">Pirojpur</Option>
                                <Option value="Barisal">Barisal</Option>
                                <Option value="Bhola">Bhola</Option>
                                <Option value="Barguna">Barguna</Option>
                                <Option value="Sylhet">Sylhet</Option>
                                <Option value="Moulvibazar">Moulvibazar</Option>
                                <Option value="Habiganj">Habiganj</Option>
                                <Option value="Sunamganj">Sunamganj</Option>
                                <Option value="Narsingdi">Narsingdi</Option>
                                <Option value="Gazipur">Gazipur</Option>
                                <Option value="Shariatpur">Shariatpur</Option>
                                <Option value="Narayanganj">Narayanganj</Option>
                                <Option value="Tangail">Tangail</Option>
                                <Option value="Kishoreganj">Kishoreganj</Option>
                                <Option value="Manikganj">Manikganj</Option>
                                <Option value="Dhaka">Dhaka</Option>
                                <Option value="Munshiganj">Munshiganj</Option>
                                <Option value="Rajbari">Rajbari</Option>
                                <Option value="Madaripur">Madaripur</Option>
                                <Option value="Gopalganj">Gopalganj</Option>
                                <Option value="Faridpur">Faridpur</Option>
                                <Option value="Panchagarh">Panchagarh</Option>
                                <Option value="Dinajpur">Dinajpur</Option>
                                <Option value="Lalmonirhat">Lalmonirhat</Option>
                                <Option value="Nilphamari">Nilphamari</Option>
                                <Option value="Gaibandha">Gaibandha</Option>
                                <Option value="Thakurgaon">Thakurgaon</Option>
                                <Option value="Rangpur">Rangpur</Option>
                                <Option value="Kurigram">Kurigram</Option>
                                <Option value="Sherpur">Sherpur</Option>
                                <Option value="Mymensingh">Mymensingh</Option>
                                <Option value="Jamalpur">Jamalpur</Option>
                                <Option value="Netrokona">Netrokona</Option>
                            </Select>
                        </div>

                        <div style={{flex: "0 0 45%"}}>
                            <label className="label-primary" htmlFor="">
                                Your Sub-district
                            </label>
                            <Select
                                showSearch
                                popupClassName="dropdown-primary"
                                placeholder="Select here"
                                optionFilterProp="children"
                                onChange={onChangeSubDistrictHandler}
                                onSearch={onSearch}
                                style={{marginBottom: "2rem", width: "100%"}}
                                value={user.subDistrict}
                            >
                                {subDistricts.map((subDistrict) => {
                                    return (
                                        <Option key={subDistrict.name} value={subDistrict.name}>
                                            {subDistrict.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                    </div>

                    <label className="label-primary" htmlFor="">
                        Address Line 1
                    </label>
                    <Input
                        onChange={onChangeHandler}
                        name="addressLineOne"
                        value={user.addressLineOne}
                        className="input-primary"
                        type="text"
                    />


                    <label className="label-primary" htmlFor="">
                        Address Line 2 (Optional)
                    </label>
                    <Input
                        onChange={onChangeHandler}
                        name="addressLineTwo"
                        value={user.addressLineTwo}
                        style={{width: "100%"}}
                        className="input-primary"
                        type="text"
                    />



                    <Button onClick={onSubmitHandler} className="confirm-order">
                        Create Customer
                    </Button>

                </div>
            </div>
        </Layout>
    );
};

export default CreateCustomer;
