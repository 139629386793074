import { useState, useEffect } from 'react';
import React from 'react';
import axios from '../config/axios';

//importing all components
import EditSubCategory from '../Components/Category/EditSubCategory';
import { message } from 'antd';
import { Skeleton } from 'antd';
import Layout from '../Components/Layout';


const SubCategories = (props) => {

    const [subCategory, setSubCategory] = useState({ name: '', parent: '', description: '' });
    const [categoryImagePreview, setCategoryImagePreview] = useState(null);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [editSubCategory, setEditSubCategory] = useState({ boolean: false, subCategory: '' });
    const [loading, setLoading] = useState(false);
    const [refresher, setRefresher] = useState(null);

    useEffect(() => {
        axios.get(`/admin/category/sub/v1`, { withCredentials: true }).then(response => {
            setSubCategories(response.data.subCategories);
        });

        axios.get(`/admin/category/v1`, { withCredentials: true }).then(response => {
            setCategories(response.data.categories);
        });
    }, [refresher]);

    //defining refresh function
    const refresh = () => {
        setRefresher(Math.random());
    }



    //defining onChangeHandler function
    const onChangeHandler = (e) => {
        e.preventDefault();

        setSubCategory({ ...subCategory, [e.target.name]: e.target.value });
    }


    //defining editSubCategoryTrigger function
    const editSubCategoryTrigger = (theSubCategory) => {
        setEditSubCategory({
            boolean: true,
            subCategory: theSubCategory
        });
    }

    //defining closeEditSubCategory function
    const closeEditSubCategory = () => {
        setEditSubCategory({
            boolean: false,
            categoryID: ''
        });
    }

    //defining onSubmitHandler function
    const onSubmitHandler = (e) => {
        e.preventDefault();

        if (subCategory.parent === 'ps' || subCategory.parent === '') {

            message.error({
                content: `Please Select Parent Category!`,
                style: {
                    marginTop: '85vh',
                    marginRight: '70vw'
                },
            })

        } else {

            setLoading(true);

            axios.post(`/admin/category/sub/v1/create`, subCategory, { withCredentials: true }).then(response => {
                if (response.status === 200) {
                    refresh();
                    setLoading(false);
                    setSubCategory({ name: '', parent: '', description: '' });
                    message.success({
                        content: `Sub Category Was Created Successfully!`,
                        style: {
                            marginTop: '85vh',
                            marginRight: '70vw'
                        },
                    })
                }
            }).catch((error) => {
                if (error) {
                    console.log(error.response.status);
                    setLoading(false);
                }
            });
        }
    }

    return (
        <>
            <Layout>

                <h2 className="breadcrumb-custom"> <span>Dashboard</span> {'>'} <span>Products</span> {'>'} <span> Sub Category Managment</span></h2>

                <div className="dashboard-full">
                    <div className="dashboard-half">

                        <div className="dashboard-page-header">
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__left">
                                <h3 style={{ fontSize: '1.8rem' }} className="dashboard-page-header__left--title">Add New Sub Category</h3>
                            </div>
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__right">

                                {/* <select className="bselect" name="" id="">
                        <option value="Follow Up">Follow Up</option>
                        <option value="In Transit">In Transit</option>
                        <option value="Delivered">Delivered</option>
                    </select> */}

                                {/* <form style={{ flex: "0 0 100%" }} >
                    <input className="binput" placeholder="Search By Name" type="text" name="" id="" />
                </form> */}

                                <button onClick={() => props.history.push('/categories')} style={{ flex: "0 0 100%" }} className="bbutton bbutton-primary"> <svg className="admin-svg white" viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg> Add New Category</button>
                            </div>
                        </div>

                        {/* Create New Category Form */}
                        <div className="dashboard-card">

                            <form onSubmit={onSubmitHandler}>

                                <label className="blabel">Sub Category Name</label>
                                <input value={subCategory.name} onChange={onChangeHandler} className="binput" type="text" name="name" required />

                                <label className="blabel">Sub Category Parent</label>
                                <select value={subCategory.parent} onChange={onChangeHandler} className='bselect' name="parent">
                                    <option value="ps">Please Select</option>
                                    {
                                        categories.map(category => {
                                            return (
                                                <>
                                                    <option value={category.slug}> {category.name} </option>

                                                </>
                                            )
                                        })
                                    }
                                </select>

                                <label className="blabel">Sub Category Description</label>
                                <textarea value={subCategory.description} onChange={onChangeHandler} className="ctext-area" placeholder="Not Mandatory To Add" name="description" required cols="30" rows="2"></textarea>


                                {
                                    loading ? <button disabled style={{ width: "100%", marginTop: "4rem" }} className="bbutton bbutton-loading"> <img src="/three-dot.svg" width="12%" alt="" /> </button>
                                        :
                                        <button type='submit' style={{ width: "100%", marginTop: "4rem" }} className="bbutton bbutton-primary">Create Sub Category</button>
                                }



                            </form>

                        </div>

                    </div>
                    <div className="dashboard-half">

                        {/* List of All Categories */}
                        <div className="dashboard-page-header">
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__left">
                                <h3 className="dashboard-page-header__left--title">All Sub Categories</h3>
                            </div>
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__right">

                                {/* <select className="bselect" name="" id="">
                        <option value="Follow Up">Follow Up</option>
                        <option value="In Transit">In Transit</option>
                        <option value="Delivered">Delivered</option>
                    </select> */}

                                {/* <form style={{ flex: "0 0 100%" }} >
                                    <input className="binput" placeholder="Search By Name" type="text" name="" id="" />
                                </form> */}

                                {/* <button style={{flex: "0 0 100%"}} className="bbutton bbutton-primary"> <svg className="admin-svg white" viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg> Add New Product</button> */}
                            </div>
                        </div>

                        {/* All Categories Here */}


                        <div className="dashboard-table-wrapper">
                            <table class="table">
                                <tbody>
                                    <tr style={{ border: "1px solid var(--color-grey-green)" }}>
                                        <th scope="row"> Name</th>
                                        <th scope="row">Slug</th>
                                        <th scope="row">Parent</th>
                                        <th scope="row">Action</th>
                                    </tr>

                                    {/* Doing The Loop Here */}
                                    {
                                        subCategories.map(subCategory => {
                                            return <tr key={subCategory._id} className="align-middle">
                                                <td> {subCategory.name} </td>
                                                <td> {subCategory.slug} </td>
                                                <td> {subCategory.parent} </td>
                                                <td onClick={() => editSubCategoryTrigger(subCategory)}><svg style={{ color: 'var(--color-primary-db)', cursor: 'pointer' }} viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg></td>
                                            </tr>
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>

                        {/* Connecting Edit Category Component */}
                        {
                            editSubCategory.boolean ? <EditSubCategory drawerClass={'pop-drawer'}
                                drawerBodyClass={'pop-drawer__body'}
                                closeEditSubCategory={closeEditSubCategory}
                                width={40}
                                subCategory={editSubCategory.subCategory}
                                categories={categories}
                                refresh={refresh}
                            /> : <EditSubCategory subCategory={subCategory} categories={categories} />
                        }

                    </div>
                </div>

            </Layout>
        </>
    );
}
export default SubCategories;