import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';
import { message } from 'antd';

const EditSubCategory = (props) => {

    const [subCategory, setSubCategory] = useState({ name: '', parent: '', description: '' });
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setSubCategory({
            name: props.subCategory.name,
            parent: props.subCategory.parent,
            description: props.subCategory.description
        });

        setCategories(props.categories);
    }, [props.subCategory]);


    //defining closeEditSubCategory function
    const closeEditCategory = () => {
        props.closeEditSubCategory();
    }



    //defining onChangeHandler function
    const onChangeHandler = (e) => {

        setSubCategory({ ...subCategory, [e.target.name]: e.target.value });
    }


    //defining onSubmitHandler function
    const onSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);

        axios.put(`/admin/category/sub/v1/update/${props.subCategory._id}`, subCategory, { withCredentials: true }).then(response => {
            if (response.status === 200) {
                props.refresh();
                setLoading(false);
                message.success({
                    content: `SubCategory Was Updated Successfully!`,
                    style: {
                        marginTop: '85vh',
                        marginRight: '70vw'
                    },
                })
            } else {
                console.log(response.status);
                setLoading(false);
            }
        });
    }

    return (
        <>

            <div className={props.drawerClass || 'pop-drawer closed'}> </div>
            <div style={{ width: `${props.width}vw` }} className={props.drawerBodyClass || `pop-drawer__body-closed`}>
                <div className="pop-drawer__body__wrapper">
                    <h2>Edit Sub Category - {subCategory.name} </h2>

                    <form >

                        <label className="blabel">Sub Category Name</label>
                        <input value={subCategory.name} onChange={onChangeHandler} color="var(--color-grey-green)" className="binput" type="text" name="name" id="" />

                        <label className="blabel">Sub Category Parent</label>
                        <select value={subCategory.parent} onChange={onChangeHandler} className='bselect' name="parent">
                            <option value="ps">Please Select</option>
                            {
                                categories.map(category => {
                                    return (
                                        <>
                                            <option value={category.slug}> {category.name} </option>

                                        </>
                                    )
                                })
                            }
                        </select>

                        <label className="blabel">Sub Category Description</label>
                        <textarea value={subCategory.description} onChange={onChangeHandler} className="ctext-area" placeholder="Not Mandatory To Add" name="description" required cols="30" rows="2"></textarea>


                        {/* <button style={{ width: "100%", marginTop: "4rem" }} className="bbutton bbutton-primary">Create Category</button> */}
                    </form>
                </div>

                <div className="pop-drawer__footer">
                    <button onClick={closeEditCategory} style={{ width: '100%', flex: '0 0 48%', color: 'red' }} className="bbutton">Cancel</button>
                    {
                        loading ? <button disabled style={{ width: "100%", flex: '0 0 48%' }} className="bbutton bbutton-loading"> <img src="/three-dot.svg" width="12%" alt="" /> </button>
                            :
                            <button onClick={onSubmitHandler} type='submit' style={{ width: "100%", flex: '0 0 48%' }} className="bbutton bbutton-primary">Update SubCategory</button>
                    }
                </div>
            </div>


        </>
    );
}

export default EditSubCategory;