import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import axios from '../../config/axios'
import moment from '../../config/moment';

const Last7DaysStatisticsChart = () => {

    const [totalOrderAmounts, setTotalOrderAmounts] = useState([]);
    const [totalDiscountedAmount, setTotalDiscountedAmount] = useState([]);
    const [noOfOrders, setNoOfOrders] = useState([]);
    const [dateList, setDateList] = useState([]);

    useEffect(() => {

        axios.get(`/admin/stats/orders/date-wise/v1`, {
            withCredentials: true, params: {
                start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                end: moment().format('YYYY-MM-DD'),
            }
        }).then(response => {

            console.log(response.data);
            response.data.forEach(data=>{
                setTotalOrderAmounts(totalOrderAmounts=>totalOrderAmounts.concat(data.total_bill_amount));
                setDateList(dateList=>dateList.concat(data._id));
                setTotalDiscountedAmount(totalDiscountedAmount => totalDiscountedAmount.concat(data.total_discount_amount));
                setNoOfOrders(noOfOrders=>noOfOrders.concat(data.total_orders))
            })
        });

    }, []);

    const data = {

            series: [{
              name: 'No of Orders',
              data: noOfOrders
            },{
              name: 'Total Sales',
              data: totalOrderAmounts
            }, {
              name: 'Total Discounted',
              data: totalDiscountedAmount
            }],
            options: {
              chart: {
                height: 350,
                type: 'area'
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth'
              },
              xaxis: {
                type: 'date',
                categories: dateList
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy'
                },
              },
            },


          };

    return (
        <>
            <ApexCharts options={data.options} series={data.series} width={'100%'} height={450} type="area"/>
        </>
    );
}

export default Last7DaysStatisticsChart;