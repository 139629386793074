import React, { useEffect, useState } from 'react';
import axios from '../../config/axios';
import styles from './Invoice.module.scss';
import { Col, Row } from "antd";
import moment from '../../config/moment';
import KitchenToken from "./KitchenToken";

const Invoice = (props) => {

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);

    useEffect(() => {
        // console.log(props.match.params.invoiceID);
        axios.get(`/admin/orders/v1/${props.match.params.invoiceID}`, {withCredentials: true}).then(response => {
            console.log(response.data);
            setOrder(response.data.order);
        })
    }, []);

    const getOrderSubTotal = () => {
        let subTotal = 0;
        order.products.forEach(item => {
            subTotal = subTotal + (item.price * item.quantity);
        })

        return subTotal;
    }

    return (
        <div className={styles.invoice}>
            {
                order &&
                <>
                    <div className={styles.header}>
                        <img src="/logo.svg" alt=""/>
                        <span>Vapex: Premium Disposable Vapes and Cafe</span>
                    </div>
                    <div className={styles.info}>
                        <Row>
                            <Col span={24}>
                                <div>
                                    <span>Order No: </span>
                                    <span>{order.orderID}</span>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <span>Time: </span>
                                    <span>{moment(order.timestamp).format('LT')}</span>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div>
                                    <span>Date: </span>
                                    <span>{moment(order.timestamp).format('DD-MM-YY')}</span>
                                </div>
                            </Col>
                            <Col span={24}>
                                <div>
                                    <span>Customer: </span>
                                    <span>{order.contact.name}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <table className={styles.table}>
                        <tbody>
                        <tr>
                            <th>SL</th>
                            <th>Item</th>
                            <th>Rate</th>
                            <th>Qty</th>
                            <th style={{textAlign: 'right'}}>Amount</th>
                        </tr>
                        {
                            order.products.map((item, index) => {
                                return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.name} - {item.color}</td>
                                    <td>{item.price}৳</td>
                                    <td>{item.quantity}</td>
                                    <td style={{textAlign: 'right'}}>{item.quantity * item.price}৳</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>

                    <div className={styles.summary}>
                        <div>
                            <span>Sub Total:</span>
                            <span>{getOrderSubTotal()}৳</span>
                        </div>
                        <div>
                            <span>+ Delivery Charge:</span>
                            <span>{order.deliveryCharge > 0 ? `${order.deliveryCharge}৳` : 'N/A'}</span>
                        </div>
                        <div>
                            <span>- Discount:</span>
                            <span>{order.discount > 0 ? `${order.discount}৳` : 'N/A'}</span>
                        </div>
                        <div className={styles.hr}/>
                        <div>
                            <span>Total:</span>
                            <span>{order.totalBill}৳</span>
                        </div>
                    </div>

                    <div className={styles.footer}>
                        <b>Thanks for your purchase {"<3"}</b>
                        <em>www.vapexbd.com</em>
                    </div>
                </>
            }

            <KitchenToken order={order} />
        </div>
    )
        ;
};

export default Invoice;