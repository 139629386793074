import React, { useState, useEffect } from 'react';
import axios from '../../config/axios';

//importing all components\
import { Drawer, List, Avatar, Divider, Col, Row, Tag, Badge, message } from 'antd';
import {
    UserOutlined,
    FileSearchOutlined
} from '@ant-design/icons';
import OrderDetails from '../Order/OrderDetails';
import moment from 'moment';

const CustomerDetails = ({ singleUser, setSingleUser, singleUserID, setSingleUserID }) => {

    const [bhai, setBhai] = useState(false);
    const [singleOrderID, setSingleOrderID] = useState(null);
    const [user, setUser] = useState();
    const [visibility, setVisibility] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if (singleUser) {

            setVisibility(true);
            setUser(singleUser);
            console.log(singleUser);
            setLoading(false);

        } else if (singleUserID) {

            setVisibility(true);

            axios.get(`/admin/users/v1/getUser/${singleUserID}`, { withCredentials: true }).then(response => {

                setUser(response.data.user);
                console.log(response.data.user);
                setLoading(false);

            }).catch(error => {
                if (error.response.status === 404) {
                    message.error({
                        content: `No user associated with this id was found!`,
                    })
                }
            })
        }

    }, [singleUser, singleUserID]);


    //defining onClose function
    const onCloseHandler = () => {

        if (setSingleUserID) {
            setSingleUserID(null);
        }

        if (setSingleUser) {
            setSingleUser(null);
        }

        setVisibility(false);
        setLoading(true);

    }

    return (
        <>

            <Drawer
                width={'90%'}
                placement="right"
                closable={true}
                onClose={onCloseHandler}
                visible={visibility}
            >

                {
                    loading ?
                        <div style={{ height: '60vh' }} className="loader"><img src="/loader.svg" alt="" /></div>
                        :
                        <>

                            <h3 className="drawer-title">Customer Details</h3>
                            <div className="ant-drawer-body-wrapper">

                                <p className="title-primary">
                                    <UserOutlined />
                                    Personal & Contact Infos
                                </p>
                                <Row>
                                    <Col span={12}>
                                        <div className="info">
                                            <span className="info__1">Full Name: </span>
                                            <span className="info__2">{user.name}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">District: </span>
                                            <span className="info__2">{user.district}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Sub District: </span>
                                            <span className="info__2">{user.subDistrict}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Address Line One: </span>
                                            <span className="info__2">{user.addressLineOne}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Address Line Two: </span>
                                            <span className="info__2">{user.addressLineTwo}</span>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="info">
                                            <span className="info__1">Email: </span>
                                            <span className="info__2">{user.email}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Phone Number: </span>
                                            <span className="info__2">{user.phoneNumber}</span>
                                        </div>
                                    </Col>
                                </Row>

                                <p style={{ marginTop: '2rem' }} className="title-primary">
                                    <FileSearchOutlined />
                                    Account Details
                                </p>
                                <Row>
                                    <Col span={24}>
                                        <div className="info">
                                            <span className="info__1">Date of Registration: </span>
                                            <span className="info__2">{moment(user.dateOfRegistration).format('MMMM D, YYYY - LT')}</span>
                                        </div>

                                        <div className="info">
                                            <span onClick={() => setBhai(true)} className="info__1">Verification: </span>
                                            <span className="info__2">
                                                {
                                                    user.isVerified ?
                                                        <Tag color="success">Verified</Tag>
                                                        :
                                                        <Tag color="error">Unverified</Tag>
                                                }
                                            </span>
                                        </div>

                                        <div className="info">
                                            <span className="info__1">No of Orders: </span>
                                            <span className="info__2">
                                                {
                                                    user.orders ?
                                                        <Badge style={{ backgroundColor: user.orders.length === 0 ? 'var(--color-red)' : user.orders.length < 3 ? 'var(--color-ok)' : 'var(--color-gold)' }} showZero count={user.orders.length} />
                                                        :
                                                        null
                                                }
                                            </span>
                                        </div>

                                        {
                                            user.orders.map(order => {
                                                return (
                                                    <>
                                                        <a key={order.orderID} onClick={() => setSingleOrderID(order.orderID)} >{order.orderID}</a>
                                                        <br />
                                                    </>
                                                )
                                            })

                                        }
                                    </Col>
                                </Row>
                            </div>
                        </>
                }


            </Drawer>


            {/* Single Order Details Drawer Component */}
            <OrderDetails
                singleOrderID={singleOrderID}
                setSingleOrderID={setSingleOrderID}
            />


        </>
    )
}


export default CustomerDetails;