import styles from './CreateOrder.module.scss';
import Layout from "../../Components/Layout";
import { Button, Col, Form, Input, InputNumber, message, Modal, Radio, Result, Row, Select, Tag } from "antd";
import { useEffect, useRef, useState } from "react";
import axios from "../../config/axios";
import { useReactToPrint } from "react-to-print";
import KitchenToken from "../Invoice/KitchenToken";


const CreateOrder = (props) => {

    const {Option} = Select;
    const componentRef = useRef(null);
    const [onPrintScreen, setOnPrintScreen] = useState(false);

    const [number, setNumber] = useState('');
    const [name, setName] = useState('');
    const [products, setProducts] = useState([]);
    const [storedProducts, setStoredProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchCategory, setSearchCategory] = useState('645bfc5d3e2edd51c20c437d');
    const [searchSubCategory, setSearchSubCategory] = useState(null);

    const [promoCode, setPromoCode] = useState('');
    const [promoDetails, setPromoDetails] = useState(null);
    const [givenOtp, setGivenOtp] = useState('');

    // products states
    const [selectedProduct, setSelectedProduct] = useState(null);

    // order item states
    const [orderItems, setOrderItems] = useState([]);
    const [selectedProductQuantity, setSelectedProductQuantity] = useState(1);
    const [deliveryCharge, setDeliveryCharge] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('cash');
    const [paymentStatus, setPaymentStatus] = useState('paid');
    const [salesChannel, setSalesChannel] = useState('store');
    const [tableNo, setTableNo] = useState('');
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [redeemedPromo, setRedeemedPromo] = useState(null);

    const [discountType, setDiscountType] = useState('flat');
    const [showAddrModal, setShowAddrModal] = useState(false);
    const [subDistricts, setSubDistricts] = useState([]);
    const [address, setAddress] = useState(null);


    useEffect(() => {
        axios.get(`/admin/pos/search/v1`, {
            withCredentials: true, params: {
                search_term: searchTerm,
                category: searchCategory,
                sub_category: searchSubCategory
            }
        }).then(response => {
            // console.log(response.data);
            setProducts(response.data.products);
            setStoredProducts(response.data.products);
        })
    }, [searchTerm, searchCategory]);

    useEffect(() => {
        axios.get(`/admin/category/v1`, {withCredentials: true}).then(response => {
            setCategories(response.data.categories);
        })
    }, []);

    const getProductCategories = () => {
        let cats = []
        categories.map(cat => {
            cats.push({
                value: cat._id,
                label: cat.name
            })
        })

        return cats;
    }

    const getTotalItemsInCart = () =>{
        let total=0;
        orderItems.map(item=>{
            total = total+ item.quantity;
        })

        return total;
    }

    function onChangeDistrictHandler(value) {
        axios.get(`/public/microservices/v1/getSubDistricts/${value}`).then((response) => {
            setSubDistricts(response.data.subDistricts);
        });
    }

    const addAddressHandler = values =>{
        console.log(values);
        setAddress(values);
        setShowAddrModal(false);
    }

    const handleNumberChange = number => {
        if (number.length === 11) {
            axios.get(`/admin/users/v1/getUsers/1?phone_number=${number}`, {withCredentials: true}).then(response => {
                console.log(response.data.users);
                if (response.data.users.length > 0) {
                    setName(response.data.users[0]?.name);
                    let u = response.data.users[0];
                    if(u.district) {
                        setAddress({
                            district: u.district,
                            subDistrict: u.subDistrict,
                            addressLineOne: u.addressLineOne,
                            old: true
                        })
                    }
                    message.info(('Customer already registered on Vapex!'));
                }
            });
        }

        if (number.length < 12) {
            setNumber(number)
        }


    }

    const addItemToInvoice = () => {
        let product = products[selectedProduct];
        // console.log(product);

        let stock = product.variant.filter(pv => pv.sku === product.p_sku)[0].stock;
        if (stock < selectedProductQuantity) {
            if (window.confirm(`We only have ${stock} units of this product in stock! Continue anyway?`) === false) {
                return;
            }
        }

        //checking if item already added
        let cOrderItems = JSON.parse(JSON.stringify(orderItems));
        let p_in = false;
        cOrderItems.map(oi => {
            if (oi.sku === product.p_sku) {
                oi.quantity = parseInt(oi.quantity) + parseInt(selectedProductQuantity);
                setOrderItems(cOrderItems);
                p_in = true;
            }
        })

        //if product was already added return here
        if (p_in) {
            return;
        }

        let push_product = {
            name: product.name,
            sku: product.p_sku,
            id: product._id,
            color: product.p_variant,
            quantity: selectedProductQuantity,
            price: product.salePrice,
            featuredImage: product.featuredImage,
            category: product.category
        }

        setOrderItems(orderItems => orderItems.concat(push_product));

        message.info('Item Added');
    }

    //totalPrice counter function definition
    const totalPrice = () => {

        let totalPrice = 0;

        for (let i = 0; i < orderItems.length; i++) {
            totalPrice = totalPrice + (orderItems[i].price * orderItems[i].quantity);
        }

        return totalPrice;
    }

    const saleChannelOptions = [
        {
            label: 'Store',
            value: 'store',
        },
        {
            label: 'Online',
            value: 'online',
        },
    ];


    const createOrder = () => {
        if (!number) {
            message.error('Customer Number is required!')
            return;
        }

        if (!name) {
            message.error('Customer Name is required!')
            return;
        }

        if (orderItems.length === 0) {
            message.error('Add at least 1 product!');
            return;
        }
        setLoading(true);

        //identifying product_type
        let product_type;
        if (orderItems[0].category === '645bfc5d3e2edd51c20c437d') {
            product_type = 'food';
        } else {
            product_type = 'disposable';
        }


        let payload = {
            products: JSON.stringify(orderItems),
            name,
            number,
            district: address ? address.district : null,
            subDistrict: address ? address.subDistrict : null,
            addressLineOne: address ? address.addressLineOne : null,
            deliveryCharge: deliveryCharge,
            deliveryMethod: 'manual',
            sales_channel: salesChannel,
            totalBill: totalPrice() + parseInt(deliveryCharge) - getDiscount(),
            paymentStatus: paymentStatus,
            paymentMethod: paymentMethod,
            orderNote: '',
            table_no: tableNo,
            discount: getDiscount(),
            redeemed_promo: redeemedPromo,
            product_type: product_type
        }

        axios.post('/admin/orders/v1/create', payload, {withCredentials: true}).then(response => {
            message.success('Order Placed Successfully!');
            setOrder(response.data.order);
            console.log(response.data);
            // props.history.push('/orders');
        }).catch(error => {
            message.error('A server side error occurred');
        }).finally(() => {
            setLoading(false);
        })
    }

    function getDiscount() {
        if (discountType === 'flat') {
            return parseInt(discount);
        } else {
            return parseInt((totalPrice() * discount) / 100);
        }
    }

    const promoApplicationHandler = () => {
        if (!promoCode) {
            message.error('Enter Promo Code first');
        }

        let payload = {
            promo_code: promoCode.toUpperCase(),
            phone_number: number
        }

        axios.post('/admin/crm/promo/apply/v1', payload, {withCredentials: true}).then(response => {
            setPromoDetails({
                ...response.data,
                showModal: true
            });
        }).catch(error => {
            message.error('Invalid Promo Code!');
        })
    }


    const handleOtpVerification = () => {
        if (parseInt(promoDetails.code) !== parseInt(givenOtp)) {
            message.error('OTP Does Not Match!');
            return;
        } else {
            setDiscount(promoDetails.promo_discount)
            setRedeemedPromo(promoCode);
            message.success('Promo Redeemed Successfully!');
            let p_d = JSON.parse(JSON.stringify(promoDetails));
            p_d.showModal = false;
            setPromoDetails(p_d);
        }
    }

    //  const handleKotPrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     documentTitle: `KOT-${Date.now()}`,
    //     onBeforePrint: () => {
    //         message.destroy();
    //         // message.success("Invoice Generated Successfully!");
    //     },
    //     onAfterPrint: () => {
    //             window.location.reload();
    //     },
    //     removeAfterPrint: true,
    // });


    return (
        <>
            {
                !onPrintScreen ?
                    <Layout>
                        <div className={'primary-card'} style={{backgroundColor: 'white', padding: '1rem'}}>
                            <Row gutter={[15, 15]}>
                                <Col sm={24} xs={24}>
                                    {/* eslint-disable-next-line react/jsx-no-undef */}
                                    <label htmlFor="">Sales Channel</label>
                                    <Radio.Group
                                        options={saleChannelOptions}
                                        onChange={(e) => setSalesChannel(e.target.value)}
                                        value={salesChannel}
                                        optionType="button"
                                        buttonStyle="solid"

                                    />
                                </Col>
                                <Col sm={12} xs={24}>
                                    <div className={styles.item}>
                                        <label htmlFor="">Customer Number</label>
                                        <Input size={'large'} inputMode='numeric' value={number}
                                               onChange={e => handleNumberChange(e.target.value)}
                                               placeholder={'01792056627'}/>
                                    </div>
                                </Col>
                                <Col sm={8} xs={24}>
                                    <div className={styles.item}>
                                        <label htmlFor="">Customer Name</label>
                                        <Input size={'large'} onChange={e => setName(e.target.value)} value={name}/>
                                    </div>
                                </Col>
                                <Col sm={4} xs={24}>
                                    <div className={styles.item}>
                                        <label htmlFor="">Customer Address</label>
                                        {
                                            address?.old ?
                                                <div>
                                                    {address.addressLineOne}, {address.subDistrict}, {address.district}
                                                </div>
                                                :
                                                <Button size={'large'} type={'link'} onClick={() => setShowAddrModal(true)}>
                                            {
                                                address ? 'Edit Address' : 'Add Address'
                                            }
                                        </Button>
                                        }
                                    </div>
                                </Col>
                                <Col sm={24} xs={24}>
                                    <label htmlFor="">Category</label>
                                    <Radio.Group
                                        options={getProductCategories()}
                                        onChange={(e) => setSearchCategory(e.target.value)}
                                        value={searchCategory}
                                        optionType="button"
                                        buttonStyle="solid"

                                    />
                                </Col>
                                <Col sm={14} xs={12}>
                                    <div className={styles.item}>
                                        <label htmlFor="">Product</label>
                                        <Input
                                            style={{width: '100%'}}
                                            type={'text'}
                                            placeholder={'Search by Name or SKU'}
                                            onChange={e => {
                                                // setSearchTerm(e.target.value)
                                                let searchText = e.target.value;
                                                if (searchText.length > 0) {
                                                    const filteredProducts = products.filter((product) => {
                                                        const name = product.name.toLowerCase();
                                                        const search = searchText.toLowerCase();
                                                        return name.includes(search); // Partial and case-insensitive match
                                                    });

                                                    setProducts(filteredProducts);
                                                } else {
                                                    setProducts(storedProducts);
                                                }

                                            }}
                                            size={'large'}
                                        />
                                    </div>
                                </Col>
                                <Col sm={6} xs={12}>
                                    <label htmlFor="">Qty</label>
                                    <div className={styles.qty_control}>
                                        <InputNumber
                                            inputMode='numeric'
                                            size={'large'}
                                            type={'Number'}
                                            onChange={(value) => {
                                                if (value > 0) {
                                                    setSelectedProductQuantity(value)
                                                }
                                            }}
                                            value={selectedProductQuantity}/>
                                        <Button
                                            size={'large'}
                                            style={{backgroundColor: 'green', color: 'white'}}
                                            onClick={() => setSelectedProductQuantity(selectedProductQuantity + 1)}
                                        >
                                            +
                                        </Button>
                                        <Button
                                            size={'large'}
                                            type={'danger'}
                                            onClick={() => {
                                                if ((selectedProductQuantity - 1) !== 0) {
                                                    setSelectedProductQuantity(selectedProductQuantity - 1)
                                                }
                                            }}
                                        >
                                            -
                                        </Button>
                                    </div>
                                </Col>
                                <Col sm={4} xs={24}>
                                    <label htmlFor="">Action</label>
                                    <Button size={'large'} style={{width: '100%'}} onClick={addItemToInvoice}
                                            type={'primary'}>Add</Button>
                                </Col>

                                <Col sm={24} xs={24}>
                                    <Row gutter={[10, 10]}>
                                        {
                                            products.map((prod, index) => {
                                                return <Col lg={4} sm={4} xs={8}>
                                                    <div
                                                        className={selectedProduct === index ? styles.item_box + " " + styles.item_active : styles.item_box}
                                                        onClick={() => setSelectedProduct(index)}>
                                                        <img src={prod.featuredImage} alt=""/>
                                                        <span>{prod.name} ({prod.p_sku})</span>
                                                    </div>
                                                </Col>
                                            })
                                        }
                                    </Row>
                                </Col>

                                {
                                    orderItems.length > 0 &&
                                    <Col sm={24} xs={24} className={styles.create_order_table}>
                                        <span>Items in Cart: {getTotalItemsInCart()}</span>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>SKU</th>
                                                <th>Name</th>
                                                <th>Qty</th>
                                                <th>Price</th>
                                                <th>Total</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>

                                            {
                                                orderItems.map((item, index) => {
                                                    return <tr key={index}>
                                                        <td>
                                                            <Tag color={'success'}>{item.sku}</Tag>
                                                        </td>
                                                        <td>
                                                <span style={{
                                                    maxWidth: '400px',
                                                    whiteSpace: 'pre-wrap'
                                                }}>{item.name}</span>
                                                        </td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.price} ৳</td>
                                                        <td>{item.price * item.quantity} ৳</td>
                                                        <td>
                                                            <Button type={'danger'} onClick={() => {
                                                                setOrderItems(orderItems => orderItems.filter(oi => oi.sku !== item.sku))
                                                            }
                                                            }>Remove</Button>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </table>
                                    </Col>
                                }


                                <Col sm={12} xs={24}>
                                    <label htmlFor="">Table No</label>
                                    <Input inputMode='numeric' size={'large'} value={tableNo} onChange={e => {
                                        if (e.target.value >= 0) {
                                            setTableNo(e.target.value)
                                        }
                                    }}/>
                                </Col>
                                <Col sm={12} xs={24}>
                                    <label htmlFor="">Delivery Charge</label>
                                    <Input inputMode='numeric' size={'large'} min={0} value={deliveryCharge}
                                           onChange={e => {
                                               if (e.target.value >= 0) {
                                                   setDeliveryCharge(e.target.value)
                                               }
                                           }}/>
                                </Col>
                                <Col sm={12} xs={24}>
                                    <div className={'d-flex align-items-center gap-4 mb-2'}>
                                        <label className={'mb-0'} htmlFor="">Discount</label>
                                        <Radio.Group
                                            // optionType={"button"}
                                            // buttonStyle="solid"
                                            options={[
                                                {
                                                    label: '%',
                                                    value: 'percentage',
                                                },
                                                {
                                                    label: 'Flat',
                                                    value: 'flat',
                                                },
                                            ]} onChange={(e) => {
                                            setDiscount(0);
                                            setDiscountType(e.target.value)
                                        }} value={discountType}/>
                                    </div>
                                    <Input inputMode='numeric' size={'large'} value={discount} onChange={e => {
                                        if (e.target.value >= 0) {
                                            setDiscount(e.target.value)
                                        }
                                    }}/>
                                </Col>
                                <Col sm={12} xs={24}>
                                    <label htmlFor="">Payment Method</label>
                                    <Select size={'large'} style={{width: '100%'}} value={paymentMethod}
                                            onChange={value => setPaymentMethod(value)}>
                                        <Select.Option value={'cash'}>Cash</Select.Option>
                                        <Select.Option value={'bKash'}>bKash</Select.Option>
                                        <Select.Option value={'COD'}>COD</Select.Option>
                                    </Select>
                                </Col>
                                <Col sm={12} xs={24}>
                                    <label htmlFor="">Payment Status</label>
                                    <Select size={'large'} style={{width: '100%'}} value={paymentStatus}
                                            onChange={value => setPaymentStatus(value)}>
                                        <Select.Option value={'due'}>Due</Select.Option>
                                        <Select.Option value={'paid'}>Paid</Select.Option>
                                    </Select>
                                </Col>
                                <Col sm={12} xs={24}>
                                    <Row gutter={[10, 10]}>
                                        <Col span={16}>
                                            <label htmlFor="">Promo Code</label>
                                            {
                                                redeemedPromo ?
                                                    <div className={'d-flex align-items-center gap-2'}
                                                         style={{whiteSpace: 'nowrap'}}>
                                                        Redeemed Promo/Coupon: <Tag
                                                        color={'processing'}>{redeemedPromo}</Tag>
                                                    </div>
                                                    :
                                                    <Input value={promoCode}
                                                           onChange={e => setPromoCode(e.target.value)}
                                                           placeholder={'Enter Promo Code'} size={'large'}/>
                                            }
                                        </Col>
                                        {
                                            !redeemedPromo &&
                                            <Col span={8}>
                                                <label style={{color: 'white'}} htmlFor="">xs</label>
                                                <Button className={'w-100'} type={'primary'}
                                                        onClick={promoApplicationHandler}
                                                        size={'large'}>Apply</Button>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                                <Col sm={24} xs={24}>
                                    <div className={styles.summary}>
                                        <div>
                                            <span>Sub Total: </span>
                                            <span>{totalPrice()} ৳</span>
                                        </div>
                                    </div>
                                    <div className={styles.summary}>
                                        <div>
                                            <span>+ Delivery Charge: </span>
                                            <span>{deliveryCharge} ৳</span>
                                        </div>
                                    </div>
                                    <div className={styles.summary}>
                                        <div>
                                            <span>- Discount: </span>
                                            <span>{getDiscount()} ৳</span>
                                        </div>
                                    </div>
                                    <hr/>
                                    <div className={styles.summary}>
                                        <div>
                                            <span style={{color: 'green'}}>Total: </span>
                                            <span style={{
                                                fontWeight: 'bold',
                                                fontSize: '2rem',
                                                color: 'green'
                                            }}>{totalPrice() + parseInt(deliveryCharge) - getDiscount()} ৳</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={24} xs={24}>
                                    <div className={'d-flex justify-content-end'} onClick={createOrder}>
                                        <Button loading={loading} disabled={loading} size={'large'} type={'primary'}
                                                block
                                        >Create Order</Button>
                                    </div>
                                </Col>
                            </Row>

                            {
                                order &&
                                <Modal
                                    open={true}
                                    footer={null}
                                    centered={true}
                                >
                                    <div>
                                        <Result
                                            status="success"
                                            title={`Order Token: ${order.token} | Table No: ${order.table_no}`}
                                            subTitle="Order Placed Successfully!"
                                            extra={[

                                                <Button danger={true} type="primary" key="console" size={'large'}
                                                        onClick={() => window.location.reload()}>
                                                    Continue
                                                </Button>,
                                                <Button type={'primary'} size={'large'}
                                                        onClick={() => {
                                                            setOnPrintScreen(true);
                                                            setTimeout(() => {
                                                                window.print();
                                                            }, 300);
                                                        }}>Print KOT</Button>,
                                            ]}
                                        />
                                    </div>
                                </Modal>
                            }

                            {
                                promoDetails?.showModal &&
                                <Modal
                                    open={true}
                                    footer={null}
                                    centered={true}
                                    onCancel={() => setPromoDetails(null)}
                                >
                                    <div className={'pt-5'}>
                                        <span>A 4 digit OTP was sent to Customer's Phone Number</span>
                                        <Input onChange={e => setGivenOtp(e.target.value)}
                                               placeholder={'Enter OTP Code'}
                                               size={'large'}/>
                                        <br/>
                                        <br/>
                                        <Button onClick={handleOtpVerification} className={'w-100'} size={'large'}
                                                type={'primary'}>Confirm</Button>
                                    </div>
                                </Modal>
                            }
                        </div>

                        <Modal
                            open={showAddrModal}
                            footer={null}
                            title={"Add Customer's Address"}
                            onCancel={()=>setShowAddrModal(false)}
                        >
                            <Form
                                layout={'vertical'}
                                onFinish={addAddressHandler}
                                initialValues={{
                                    ...address
                                }}
                            >
                                <Form.Item
                                    label={'District'}
                                    name={'district'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This is a required field'
                                        }
                                    ]}
                                    required={true}
                                >
                                    <Select
                                        showSearch
                                        popupClassName="dropdown-primary"
                                        placeholder="Select here"
                                        optionFilterProp="children"
                                        onChange={onChangeDistrictHandler}
                                        style={{marginBottom: "2rem", width: "100%"}}
                                        size={'large'}
                                    >
                                        <Option value="Comilla">Comilla</Option>
                                        <Option value="Feni">Feni</Option>
                                        <Option value="Brahmanbaria">Brahmanbaria</Option>
                                        <Option value="Rangamati">Rangamati</Option>
                                        <Option value="Noakhali">Noakhali</Option>
                                        <Option value="Chandpur">Chandpur</Option>
                                        <Option value="Lakshmipur">Lakshmipur</Option>
                                        <Option value="Chattogram">Chattogram</Option>
                                        <Option value="Coxsbazar">Coxsbazar</Option>
                                        <Option value="Khagrachhari">Khagrachhari</Option>
                                        <Option value="Bandarban">Bandarban</Option>
                                        <Option value="Sirajganj">Sirajganj</Option>
                                        <Option value="Pabna">Pabna</Option>
                                        <Option value="Bogura">Bogura</Option>
                                        <Option value="Rajshahi">Rajshahi</Option>
                                        <Option value="Natore">Natore</Option>
                                        <Option value="Joypurhat">Joypurhat</Option>
                                        <Option value="Chapainawabganj">Chapainawabganj</Option>
                                        <Option value="Naogaon">Naogaon</Option>
                                        <Option value="Jashore">Jashore</Option>
                                        <Option value="Satkhira">Satkhira</Option>
                                        <Option value="Meherpur">Meherpur</Option>
                                        <Option value="Narail">Narail</Option>
                                        <Option value="Chuadanga">Chuadanga</Option>
                                        <Option value="Kushtia">Kushtia</Option>
                                        <Option value="Magura">Magura</Option>
                                        <Option value="Khulna">Khulna</Option>
                                        <Option value="Bagerhat">Bagerhat</Option>
                                        <Option value="Jhenaidah">Jhenaidah</Option>
                                        <Option value="Jhalakathi">Jhalakathi</Option>
                                        <Option value="Patuakhali">Patuakhali</Option>
                                        <Option value="Pirojpur">Pirojpur</Option>
                                        <Option value="Barisal">Barisal</Option>
                                        <Option value="Bhola">Bhola</Option>
                                        <Option value="Barguna">Barguna</Option>
                                        <Option value="Sylhet">Sylhet</Option>
                                        <Option value="Moulvibazar">Moulvibazar</Option>
                                        <Option value="Habiganj">Habiganj</Option>
                                        <Option value="Sunamganj">Sunamganj</Option>
                                        <Option value="Narsingdi">Narsingdi</Option>
                                        <Option value="Gazipur">Gazipur</Option>
                                        <Option value="Shariatpur">Shariatpur</Option>
                                        <Option value="Narayanganj">Narayanganj</Option>
                                        <Option value="Tangail">Tangail</Option>
                                        <Option value="Kishoreganj">Kishoreganj</Option>
                                        <Option value="Manikganj">Manikganj</Option>
                                        <Option value="Dhaka">Dhaka</Option>
                                        <Option value="Munshiganj">Munshiganj</Option>
                                        <Option value="Rajbari">Rajbari</Option>
                                        <Option value="Madaripur">Madaripur</Option>
                                        <Option value="Gopalganj">Gopalganj</Option>
                                        <Option value="Faridpur">Faridpur</Option>
                                        <Option value="Panchagarh">Panchagarh</Option>
                                        <Option value="Dinajpur">Dinajpur</Option>
                                        <Option value="Lalmonirhat">Lalmonirhat</Option>
                                        <Option value="Nilphamari">Nilphamari</Option>
                                        <Option value="Gaibandha">Gaibandha</Option>
                                        <Option value="Thakurgaon">Thakurgaon</Option>
                                        <Option value="Rangpur">Rangpur</Option>
                                        <Option value="Kurigram">Kurigram</Option>
                                        <Option value="Sherpur">Sherpur</Option>
                                        <Option value="Mymensingh">Mymensingh</Option>
                                        <Option value="Jamalpur">Jamalpur</Option>
                                        <Option value="Netrokona">Netrokona</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={'Sub District'}
                                    name={'subDistrict'}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This is a required field'
                                        }
                                    ]}
                                    required={true}
                                >
                                    <Select
                                        showSearch
                                        popupClassName="dropdown-primary"
                                        placeholder="Select here"
                                        optionFilterProp="children"
                                        style={{marginBottom: "2rem", width: "100%"}}
                                        size={'large'}

                                    >
                                        {subDistricts.map((subDistrict) => {
                                            return (
                                                <Option key={subDistrict.name} value={subDistrict.name}>
                                                    {subDistrict.name}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label={'Address Line One'}
                                    name={'addressLineOne'}
                                    required={true}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'This is a required field'
                                        }
                                    ]}
                                >
                                    <Input size={'large'}/>
                                </Form.Item>

                                <Form.Item

                                >
                                    <Button size={'large'} type={'primary'} htmlType={'submit'}>Add Address</Button>
                                </Form.Item>

                            </Form>
                        </Modal>

                        {/*<div className="d-none">*/}
                        {/*    <div ref={componentRef}>*/}
                        {/*        <KitchenToken order={order} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Layout>
                    :
                    <KitchenToken order={order}/>
            }
        </>
    );
};

export default CreateOrder;