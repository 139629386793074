import React, { useEffect, useState } from 'react';
import axios from '../config/axios';

//importing all components
import { Button, Col, Form, Input, message, Pagination, Row, Select } from 'antd';
import Layout from '../Components/Layout';
import OrderItem from '../Components/Order/OrderItem';
import OrderDetails from '../Components/Order/OrderDetails';
import CustomerDetails from '../Components/Customer/CustomerDetails';


const Orders = (props) => {

        const [form] = Form.useForm();

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [curretPage, setCurrentPage] = useState(1);
    const [stat, setStat] = useState({totalPage: '', totalData: ''});
    const [singleOrder, setSingleOrder] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const [singleUserID, setSingleUserID] = useState(null);
    const [showFilters, setShowFilters] = useState(false);
    const [filers, setFilters] = useState({});


    useEffect(() => {
        setLoading(true);
        axios.get(`/admin/orders/v1/getOrders/${curretPage}`, {
            withCredentials: true, params: {
                order_id: filers.order_id??null,
                status: filers.status??null,
                payment_status: filers.payment_status??null,
                payment_method: filers.payment_method??null,
                sales_channel: filers.sales_channel??null,
                phone_number: filers.phone_number??null,
                name: filers.name??null,
            }
        }).then(response => {

            // console.log(response.data);

            setOrders(response.data.orders);

            setStat({
                totalPage: response.data.totalPage,
                totalData: response.data.totalOrders
            });

            setLoading(false);
        });
    }, [refresh,  curretPage, filers]);


    //defining paginationHangler function
    const paginationHangler = (page) => {
        setCurrentPage(page);
    }


    //defining refreshNow function
    const refreshNow = () => {
        setRefresh(Math.random());
    }



    return (
        <>
            <Layout>
                <h2 className="breadcrumb-custom"><span>Dashboard</span> {'>'}<span> Order Management</span>
                </h2>

                <div className={'card p-4 mb-4'}
                     style={{
                         border: 'none',
                         overflow: 'hidden',
                         height: (window.innerWidth < 577 && !showFilters) ? '55px' : null
                     }}>
                    <Row gutter={[0, 10]}>
                        <Col span={24}>
                            <div className={'d-flex align-items-center justify-content-between'}>
                                <div className="dashboard-page-header__left" style={{flex: '0 0 50%'}}>
                                    <h3 className="dashboard-page-header__left--title">All Orders</h3>
                                    <svg onClick={refreshNow} aria-hidden="true" focusable="false" data-prefix="fal"
                                         data-icon="redo" role="img"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor"
                                              d="M492 8h-10c-6.627 0-12 5.373-12 12v110.625C426.804 57.047 346.761 7.715 255.207 8.001 118.82 8.428 7.787 120.009 8 256.396 8.214 393.181 119.166 504 256 504c63.926 0 122.202-24.187 166.178-63.908 5.113-4.618 5.354-12.561.482-17.433l-7.069-7.069c-4.503-4.503-11.749-4.714-16.482-.454C361.218 449.238 311.065 470 256 470c-117.744 0-214-95.331-214-214 0-117.744 95.331-214 214-214 82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12z"/>
                                    </svg>
                                </div>
                                <Button type={'primary'} danger={showFilters}
                                        onClick={() => setShowFilters(!showFilters)}>
                                    {
                                        !showFilters ?
                                            'Show Filters'
                                            :
                                            'Hide Filters'
                                    }
                                </Button>
                            </div>
                        </Col>
                        <Col span={24}>
                            <Form
                                style={{width: '100%'}}
                                layout={'vertical'}
                                onFinish={values=>setFilters(values)}
                                form={form}
                            >
                                <Row style={{width: '100%'}} gutter={[10, 0]}>
                                    <Col sm={4} xs={12}>
                                        <Form.Item name={'order_id'} label={'Order ID'}>
                                            <Input placeholder={'Search by Order ID'}/>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                        <Form.Item name={'status'} label={'Order Status'}>
                                            <Select placeholder={'Filter by Order Status'}>
                                                <Select.Option value={'pending'}>Pending</Select.Option>
                                                <Select.Option value={'processing'}>Processing</Select.Option>
                                                <Select.Option value={'delivered'}>Delivered</Select.Option>
                                                <Select.Option value={'cancelled'}>Cancelled</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                        <Form.Item name={'sales_channel'} label={'Sales Channel'}>
                                            <Select placeholder={'Filter by Sales Channel'}>
                                                <Select.Option value={'online'}>Online</Select.Option>
                                                <Select.Option value={'store'}>Store</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                        <Form.Item name={'payment_method'} label={'Payment Method'}>
                                            <Select style={{maxWidth: '100%'}} placeholder={'Filter by Payment Method'}>
                                                <Select.Option value={'cash'}>Cash</Select.Option>
                                                <Select.Option value={'bKash'}>Bkash</Select.Option>
                                                <Select.Option value={'COD'}>COD</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                        <Form.Item name={'payment_status'} label={'Payment Status'}>
                                            <Select placeholder={'Filter by Payment Status'}>
                                                <Select.Option value={'paid'}>Paid</Select.Option>
                                                <Select.Option value={'due'}>Due</Select.Option>
                                                <Select.Option value={'refund'}>Refund</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                        <Form.Item name={'phone_number'} label={'Phone Number'}>
                                            <Input type={'number'} inputMode={'numeric'}
                                                   placeholder={'Filter by Phone Number'}/>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                        <Form.Item name={'name'} label={'Customer Name'}>
                                            <Input type={'text'} placeholder={'Filter by Phone Number'}/>
                                        </Form.Item>
                                    </Col>
                                    <Col sm={6} xs={12}>
                                        <Form.Item label={'Actions'}>
                                            <div className={'d-flex align-items-center gap-3'}>
                                                <Button htmlType={'submit'} type={'primary'}>Apply</Button>
                                                <Button onClick={()=>{
                                                    setFilters({});
                                                    form.resetFields();
                                                }} danger={true}>Clear</Button>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </div>

                {/* All Orders Here */}
                <div className="dashboard-table-wrapper">

                    {
                        loading ?
                            <div style={{height: '60vh'}} className="loader"><img src="/loader.svg" alt=""/></div>
                            :
                            <>
                                <table className="table table-responsive">
                                    <tbody>
                                    <tr style={{border: "1px solid var(--color-grey-green)"}}>
                                        <th scope="row">ID</th>
                                        <th scope="row">Customer</th>
                                        <th scope="row">SC</th>
                                        <th scope="row">Amount</th>
                                        <th style={{width: '120px'}} scope="row">Status</th>
                                        <th scope={'row'}>Payment</th>
                                        <th scope="row">Timestamp</th>
                                        <th scope="row">Delivery</th>
                                        {/*<th style={{width: '120px'}} scope="row">Status</th>*/}
                                        <th scope="row">Actions</th>
                                    </tr>

                                    {/* Doing The Loop Here */}
                                    {
                                        orders.map(order => {
                                            return <OrderItem
                                                key={order.orderID}
                                                order={order}
                                                setSingleOrder={setSingleOrder}
                                                setSingleUserID={setSingleUserID}
                                                refresh={refreshNow}
                                            />
                                        })
                                    }

                                    </tbody>
                                </table>
                            </>
                    }


                    <div className="pagination">
                        <Pagination current={curretPage} pageSize={20} total={stat.totalData}
                                    onChange={paginationHangler}/>

                        <span> Total <span className='pagination-span'>{stat.totalData}</span> Orders </span>
                    </div>
                </div>


                {/* Single Order Details Drawer Section */}
                <OrderDetails
                    singleOrder={singleOrder}
                    setSingleOrder={setSingleOrder}
                    refresh={refreshNow}
                />

                {/* Single Customer Details Drawer Section */}
                <CustomerDetails
                    singleUserID={singleUserID}
                    setSingleUserID={setSingleUserID}
                />


            </Layout>

        </>
    );
}


export default Orders;