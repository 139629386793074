import React, { useEffect, useState } from 'react';
import axios from '../config/axios';

//imoprting all components
import { Col, message, Row } from 'antd';
import Layout from '../Components/Layout';
import VariantsCard from '../Components/Products/VariantsCard';

const ProductVariant = props => {

    const [color, setColor] = useState([]);
    const [colors, setColors] = useState([]);
    const [variant, setVariant] = useState({sku: '', size: '', stock: ''});
    const [variants, setVariants] = useState([]);
    const [product, setProduct] = useState({});
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(null);


    useEffect(() => {
        axios.get(`/admin/products/v1/single/${props.match.params.productID}`, {withCredentials: true}).then(response => {

            setProduct(response.data.product);
            setColors(response.data.product.colors);

            setVariants(response.data.product.variant);
        });


    }, [refresh]);

    //defining colorOnCLickHandle function
    const colorOnCLickHandle = (color) => {
        setColor(color);
    }

    //defining onChangeHandler function
    const onChangeHandler = (e) => {
        e.preventDefault();

        setVariant({...variant, [e.target.name]: e.target.value});
    }

    //defining onSubmitHandler function 
    const onSubmitHandler = () => {


        setLoading(true);

        let formData = new FormData;

        formData.append('productID', props.match.params.productID);
        formData.append('sku', variant.sku);
        formData.append('color', color);
        formData.append('size', variant.size);
        formData.append('stock', variant.stock);

        axios.post(`/admin/products/v1/create/flow-3`, formData, {withCredentials: true}).then(responese => {

            setLoading(false);

            resetForm();

            message.success({
                content: `Product Variant Was Created Successfully!`,
            });
        }).catch(error=>{
            message.error(error.response.data?.msg? error.response.data.msg : 'A Server-Side Error Occurred!');
            setLoading(false);
        })


    }

    //defining resetForm function
    function resetForm() {
        setVariant({sku: '', size: '', stock: ''});
        setColor('');
        setRefresh(Math.random());
    }

    //defining refreshNow function
    const refreshNow = () => {
        setRefresh(Math.random());
    }


    return (
        <>
            <Layout>
                <h2 className="breadcrumb-custom"><span>Dashboard</span> {'>'} <span>Products</span> {'>'} <span> Edit Product Variant</span>
                </h2>
                <h1>Add Variant of Product ID: {product._id} <br/> Name: {product.name}</h1>

                <Row gutter={[20,20]}>
                    <Col span={24}>
                        <div style={{backgroundColor: 'var(--color-white)', padding: '4rem'}}
                             className="dashboard-half">

                            <h1 style={{marginLeft: '1rem'}}>Add New Product Variant</h1>

                            <label className="blabel" htmlFor="">Variant Sku</label>
                            <input onChange={onChangeHandler} value={variant.sku} className="binput" type="text"
                                   name="sku" id=""/>

                            <label className="blabel" htmlFor="">Variant Name (Click to Select)</label>
                            <div className="color-button-box">

                                {
                                    colors.map(theColor => {
                                        return <button key={theColor} onClick={() => colorOnCLickHandle(theColor)}
                                                       className={color === theColor ? 'color-button-active' : 'color-button'}>{theColor}</button>
                                    })
                                }

                            </div>


                            <label className="blabel" htmlFor="">Variant Stock</label>
                            <input onChange={onChangeHandler} value={variant.stock} type="number" name="stock"
                                   className="binput"/>

                            {
                                loading ?
                                    <button disabled style={{marginTop: "4rem"}} className="bbutton bbutton-loading">
                                        <img src="/three-dot.svg" width="12%" alt=""/></button>
                                    :
                                    <button onClick={onSubmitHandler} style={{marginTop: '4rem', width: '100%'}}
                                            className="bbutton bbutton-primary">Create Product Variant</button>
                            }
                        </div>
                    </Col>
                    <Col span={24}>
                        {/* All Variants */}
                        <div style={{backgroundColor: 'var(--color-white)', padding: '4rem'}}
                             className="dashboard-half">
                            <h1 style={{marginLeft: '1rem'}}>Existing Product Variant(s)</h1>
                            <VariantsCard
                                variants={variants}
                                productID={product._id}
                                refresh={refreshNow}
                            />

                        </div>
                    </Col>
                </Row>


            </Layout>

        </>
    );
}

export default ProductVariant;