import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, Radio, Col, DatePicker, Drawer, message, Row, Table, Tag, Input, Select } from "antd";
import moment from "../../config/moment";
import axios from '../../config/axios';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { BsDashCircleDotted, BsPlusCircleDotted } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { AuthContext } from "../../Context-Apis/AuthContext";

const StockExport = ({setShowStockReport}) => {

    return (
        <div style={{width: '210mm'}}>

            <CurrentStockReport setShowStockReport={setShowStockReport}/>

        </div>
    );
};

export default StockExport;


const CurrentStockReport = ({setShowStockReport}) => {

    const {user} = useContext(AuthContext);
    const {Search} = Input;

    const [products, setProducts] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [edit, setEdit] = useState(null);

    const [total, setTotal] = useState(0);

    useEffect(() => {
        axios.get(`/admin/products/v1/stock/report`, {
            withCredentials: true, params: {
                category: '641b085b0c643e52baa19130'
            }
        }).then(response => {
            setProducts(response.data.products);

            let total_stock = 0;
            response.data.products.forEach(product => {
                console.log(product.p_stock);
                total_stock += product.p_stock;
            })

            console.log(total_stock);
            setTotal(total_stock);

        }).catch(err => {
            message.error(err.response.data?.msg ? err.response.data.msg : 'A server side error occurred');
        })
    }, [refresh]);

    useEffect(() => {
        if (products.length > 0) {
            window.print();
        }
    }, [products]);


    const columns = [
        {
            title: 'Image',
            dataIndex: 'featuredImage',
            key: 'featuredImage',
            render: value => <img style={{width: '50px'}} src={value} alt=""/>
        },
        {
            title: 'Product',
            dataIndex: 'name',
            key: 'name',
            render: value => <span>{value}</span>
        },
        {
            title: 'SKU',
            dataIndex: 'p_sku',
            key: 'p_sku',
            render: (name, item) => <span>
                <Tag color={'processing'}>{name}</Tag>
            </span>
        },
        {
            title: 'Stock',
            dataIndex: 'p_stock',
            key: 'p_stock',
            defaultSortOrder: 'descend',
            render: (value, product) => <div className={'d-flex align-items-center gap-2'}>
                <Tag color={value > 0 ? 'green' : 'red'}>{value > 0 ? 'In Stock' : 'Out of Stock'}</Tag>
            </div>,
            sorter: (a, b) => a.p_stock - b.p_stock
        },
    ]
    return (
        <div>
            <div className={'d-flex flex-column align-items-center justify-content-center m-5'}>
                <img style={{width: '100px'}} src="/logo.svg" alt="" className={'mb-2'}/>
                <span>www.vapexbd.com</span>
            </div>
            <Table dataSource={products} columns={columns} pagination={false}/>
        </div>
    )
}
