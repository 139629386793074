import React, { useContext, useEffect, useState } from 'react';
import { Badge, Button, Radio, Col, DatePicker, Drawer, message, Row, Table, Tag, Input, Select } from "antd";
import moment from "../../config/moment";
import axios from '../../config/axios';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { BsDashCircleDotted, BsPlusCircleDotted } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { AuthContext } from "../../Context-Apis/AuthContext";

const StockReport = ({setShowStockReport}) => {

    const [selected, setSelected] = useState('currentStock');

    return (
        <Drawer
            open={true}
            width={'95%'}
            title={'Stock Reports'}
            onClose={() => setShowStockReport(false)}
        >
            <Radio.Group onChange={e => setSelected(e.target.value)} value={selected} buttonStyle="solid">
                <Radio.Button value="currentStock">Current Stock</Radio.Button>
                <Radio.Button value="stockChangeLogs">Stock Change Logs</Radio.Button>
            </Radio.Group>
            <br/>
            <br/>
            {
                selected === 'currentStock' ?
                    <CurrentStockReport setShowStockReport={setShowStockReport}/>
                    :
                    <StockLogsReport setShowStockReport={setShowStockReport}/>
            }

        </Drawer>
    );
};

export default StockReport;


const CurrentStockReport = ({setShowStockReport}) => {

    const {user} = useContext(AuthContext);
    const {Search} = Input;

    const [products, setProducts] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [edit, setEdit] = useState(null);

    const [total, setTotal] = useState(0);

    useEffect(() => {
        axios.get(`/admin/products/v1/stock/report`, {
            withCredentials: true, params: {
                category: '641b085b0c643e52baa19130'
            }
        }).then(response => {
            setProducts(response.data.products);

            let total_stock = 0;
            let total_pxp = 0;
            response.data.products.forEach(product=>{
                // console.log(product);
                total_stock+=product.p_stock;
                total_pxp = total_pxp + product.variant[0].pxp * product.p_stock
                // product.variant.forEach(variant=>{
                //     total_pxp = total_pxp + (variant.stock * variant.pxp)
                // })
            })

            console.log(total_stock);
            console.log(total_pxp);
            setTotal(total_stock);

        }).catch(err => {
            message.error(err.response.data?.msg ? err.response.data.msg : 'A server side error occurred');
        })
    }, [refresh]);

    const stockUpdateHandler = (stockValue, productID, SKU) => {
        console.log(stockValue, productID, SKU);

        if (!stockValue || stockValue == '') {
            message.error({
                content: 'Minimum stock value is 0'
            })
        } else {
            let formData = new FormData;
            formData.append('stock', stockValue);
            formData.append('sku', SKU);

            axios.put(`/admin/products/v1/stock-update/${productID}`, formData, {withCredentials: true}).then(response => {
                setEdit(null);
                setRefresh(Math.random());
                message.success({
                    content: 'Product stock was updated'
                })

            })
        }
    }

    const columns = [
        {
            title: 'Image',
            dataIndex: 'featuredImage',
            key: 'featuredImage',
            render: value => <img style={{width: '50px'}} src={value} alt=""/>
        },
        {
            title: 'Product',
            dataIndex: 'name',
            key: 'name',
            render: value => <span>{value}</span>
        },
        {
            title: 'SKU',
            dataIndex: 'p_sku',
            key: 'p_sku',
            render: (name, item) => <span>
                <Tag color={'processing'}>{name}</Tag>
            </span>
        },
        {
            title: 'Stock',
            dataIndex: 'p_stock',
            key: 'p_stock',
            defaultSortOrder: 'descend',
            render: (value, product) => <div className={'d-flex align-items-center gap-2'}>
                {
                    edit === product.p_sku ?
                        <div>
                            <Search
                                defaultValue={value}
                                enterButton={'Update'}
                                style={{maxWidth: '150px', minWidth: '150px'}}
                                onSearch={stock => stockUpdateHandler(stock, product._id, product.p_sku)}
                            />
                        </div>
                        :
                        <div className={'d-flex align-items-center justify-content-center'} style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            background: value > 0 ? 'green' : 'red',
                            color: 'white',
                            fontSize: '11px',
                            fontWeight: 'bold'
                        }}>
                            {value}
                        </div>
                }
                <AiOutlineEdit size={20} style={{cursor: 'pointer'}}
                               onClick={() => setEdit(edit ? null : product.p_sku)}/>
            </div>,
            sorter: (a, b) => a.p_stock - b.p_stock
        },
    ]
    return (

        <Row gutter={[10, 10]}>
            {
                user.is_tier_one &&
                <Col span={24}>
                Total Stock: {total} Units
            </Col>
            }
            <Col span={24}>
                <Table dataSource={products} columns={columns} pagination={false}/>
            </Col>
        </Row>

    )
}

const StockLogsReport = ({setShowStockReport}) => {
    const [logs, setLogs] = useState([]);

    const [products, setProducts] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [page, setPage] = useState(1);
    const [dataCount, setDataCount] = useState(0);
    const [sku, setSku] = useState(null);

    useEffect(() => {
        axios.get(`/admin/products/v1/stock/logs`, {
            withCredentials: true, params: {
                page: page,
                limit: 40,
                sku: sku? sku : null
                // category: '641b085b0c643e52baa19130'
            }
        }).then(response => {
            setLogs(response.data.result);
            setDataCount(response.data.dataCount);
        }).catch(err => {
            message.error(err.response.data?.msg ? err.response.data.msg : 'A server side error occurred');
        });
    }, [refresh, page, sku]);


    useEffect(() => {
        axios.get(`/admin/products/v1/stock/report`, {
            withCredentials: true, params: {
                category: '641b085b0c643e52baa19130'
            }
        }).then(response => {
            setProducts(response.data.products);
        }).catch(err => {
            message.error(err.response.data?.msg ? err.response.data.msg : 'A server side error occurred');
        });
    }, []);

    const columns = [
        {
            title: 'Product',
            dataIndex: 'product',
            key: 'product',
            render: (item, log) => <span>{item.name} - {log.variant.color}</span>
        },
        {
            title: 'SKU',
            dataIndex: 'variant',
            key: 'variant',
            render: value => <Tag color={'processing'}>{value.sku}</Tag>
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            key: 'qty',
            render: (qty, log) => <span className={'d-flex align-items-center gap-2'}>
                {
                    log.event_type === 'inc' ?
                        <BsPlusCircleDotted color={'green'} size={18}/>
                        :
                        <BsDashCircleDotted color={'red'} size={18}/>
                }
                <Badge color={log.event_type === 'inc' ? 'green' : 'red'} count={Math.abs(qty)}/>
            </span>
        },
        {
            title: 'Details',
            dataIndex: 'details',
            key: 'details',
            render: value => <span>{value}</span>
        },
        {
            title: 'Timestamp',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: value => <span>{moment(value).format('DD MMM, YY - LT')}</span>
        },
    ]
    return (

        <Row gutter={[10, 10]}>
            <Col span={24}>
                <Select
                    style={{minWidth: '350px', maxWidth: '350px'}}
                    placeholder={'Filter by Product'}
                    onChange={value => {
                        setSku(value ? value.split('_')[0] : null)
                    }}
                    allowClear={true}
                    showSearch={true}
                >
                    {
                        products.map((product, index) => {
                            return <Select.Option key={index} value={`${product.p_sku}_${product.name}`}>{product.name}</Select.Option>
                        })
                    }
                </Select>
            </Col>
            <Col span={24}>
                <Table dataSource={logs} columns={columns} pagination={{
                    total: dataCount,
                    current: page,
                    pageSize: 40,
                    onChange: (nextPage) => setPage(nextPage)
                }}/>
            </Col>
        </Row>

    )
}