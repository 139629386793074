import React, {useEffect, useState} from "react";
import axios from '../config/axios';


//importing all components
import Layout from '../Components/Layout';
import {Pagination} from 'antd';
import ReturnItem from '../Components/Return/ReturnItem';
import ReturnDetails from '../Components/Return/ReturnDetails';

const Refunds = (props) => {

  const [returns, setReturns] = useState([]);
  const [singleReturnID, setSingleReturnID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [stat, setStat] = useState({totalPage: '', totalData: ''});
  const [curretPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(null);

  //the useEffect hook
  useEffect(() => {

    setLoading(true);

    axios.get(`/admin/returns/v1/getReturns/${curretPage}`, {withCredentials: true}).then(response => {

      setReturns(response.data.returns);
      setStat({
        totalPage: response.data.totalPage,
        totalData: response.data.totalReturns
      });
      setLoading(false);

    }).catch(error => {
      if (error.response.status === 401) {
        window.location.replace('https://dashboard.vapexbd.com')
      }
    })

  }, [refresh]);

  //defining paginationHangler function
  const paginationHangler = (page) => {
    setCurrentPage(page);
  }


  return (
    <>

      <Layout>
        <h2 className="breadcrumb-custom"><span>Dashboard</span> {'>'} <span>Products</span> {'>'} <span> Orders & Shipping Managment</span>
        </h2>

        <div className="dashboard-page-header">
          <div className="dashboard-page-header__left">
            <h3 className="dashboard-page-header__left--title">All Refunds</h3>
            <svg onClick={() => setRefresh(Math.random())} aria-hidden="true" focusable="false" data-prefix="fal"
                 data-icon="redo" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor"
                    d="M492 8h-10c-6.627 0-12 5.373-12 12v110.625C426.804 57.047 346.761 7.715 255.207 8.001 118.82 8.428 7.787 120.009 8 256.396 8.214 393.181 119.166 504 256 504c63.926 0 122.202-24.187 166.178-63.908 5.113-4.618 5.354-12.561.482-17.433l-7.069-7.069c-4.503-4.503-11.749-4.714-16.482-.454C361.218 449.238 311.065 470 256 470c-117.744 0-214-95.331-214-214 0-117.744 95.331-214 214-214 82.862 0 154.737 47.077 190.289 116H332c-6.627 0-12 5.373-12 12v10c0 6.627 5.373 12 12 12h160c6.627 0 12-5.373 12-12V20c0-6.627-5.373-12-12-12z"/>
            </svg>
          </div>
          <div className="dashboard-page-header__right">

            {/* <select onChange={filterByOrderStatus} style={{ flex: '0 0 30%' }} className="bselect" name="" id="">
                            <option value={0}>All</option>
                            <option value={1}>Ordered</option>
                            <option value={2}>Processing</option>
                            <option value={3}>In Transit</option>
                            <option value={4}>RBC</option>
                            <option value={5}>Picked</option>
                            <option value={6}>Delivered</option>
                            <option value={7}>Cancelled</option>
                        </select> */}


            {/* <input style={{ flex: '0 0 32%' }} className="binput" placeholder="Search By OrderID" type="text" onKeyDown={searchByOrderID} />

                        <input style={{ flex: '0 0 32%' }} className="binput" placeholder="Search By Customer Name" type="text" onKeyDown={searchByCustomerName} /> */}

          </div>
        </div>


        {/* All Orders Here */}
        <div className="dashboard-table-wrapper">

          {
            loading ?
              <div style={{height: '60vh'}} className="loader"><img src="/loader.svg" alt=""/></div>
              :
              <>
                <table className="table table-responsive">
                  <tbody>
                  <tr style={{border: "1px solid var(--color-grey-green)"}}>
                    <th scope="row">Order ID</th>
                    <th scope="row">Date</th>
                    <th scope="row">Refund Request Date</th>
                    <th scope="row">Customer</th>
                    <th scope="row">Reason</th>
                    {/* <th scope="row">Delivery</th> */}
                    <th style={{width: '120px'}} scope="row">Method</th>
                    <th style={{width: '120px'}} scope="row">Return Amounts</th>
                    <th style={{width: '120px'}} scope="row">Status</th>
                    <th scope="row">Details</th>
                  </tr>

                  {/* Doing The Loop Here */}
                  {
                    returns.map(returnX => {
                      return <ReturnItem
                        key={returnX.orderID}
                        returnX={returnX}
                        setSingleReturnID={setSingleReturnID}
                        //setSingleUserID={setSingleUserID}
                        //refresh={refreshNow}
                      />
                    })
                  }

                  </tbody>
                </table>
              </>
          }


          <div className="pagination">
            <Pagination current={curretPage} pageSize={20} total={stat.totalData} onChange={paginationHangler}/>

            <span> Total <span className='pagination-span'>{stat.totalData}</span> Returns </span>
          </div>
        </div>


        {/* Single Return Details Section */}
        <ReturnDetails
          singleReturnID={singleReturnID}
          setSingleReturnID={setSingleReturnID}
        />


      </Layout>

    </>
  );
}

export default Refunds;