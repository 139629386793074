import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./global.scss";
//importing all views
import Categories from "./Views/Categories";
import Coupons from "./Views/Coupons";
import CreateProduct from "./Views/CreateProduct";
import Customers from "./Views/Customers";
import EditGallery from "./Views/EditGallery";
import EditProduct from "./Views/EditProduct";
import Landing from "./Views/Landing/Landing";
import Login from "./Views/Login";
import Orders from "./Views/Orders";
import ProductGallery from "./Views/ProductGallery";
import ProductVariant from "./Views/ProductVariant";
import Products from "./Views/Products";
import Refunds from "./Views/Refunds";
import SubCategories from "./Views/SubCategories";
import { notification } from "antd";
import { AiOutlineWifi } from "react-icons/ai";
import { BsWifiOff } from "react-icons/bs";
import PrivateRoute from "./Hocs/PrivateRoute";
import UnPrivateRoute from "./Hocs/UnPrivateRoute";
import Brands from "./Views/Brands";
import CreateOrder from "./Views/CreateOrder/CreateOrder";
import Invoice from "./Views/Invoice/Invoice";
import CreateExpense from "./Views/CreateExpense/CreateExpense";
import Expenses from "./Views/Expenses/Expenses";
import InventoryIn from "./Views/Inventory/InventoryIn";
import InventoryOut from "./Views/Inventory/InventoryOut";
import Inventory from "./Views/Inventory/Inventory";
import ContactSubmission from "./Views/ContactSubmission/ContactSubmission";
import SendBulkSMS from "./Views/SendBulkSMS/SendBulkSMS";
import VapexXShamim from "./Views/VapexXShamim/VapexXShamim";
import Label from "./Views/Invoice/Label";
import CreateCustomer from "./Views/CreateCustomer/CreateCustomer";
import StockExport from "./Views/StockExport/StockExport";

function App() {
    useEffect(() => {
        function handleOnlineStatus() {
            const isOnline = window.navigator.onLine;
            if (isOnline) {
                notification.destroy();
                notification.open({
                    message: "Your internet connection is back!",
                    duration: 2,
                    icon: <AiOutlineWifi style={{ color: "green" }} />,
                    maxCount: 1,
                    onClose: () => {
                        notification.destroy();
                    },
                });
            } else {
                // show "You are offline" notification
                notification.open({
                    message: "You are offline",
                    description: "Please check your internet connection",
                    duration: 0,
                    icon: <BsWifiOff style={{ color: "#ff4d4f" }} />,
                    maxCount: 1,
                    closeIcon: <></>,
                    destroyOnClose: true,
                });
            }
        }

        window.addEventListener("online", handleOnlineStatus);
        window.addEventListener("offline", handleOnlineStatus);

        return () => {
            window.removeEventListener("online", handleOnlineStatus);
            window.removeEventListener("offline", handleOnlineStatus);
        };
    }, []);

    return (
        <Router>
            <UnPrivateRoute exact path="/login" component={Login} />

            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/" component={Landing} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/brands" component={Brands} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/categories" component={Categories} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/sub-categories" component={SubCategories} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/products" component={Products} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/products/create" component={CreateProduct} />
            <PrivateRoute
                roles={["UN7vBlHbP9ad"]}
                exact
                path="/products/product-variant/:productID"
                component={ProductVariant}
            />
            <PrivateRoute
                roles={["UN7vBlHbP9ad"]}
                exact
                path="/products/product-gallery/:productID"
                component={ProductGallery}
            />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/products/edit/:productID" component={EditProduct} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/orders" component={Orders} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/customers" component={Customers} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/coupons" component={Coupons} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/refunds" component={Refunds} />
            <PrivateRoute
                roles={["UN7vBlHbP9ad"]}
                exact
                path="/products/edit-gallery/:productID/:galleryIndex"
                component={EditGallery}
            />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/create-order" component={CreateOrder} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/invoice/:invoiceID" component={Invoice} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/label/:invoiceID" component={Label} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/create-expense" component={CreateExpense} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/expenses" component={Expenses} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/inventory" component={Inventory} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/inventory-in" component={InventoryIn} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/inventory-out" component={InventoryOut} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/contact-submissions" component={ContactSubmission} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/send-bulk-sms" component={SendBulkSMS} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/vapex-x-codenine" component={VapexXShamim} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/create-customer" component={CreateCustomer} />
            <PrivateRoute roles={["UN7vBlHbP9ad"]} exact path="/stock-export" component={StockExport} />
        </Router>
    );
}

export default App;
