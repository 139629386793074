import React from 'react';
import { useState, useEffect } from 'react';
import axios from '../config/axios';

//importing all components
import { SketchPicker } from 'react-color';
import { message, Checkbox } from 'antd';
import Layout from '../Components/Layout';

const EditProduct = (props) => {

    const [product, setProduct] = useState({
        name: '',
        description: '',
        brand: '',
        category: '',
        subCategory: '',
        regularPrice: '',
        salePrice: '',
        texture: 'print',
        otherFunctions: ''
    });
    const [featuredImage, setFeaturedImage] = useState(null);
    const [tags, setTags] = useState([]);
    const [features, setFeatures] = useState([]);
    const [featureHolder, setFeatureHolder] = useState('');
    const [tagHolder, setTagHolder] = useState('');
    const [colors, setColors] = useState([]);
    const [genders, setGenders] = useState([]);
    const [colorHolder, setColorHolder] = useState('');
    const [brands, setBrands] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [shamimBhaiRefresh, setShamimBhaiRefresh] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isRefundable, setIsRefundable] = useState();

    const [details, setDetails] = useState({
        madeIn: ''
    });


    useEffect(() => {
        axios.get(`/admin/products/v1/single/${props.match.params.productID}`, {withCredentials: true}).then(response => {

            axios.get(`/admin/category/v1`, {withCredentials: true}).then(response => {
                setCategories(response.data.categories);
            });

            axios.get(`/admin/category/brands/v1`, {withCredentials: true}).then(response => {
                setBrands(response.data.brands);
            });

            axios.get(`/admin/category/sub/v1`, {withCredentials: true}).then(response => {
                setSubCategories(response.data.subCategories);
            });

            console.log(response.data.product);

            setProduct({
                name: response.data.product.name,
                description: response.data.product.description,
                brand: response.data.product.brand,
                category: response.data.product.category,
                subCategory: response.data.product.subCategory ? response.data.product.subCategory : '',
                regularPrice: response.data.product.regularPrice,
                salePrice: response.data.product.salePrice,
                texture: response.data.product.texture ? response.data.product.texture : '',
                otherFunctions: response.data.product.otherFunctions
            });

            setFeatures(response.data.product.features ? response.data.product.features : []);

            setTags(response.data.product.tags);

            setColors(response.data.product.colors);

            setFeaturedImage(response.data.product.featuredImage);

            setIsRefundable(response.data.product.isRefundable);


            setDetails({
                madeIn: response.data.product.details ? response.data.product.details.madeIn : '',
            });
        })
    }, []);


    //window/tab closing warning event listener
    // window.addEventListener("beforeunload", (ev) => {
    //     ev.preventDefault();
    //     return ev.returnValue = 'Are you sure you want to close?';
    // });


    const shamimBhai = () => {
        // console.log(product);
        // console.log(colors);
        // console.log(tags);
        console.log(genders);
    }


    //gender options
    const genderOptions = [
        {label: 'Men', value: 'men'},
        {label: 'Women', value: 'women'},
        {label: 'Unisex', value: 'Unisex'},
        {label: 'Children', value: 'children'},
    ];


    function removeItemOnce(arr, value) {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }


    //defining tagInputOnChange function
    const tagInputOnChange = (e) => {
        e.preventDefault();
        setTagHolder(e.target.value);
    }


    const featureInputOnChange = (e) => {
        e.preventDefault();
        setFeatureHolder(e.target.value);
    }


    //defining tagInputHandler function
    const tagInputHandler = (e) => {
        e.preventDefault();

        setTags(tags => tags.concat(tagHolder));
        setTagHolder('');
    }


    //defining rmeoveTag function
    const removeTag = (tag) => {

        setTags(removeItemOnce(tags, tag));
        setShamimBhaiRefresh(Math.random());
    }

    const featureInputHandler = (e) => {
        e.preventDefault();

        setFeatures(features => features.concat(featureHolder));
        setFeatureHolder('');
    }


    //defining rmeoveTag function
    const removeFeature = (feature) => {

        setFeatures(removeItemOnce(features, feature));
        setShamimBhaiRefresh(Math.random());
    }

    useEffect(() => {
        console.log(`Im use Effect`);
        setTags(tags);
    }, [shamimBhaiRefresh])


    //defining removeColor function
    const removeColor = (color) => {

        setColors(removeItemOnce(colors, color));
        setShamimBhaiRefresh(Math.random());
    }

    useEffect(() => {
        console.log(`Im use Effect`);
        setColors(colors);
    }, [shamimBhaiRefresh])


    //defining colorOnChange function
    const colorOnChange = (e) => {
        //setColorHolder(color.hex)
        setColorHolder(e.target.value);
    }


    //defining colorInputOnChange function
    const colorInputOnChange = (e) => {
        e.preventDefault();
    }


    //defining colorInputHandler function
    const colorInputHandler = (e) => {
        e.preventDefault();
        console.log(`color onsubmit trige`)
        setColors(colors => colors.concat(colorHolder));
    }

    //defininf productOnChange function
    const productOnChange = (e) => {
        e.preventDefault();

        setProduct({...product, [e.target.name]: e.target.value});
    }


    //defining onChangeIsRefundableHandler function
    const onChangeIsRefundableHandler = (e) => {
        setIsRefundable(e.target.checked);
    }

    //defining productDetailsOnChange function
    const productDetailsOnChange = (e) => {
        setDetails({...details, [e.target.name]: e.target.value});
    }


    //defining image upload onchange handler
    const imageUploadOnchangeHandler = (e) => {
        console.log(e.target.files[0]);

        let formData = new FormData;
        formData.append('image', e.target.files[0]);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        axios.post(`/admin/microservices/v1/imageUpload`, formData, config).then(responese => {
            console.log(responese.data.info);
            setFeaturedImage(responese.data.info.Location);
        });

    }


    //defining onSubmitHandler function
    const onSubmitHandler = () => {
        setLoading(true);
        let formData = new FormData;

        formData.append('name', product.name);
        formData.append('description', product.description);
        formData.append('category', product.category);
        formData.append('brand', product.brand);
        formData.append('subCategory', product.subCategory);
        formData.append('isRefundable', isRefundable);
        formData.append('regularPrice', product.regularPrice);
        formData.append('salePrice', product.salePrice);
        formData.append('colors', JSON.stringify(colors));
        formData.append('genders', JSON.stringify(genders));
        formData.append('tags', JSON.stringify(tags));
        formData.append('featuredImage', featuredImage);
        formData.append('texture', product.texture);
        formData.append('details', JSON.stringify(details));
        formData.append('features', JSON.stringify(features));

        axios.put(`/admin/products/v1/update/${props.match.params.productID}`, formData, {withCredentials: true}).then(responese => {
            console.log(responese.data);
            message.success({
                content: `Product Updated Successfully!`,
                style: {
                    marginTop: '85vh',
                    marginRight: '70vw'
                },
            })

            setTimeout(() => {
                props.history.push(`/products/product-gallery/${props.match.params.productID}`);
            }, 1000)
        });

        console.log(product);
    }


    return (
        <>

            <Layout>
                <h2 className="breadcrumb-custom"><span>Dashboard</span> {'>'} <span>Products</span> {'>'} <span> Update Existing Product</span>
                </h2>
                <div className="dashboard-full">

                    <div style={{backgroundColor: 'var(--color-white)', padding: '2rem'}} className="dashboard-half">

                        <label onClick={shamimBhai} className="blabel">Product Name</label>
                        <input onChange={productOnChange} name="name" value={product.name} className="binput"
                               type="text"/>

                        <label className="blabel">Product Featured Image</label>
                        <div className="file-input-master-wrapper">
                            <label className="file-input-box" htmlFor="file-6">
                                <svg className="file-input-svg" xmlns="http://www.w3.org/2000/svg" width={20}
                                     height={17} viewBox="0 0 20 17">
                                    <path
                                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
                                </svg>
                                <input onChange={imageUploadOnchangeHandler} type="file" id="file-6"
                                       className="binput-file"/>
                                <span className="file-input-label">Drag or Click to upload your image</span>
                            </label>
                            <div style={{justifyContent: 'center'}} className="file-input-master-wrapper__gallery">
                                {
                                    featuredImage ?
                                        <img className="file-input-master-wrapper__gallery--image" src={featuredImage}
                                             alt=""/> : null
                                }
                            </div>
                        </div>


                        <label className="blabel">Product Description</label>
                        <textarea onChange={productOnChange} value={product.description} className="ctext-area"
                                  name="description" id="" cols="30" rows="10"></textarea>


                        {/* <label className="blabel">Is Product Refundable</label> */}
                        <Checkbox style={{marginTop: '2rem'}} checked={isRefundable}
                                  onChange={onChangeIsRefundableHandler}>Is Product Refundable</Checkbox>


                        <label className="blabel">Product Category</label>
                        <select onChange={productOnChange} value={product.category} className='bselect' name="category">
                            <option value="ps">Please Select</option>
                            {
                                categories.map(category => {
                                    return <option value={category._id}> {category.name} </option>
                                })
                            }

                        </select>

                        <label className="blabel">Product SubCategory</label>
                        <select onChange={productOnChange} value={product.subCategory} className='bselect'
                                name="subCategory">
                            <option value="ps">Please Select</option>
                            {
                                subCategories.map(subCategory => {
                                    return <option value={subCategory._id}> {subCategory.name} </option>
                                })
                            }
                        </select>


                    </div>


                    <div style={{backgroundColor: 'var(--color-white)', padding: '2rem'}} className="dashboard-half">

                        <label className="blabel">Product Brand</label>
                        <select onChange={productOnChange} value={product.brand} className='bselect' name="brand">
                            <option value="ps">Please Select</option>
                            {
                                brands.map(brand => {
                                    return <option value={brand._id}> {brand.name} </option>
                                })
                            }

                        </select>


                        {/* <label className="blabel">Product Dimension</label>
                        <input onChange={productDetailsOnChange} placeholder="L x W x H" value={details.dimension} name="dimension" className="binput" type="text" />

                        <label className="blabel">Product Weight</label>
                        <input onChange={productDetailsOnChange} value={details.weight} name="weight" className="binput" type="number" />

                        <label className="blabel">Product Leather Type</label>
                        <input onChange={productDetailsOnChange} value={details.typeOfLeather} name="typeOfLeather" className="binput" type="text" /> */}

                        <label className="blabel">Product Made In(Country)</label>
                        <input onChange={productDetailsOnChange} value={details.madeIn} name="madeIn"
                               defaultValue="Bangladesh" className="binput" type="text"/>

                        <label className="blabel">Product Regular Price</label>
                        <input onChange={productOnChange} value={product.regularPrice} name="regularPrice"
                               className="binput" type="number"/>

                        <label className="blabel">Product Sale Price</label>
                        <input onChange={productOnChange} value={product.salePrice} name="salePrice" className="binput"
                               type="number"/>


                        <label className="blabel">Product Tags</label>
                        <div className="tags-wrapper">
                            {
                                tags.map(tag => {
                                    return <span key={tag} className="tag"> {tag}
                                        <svg onClick={() => removeTag(tag)} viewBox="0 0 24 24" width={14} height={14}
                                             stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round"
                                             strokeLinejoin="round"><line x1={18} y1={6} x2={6} y2={18}/><line x1={6}
                                                                                                               y1={6}
                                                                                                               x2={18}
                                                                                                               y2={18}/></svg></span>
                                })
                            }
                        </div>
                        <form onSubmit={tagInputHandler}>
                            <input value={tagHolder} onChange={tagInputOnChange} placeholder="Type A Tag and Hit Enter"
                                   className="binput" type="text"/>
                        </form>

                        <label className="blabel">Product Features</label>
                        <div className="tags-wrapper">
                            {
                                features.map(feature => {
                                    return <span key={feature} className="tag"> {feature}
                                        <svg onClick={() => removeFeature(feature)} viewBox="0 0 24 24" width={14} height={14}
                                             stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round"
                                             strokeLinejoin="round"><line x1={18} y1={6} x2={6} y2={18}/><line x1={6}
                                                                                                               y1={6}
                                                                                                               x2={18}
                                                                                                               y2={18}/></svg></span>
                                })
                            }
                        </div>
                        <form onSubmit={featureInputHandler}>
                            <input value={featureHolder} onChange={featureInputOnChange} placeholder="Feature Name:Feature Info"
                                   className="binput" type="text"/>
                        </form>


                        <label className="blabel">Product Available Variants</label>
                        <div className="tags-wrapper">
                            {
                                colors.map(color => {
                                    return <span style={{backgroundColor: `${color}`}} className="tag"> {color}
                                        <svg onClick={() => removeColor(color)} viewBox="0 0 24 24" width={14}
                                             height={14} stroke="currentColor" strokeWidth={2} fill="none"
                                             strokeLinecap="round" strokeLinejoin="round"><line x1={18} y1={6} x2={6}
                                                                                                y2={18}/><line x1={6}
                                                                                                               y1={6}
                                                                                                               x2={18}
                                                                                                               y2={18}/></svg></span>
                                })
                            }
                        </div>
                        <div style={{display: 'flex', alignItems: 'flex-start'}} className="product-color-box">
                            {/* <SketchPicker color={colorHolder} /> */}
                            <form onSubmit={colorInputHandler}>
                                <input style={{maxWidth: "200px", marginLeft: '0px'}} value={colorHolder}
                                       onChange={colorOnChange} placeholder="Color Code" className="binput"
                                       type="text"/>
                                <button type="submit" style={{width: "200px", marginLeft: '0px', marginTop: '2rem'}}
                                        className="bbutton bbutton-primary">Enter Name
                                </button>
                            </form>
                        </div>


                        {
                            loading ?
                                <button disabled style={{marginTop: "4rem"}} className="bbutton bbutton-loading"><img
                                    src="/three-dot.svg" width="12%" alt=""/></button>
                                :
                                <button onClick={onSubmitHandler} type='submit' style={{marginTop: "4rem"}}
                                        className="bbutton bbutton-primary">Update Product & Proceed To Next
                                    Step</button>
                        }

                    </div>
                </div>

            </Layout>

        </>
    );
}

export default EditProduct;