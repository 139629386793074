import { useState } from "react";
import axios from "../../config/axios";

//importing components
import Layout from "../../Components/Layout";
import { Form, Input, Button, message, Tag, Alert } from "antd";
import { IoIosClose } from 'react-icons/io';
import { hasUnicodeCharacters } from "../../utils";

const {TextArea} = Input;

const SendBulkSMS = () => {

    const [numbers, setNumbers] = useState([]);
    const [number, setNumber] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');

    //adding recipient to list
    const addRecipient = () => {
        if (number.length !== 11) {
            message.error('Number must be 11 digit!');
            return;
        }
        if (numbers.includes(number)) {
            message.error('Number already added to recipient list!');
            return;
        }
        setNumbers(numbers => numbers.concat(number));
        setNumber('');
    }

    const getSmsCount = () => {
        if (hasUnicodeCharacters(messageBody)) {
            console.log(messageBody.length)
            console.log(Math.ceil(messageBody.length / 160))
            return Math.ceil(messageBody.length / 70);
        } else {
            console.log(messageBody.length)
            console.log(Math.ceil(messageBody.length / 160))
            return Math.ceil(messageBody.length / 160);
        }
    }


    // send push notification function
    const sendSmsNow = async (values) => {
        if (!messageBody) {
            message.error('Message Body is required!');
            return;
        }

        if (numbers.length === 0) {
            message.error('Please add at least one recipient!');
            return;
        }

        if (password !== 'Taiwan_no_China') {
            message.error('Invalid Password');
            return;
        }

        setLoading(true);

        let payload = {
            message: messageBody,
            numbers: numbers
        }

        axios.post('/admin/crm/service/send-bulk-sms/v1', payload, {withCredentials: true}).then(response => {
            message.success('SMS Sent Successfully!');
            setNumber('');
            setNumbers([]);
            setMessageBody('');
            setLoading(false);
            setPassword('');
        })
    };
    return (
        <Layout pageName="Send SMS">
            <div className="card p-5">
                <h2 className="primary_h2"> Send SMS </h2>
                <div style={{display: 'flex', maxWidth: '400px'}}>
                    <Input
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        placeholder="Enter 11 Digit Phone Number"
                        maxLength={11}
                        minLength={11}
                        onPressEnter={addRecipient}
                    />
                    <Button
                        style={{marginLeft: '1rem'}}
                        type="primary"
                        onClick={addRecipient}
                    >
                        Add Number
                    </Button>
                </div>
                <div style={{marginTop: '2rem', display: 'flex'}}>
                    <span style={{marginRight: '1rem'}}>Recipients: </span>
                    {
                        numbers.map((number, index) => {
                            return <Tag
                                color={'success'}
                                style={{display: 'flex', alignItems: 'center', width: 'fit-content', cursor: 'pointer'}}
                                onClick={() => setNumbers(numbers.filter(num => num !== number))}
                                key={index}
                            >
                                {number}
                                <IoIosClose size={20} color={'var(--color-red)'}/>
                            </Tag>
                        })
                    }
                </div>
                <br/>
                <Alert message="এসএমএস অবশ্যই বাংলায় হতে হবে!" type="error"/>
                <TextArea
                    style={{marginTop: '1rem'}}
                    placeholder="Write SMS Body Here"
                    onChange={e => {
                        setMessageBody(e.target.value);
                        console.log(hasUnicodeCharacters(e.target.value))
                    }}
                    value={messageBody}
                />
                <span style={{marginTop: '1rem', display: 'block'}}>
                    Type: {hasUnicodeCharacters(messageBody) ? 'Unicode SMS' : 'Regular SMS'} / Characters: {messageBody.length} / SMS Count: {getSmsCount()} / Total Cost: {parseFloat(numbers.length * getSmsCount() * 0.25).toFixed(2)} Taka
                </span>

                <div className={'row d-flex mt-5'} style={{maxWidth: '400px'}}>
                    <div className={'col-6'}>
                        <Input
                            type={'password'}
                            placeholder={'Enter Password'}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                    <div className={'col-6'}>
                        <Button
                            type='primary'
                            loading={loading}
                            disabled={loading}
                            onClick={sendSmsNow}
                        >
                            Send SMS
                        </Button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default SendBulkSMS;
