import React, {useEffect, useState} from 'react';
import axios from '../../config/axios';
import {message} from 'antd';

const EditCategory = (props) => {

  const [category, setCategory] = useState({name: '', description: ''});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCategory({
      name: props.category.name,
      description: props.category.description
    })
  }, [props.category]);

  //defining closeEditCategory function
  const closeEditCategory = () => {
    props.closeEditCategory();
  }


  //defining onChangeHandler function
  const onChangeHandler = (e) => {
    e.preventDefault();

    setCategory({...category, [e.target.name]: e.target.value});
  }


  //defining onSubmitHandler function
  const onSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    axios.put(`/admin/category/v1/update/${props.category._id}`, category, {withCredentials: true}).then(response => {
      if (response.status === 200) {
        props.refresh();
        setLoading(false);
        message.success({
          content: `Category Was Updated Successfully!`,
          style: {
            marginTop: '85vh',
            marginRight: '70vw'
          },
        })
      } else {
        console.log(response.status)
      }
    });
  }


  return (
    <>

      <div className={props.drawerClass || 'pop-drawer closed'}></div>
      <div style={{width: `${props.width}%`}} className={props.drawerBodyClass || `pop-drawer__body-closed`}>
        <div className="pop-drawer__body__wrapper">
          <h2>Edit Category - {category.name}</h2>

          <form name="EditCategory" onSubmit={onSubmitHandler}>

            {/* <label className="blabel">Category Image</label>
            <div className="file-input-master-wrapper">
                <label className="file-input-box" htmlFor="file-6">
                    <svg className="file-input-svg" xmlns="http://www.w3.org/2000/svg" width={20} height={17} viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg>
                    <input onChange={imageUploadOnchangeHandler} type="file" id="file-6" className="binput-file" />
                    <span className="file-input-label">Drag or Click to upload your image</span>
                </label>
                <div className="file-input-master-wrapper__gallery">
                    {
                        categoryImagePreview ? <img className="file-input-master-wrapper__gallery--image" src={categoryImagePreview} alt="" /> : null
                    }
                </div>
            </div> */}

            <label className="blabel">Category Name</label>
            <input value={category.name} onChange={onChangeHandler} color="var(--color-grey-green)" className="binput"
                   type="text" name="name" id=""/>

            <label className="blabel">Category Description</label>
            <textarea value={category.description} onChange={onChangeHandler} className="ctext-area" name="description"
                      id="" cols="30" rows="2"></textarea>


            {/* <button style={{ width: "100%", marginTop: "4rem" }} className="bbutton bbutton-primary">Create Category</button> */}
          </form>
        </div>

        <div className="pop-drawer__footer">
          <button onClick={closeEditCategory} style={{width: '100%', flex: '0 0 48%', color: 'red'}}
                  className="bbutton">Cancel
          </button>
          {
            loading ? <button disabled style={{width: "100%", flex: '0 0 48%'}} className="bbutton bbutton-loading"><img
                src="/three-dot.svg" width="12%" alt=""/></button>
              :
              <button onClick={onSubmitHandler} type='submit' style={{width: "100%", flex: '0 0 48%'}}
                      className="bbutton bbutton-primary">Update Category</button>
          }
        </div>
      </div>


    </>
  );
}

export default EditCategory;