import React, { createContext, useEffect, useState } from 'react';
import axios from '../config/axios';

export const AuthContext = createContext();

export default ({ children }) => {

    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {

        axios.get(`/user/v1/authenticated`, { withCredentials: true }).then(response => {

            if (response.status === 200) {
                if (response.data.user.role.includes('UN7vBlHb')) {
                    setIsAuthenticated(true);
                    setUser(response.data.user);
                    setIsLoaded(true);
                } else {
                    window.location.replace('/login');
                }
            }

            setIsLoaded(true);


        }).catch((error) => {
            console.log(error);
            if (error) {
                console.log(error.response?.status);
                if (error.response?.status === 401) {
                    setIsLoaded(true);
                    setUser({ username: "", role: "" });
                }
            }
        });
    }, []);


    return (
        <div>
            {!isLoaded ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}><img
                src="/loader.svg" alt={'loader'} /></div> :
                <AuthContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated }}>

                    {children}

                </AuthContext.Provider>}
        </div>
    )
}