import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, DatePicker, Drawer, message, Row, Select, Table, Tag } from "antd";
import moment from "../../config/moment";
import axios from '../../config/axios';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

const DateWiseSoldItems = ({setShowStockReport}) => {
    const [products, setProducts] = useState([]);

    const [total, setTotal] = useState(0);
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [refresh, setRefresh] = useState(null);
    const [paidOnly, setPaidOnly] = useState(false);
    const [status, setStatus] = useState(['processing', 'delivered']);

    useEffect(() => {
        // axios.get(`/admin/inventory/products/date-wise-sale/v1?start=${startDate}&end=${endDate}`, {withCredentials: true}).then(response => {
        //     setProducts(response.data);
        // }).catch(err => {
        //     message.error(err.response.data?.msg ? err.response.data.msg : 'A server side error occurred');
        // })
        axios.get(`/admin/inventory/products/sold-disposables`, {
            withCredentials: true, params: {
                start_date: startDate,
                end_date: endDate,
                payment_status: paidOnly ? 'paid' : null,
                status: status.length > 0 ? status.join(','):null
            }
        }).then(response => {
            setProducts(response.data.result);
            setTotal(response.data.total);
        }).catch(err => {
            message.error(err.response.data?.msg ? err.response.data.msg : 'A server side error occurred');
        })
    }, [startDate, endDate, refresh, paidOnly, status]);

    const columns = [
        {
            title: 'Date/Date Range',
            dataIndex: 'created_at',
            key: 'date',
            render: value => startDate === endDate ? <Tag color={'success'}>{startDate}</Tag> :
                <div><Tag color={'success'}>{startDate}</Tag> to <Tag color={'red'}>{endDate}</Tag></div>
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, item) => <div>
                <img src={item.image} style={{width: '50px'}} alt=""/>
                <span>{name.includes('-') ? name +` - ${item._id}` : name + ' - ' + item.color + item._id}</span>
            </div>
        },
        // {
        //     title: 'SKU',
        //     dataIndex: 'sku',
        //     key: 'sku',
        // },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.quantity - b.quantity
        },
    ]
    return (
        <Drawer
            open={true}
            width={'95%'}
            title={'Date Wise Sold Items'}
            onClose={() => setShowStockReport(false)}
        >
            <Row gutter={[10, 10]}>
                <Col lg={6} xs={24}>
                    <div className="d-flex flex-column justify-content-center align-items-start">
                        <span className={'mb-2 text-primary'}>See By Specific Date</span>
                        <div className={'d-flex align-item-center gap-2'}>
                            <Button onClick={() => {
                                setStartDate(moment(startDate, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD'));
                                setEndDate(moment(endDate, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD'));
                            }} size={'large'}>
                                <HiChevronLeft size={20}/>
                            </Button>
                            <DatePicker
                                placeholder={["From Date", "To Date"]}
                                onChange={(_, dateString) => {
                                    setStartDate(dateString);
                                    setEndDate(dateString);
                                }}
                                value={moment(startDate, 'YYYY-MM-DD')}
                                format={"YYYY-MM-DD"}
                                size={'large'}
                            />
                            <Button onClick={() => {
                                setStartDate(moment(startDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD'));
                                setEndDate(moment(endDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD'));
                            }} size={'large'}>
                                <HiChevronRight size={20}/>
                            </Button>
                        </div>
                    </div>
                </Col>
                <Col lg={6} xs={24}>
                    <div className="d-flex flex-column justify-content-center align-items-start">
                        <span className={'mb-2 text-primary'}>Or See By Date Range</span>
                        <DatePicker.RangePicker
                            placeholder={["From Date", "To Date"]}
                            onChange={(_, dateString) => {
                                setStartDate(dateString[0]);
                                setEndDate(dateString[1]);
                            }}
                            value={[moment(startDate, 'YYYY-MM-DD'), moment(endDate, 'YYYY-MM-DD')]}
                            format={"YYYY-MM-DD"}
                            size={'large'}
                        />
                    </div>
                </Col>
                <Col lg={6} xs={24}>
                    <div className="d-flex flex-column justify-content-center align-items-start">
                        <span className={'mb-2 text-primary'}>Payment Status</span>
                        <Checkbox
                            checked={paidOnly}
                            onChange={e => setPaidOnly(e.target.checked)}
                        >
                            Show Paid Units Only
                        </Checkbox>
                    </div>
                </Col>
                <Col lg={6} xs={24}>
                    <div className="d-flex flex-column justify-content-center align-items-start">
                        <span className={'mb-2 text-primary'}>Order Status</span>
                        <Select
                            style={{width: '100%'}}
                            mode={'multiple'}
                            value={status}
                            onChange={e=>setStatus(e)}
                        >
                            <Select.Option value={'pending'}>Pending</Select.Option>
                            <Select.Option value={'processing'}>Processing</Select.Option>
                            <Select.Option value={'delivered'}>Delivered</Select.Option>
                            <Select.Option value={'cancelled'}>Cancelled</Select.Option>
                        </Select>
                    </div>
                </Col>
                <Col span={24}>
                    {/*<br/>*/}
                    <b>Total: {total} Units</b>
                    <span
                        className={'mt-1 d-block d-flex gap-1'}>Showing data {startDate === endDate ? 'for' : 'from'}: {startDate === endDate ?
                        <Tag color={'success'}>{startDate}</Tag> :
                        <div><Tag color={'success'}>{startDate}</Tag> to <Tag color={'red'}>{endDate}</Tag>
                        </div>}</span>
                    {/*<br/>*/}
                    <Table dataSource={products} columns={columns} pagination={false}/>
                </Col>
            </Row>
        </Drawer>
    );
};

export default DateWiseSoldItems;