import React, { useEffect, useState } from 'react';
import { Badge, Tag } from 'antd';
import ProductDetails from '../Products/ProductDetails';
const OrderCard = ({ products }) => {

    const [singleProductID, setSingleProductID] = useState(null);
    const [total, setTotal] = useState(0);


    useEffect(() => {
        let total = 0;
        products.map(product=>{
            total = total + product.quantity
        })

        setTotal(total);
    }, []);

    return (
        <>
            <span>{total}</span>
            <div style={{ backgroundColor: 'var(--color-white)', width: '100%' }} className="dashboard-table-wrapper">
                <table className="table">
                    <tbody>
                        <tr style={{ border: "1px solid var(--color-grey-green)" }}>
                            <th scope="row">Product Image</th>
                            <th scope="row">Item</th>
                            <th scope="row">Flavor</th>
                            <th scope="row">QTY x Price</th>
                            {/*<th>PxP</th>*/}
                        </tr>

                        {/* 🔥 Looping Here 🔥 */}
                        {

                            products.map(product => {
                                return <tr key={product.sku} className="align-middle">
                                    <td style={{ maxWidth: '50px' }}><img className="productImageTable" src={product.featuredImage} width="35%" alt="" /></td>
                                    <td>{product.name} - {product.sku}</td>
                                    <td>{product.color}</td>
                                    {/*<td> <a onClick={() => setSingleProductID(product.id)}>{product.sku}</a> </td>*/}
                                    <td>{product.quantity} x {product.price}৳</td>
                                    {/*<td>{product.ot_pxp}</td>*/}
                                </tr>
                            })

                        }
                    </tbody>
                </table>

            </div>


            {/* Single Product Details Drawer */}


            <ProductDetails
                singleProductID={singleProductID}
                setSingleProductID={setSingleProductID}
            />


        </>
    );
}

export default OrderCard;