import React, { useEffect, useState } from 'react';
import Layout from "../../Components/Layout";
import axios from "../../config/axios";
import { Col, DatePicker, Row } from "antd";
import moment from "../../config/moment";
import styles from "../Landing/Landing.module.scss";
import { formattedAmount } from "../../utils";

const VapexXShamim = () => {

    const [allOrderData, setAllOrderData] = useState(null);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

    useEffect(() => {
        axios.get(`/admin/stats/orders/v1?start=${startDate}&end=${endDate}`, {withCredentials: true}).then((response) => {
            setAllOrderData(response.data);
            console.log(response.data);
        });
    }, [startDate, endDate]);

    const getGrossProfit = () =>{
        return parseInt(allOrderData.total_collected_amount_for_disposable - allOrderData.total_delivery_charge - allOrderData.total_bKash_charge - allOrderData.total_cod_charge - allOrderData.total_pxp);
    }

    const getCodeNineGrossProfit = () =>{
        return Math.ceil(getGrossProfit() * 6.711111111 /100 );
    }

    return (<Layout>
        <Row gutter={[10, 10]}>
            <Col xs={24} lg={12}>
                {/*<h2 className="breadcrumb-custom mb-0 mt-2">*/}
                {/*    <span>Dashboard</span> {">"} <span>Landing</span>*/}
                {/*</h2>*/}
                <div className={"d-flex flex-column"}>
                    <h2 className={"m-0 " + styles.title}>Financial Statements</h2>
                    <span>Vapex X CodeNine</span>

                    {/*<Button style={{marginLeft: '1rem'}} type={'dashed'}*/}
                    {/*        onClick={() => setRefresh(Math.random())}>*/}
                    {/*    /!*<FiRefreshCcw/>*!/*/}
                    {/*</Button>*/}
                </div>
            </Col>
            <Col xs={24} lg={12}>
                <div className="d-flex justify-content-end align-items-center">
                    <DatePicker.RangePicker
                        size={"large"}
                        style={{width: window.innerWidth < 576 ? "100%" : null}}
                        placeholder={["From Date", "To Date"]}
                        onChange={(_, dateString) => {
                            setStartDate(dateString[0]);
                            setEndDate(dateString[1]);
                        }}
                        defaultValue={[moment(), moment()]}
                        format={"YYYY-MM-DD"}
                    />
                </div>
            </Col>
        </Row>
        <br/>
        <div className={'card'} style={{padding: '2rem'}}>
            <div style={{maxWidth: '400px'}}>
                <div className="d-flex justify-content-between">
                        <span className={"text-secondary"}>
                            <strong>Total Sales</strong>
                        </span>
                    <span className={"text-secondary"}>
                        {allOrderData ? `${formattedAmount(allOrderData.total_disposable_sales)} BDT` : "..."}
                    </span>
                </div>
                <div className="d-flex justify-content-between">
                        <span className={"text-secondary"}>
                            <strong>Amount Collected</strong>
                        </span>
                    <span className={"text-secondary"}>
                        {allOrderData ? `${formattedAmount(allOrderData.total_collected_amount_for_disposable)} BDT` : "..."}
                    </span>
                </div>
                <hr/>
                <div className="d-flex justify-content-between">
                        <span className={"text-secondary"}>
                            <strong>Gross Profit</strong>
                        </span>
                    <span className={"text-secondary"}>
                        {allOrderData ? `${formattedAmount(getGrossProfit())} BDT` : "..."}
                    </span>
                </div>
                <div className="d-flex justify-content-between">
                        <span className={"text-secondary"}>
                            <strong>CodeNine's Gross Profit (6.711111111%)</strong>
                        </span>
                    <span className={"text-secondary"}>
                        {allOrderData ? `${formattedAmount(getCodeNineGrossProfit())} BDT` : "..."}
                    </span>
                </div>
                <div className="d-flex justify-content-between">
                        <span className={"text-danger"}>
                            <strong>- Vapex's Cut (35% of CodeNine's GP)</strong>
                        </span>
                    <span className={"text-danger"}>
                        {allOrderData ? `${formattedAmount(Math.ceil(getCodeNineGrossProfit() * 35 /100))} BDT` : "..."}
                    </span>
                </div>
                <hr/>
                <div className="d-flex justify-content-between">
                        <span className={"text-success"}>
                            <strong>CodeNine's Final Payable</strong>
                        </span>
                    <span className={"text-success"}>
                        {allOrderData ? `${formattedAmount(Math.ceil(getCodeNineGrossProfit() - getCodeNineGrossProfit() * 35 /100))} BDT` : "..."}
                    </span>
                </div>
            </div>
        </div>
    </Layout>);
};

export default VapexXShamim;