import React, { useEffect, useState } from 'react';
import {
    Select
} from 'antd';

const ReturnStatus = (props) => {

    const { Option, OptGroup } = Select;
    const [status, setStatus] = useState(null);

    useEffect(() => {

        setStatus(props.status);

    }, []);


    //defining handleChange function
    const handleChange = value => {

        setStatus(value);
        let formData = new FormData;
        formData.append('bla', 1);
        // axios.put(`/admin/orders/v1/update/${order._id}/${value}`, formData, { withCredentials: true }).then(response => {
        //     if (response.status === 200) {
        //         refresh();
        //         message.success({
        //             content: `Order status updated and user was notified!`,
        //             style: {
        //                 marginTop: '85vh',
        //                 marginRight: '70vw'
        //             },
        //         })
        //     }
        // })
    }
    return (
        <>

            <Select value={status} style={{ width: 100, }} onChange={handleChange}>
                <Option value={'Initiated'}>Initiated</Option>
                <Option value={'Product Received'}>Product Received</Option>
            </Select>

        </>
    );
}

export default ReturnStatus;