import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, message } from "antd";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FiMinusCircle } from "react-icons/fi";
import { Link, useHistory } from "react-router-dom";
import Layout from "../../Components/Layout";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../config/axios";
import moment from "moment";

const InventoryOut = () => {
    const [form] = Form.useForm();
    const router = useHistory();
    const [inventoryItems, setInventoryItems] = useState([]);
    const [itemUnits, setItemUnits] = useState([]);
    const [inventoryOutLoading, setInventoryOutLoading] = useState(false);

    // get inventory items
    useEffect(() => {
        (async () => {
            const res = await axios.get("/admin/inventory/items/v1", { withCredentials: true });
            setInventoryItems(res.data.inventory_items);
        })();
    }, []);

    const handleInventoryOutSubmit = async (values) => {
        if (values.items.length === 0) {
            message.error("Please add at least one item!");
            return;
        }
        values.date = values.date.format("YYYY-MM-DD");
        // format the items array
        values.items = values.items.map((item) => ({
            item: item.item,
            qty: +item.qty.unit,
        }));
        try {
            setInventoryOutLoading(true);
            await axios.post("/admin/inventory/out/v1", values, { withCredentials: true });
            message.success("Inventory out record created successfully!");
            form.resetFields();
            router.push("/inventory?tab=out");
        } catch (error) {
            message.error("Failed to create inventory out record!");
        } finally {
            setInventoryOutLoading(false);
        }
    };

    return (
        <Layout>
            <Card
                bordered={false}
                bodyStyle={{
                    padding: 15,
                }}
            >
                <div className="d-flex justify-content-between align-items-center flex-wrap gap-2 ">
                    <h3 className="mb-0">Add Inventory Out Record</h3>
                    <div className="d-flex gap-2 flex-wrap">
                        <Link to={"/inventory-in"}>
                            <Button type="primary" className="d-flex align-items-center justify-content-center gap-2">
                                <AiOutlinePlusCircle size={20} />
                                <span>Add Inventory In Record</span>
                            </Button>
                        </Link>
                        <Link to={"/inventory"}>
                            <Button type="primary" className="d-flex align-items-center justify-content-center gap-2">
                                <span>Go to Inventory</span>
                            </Button>
                        </Link>
                    </div>
                </div>
            </Card>
            <br />
            <Card
                bordered={false}
                bodyStyle={{
                    padding: 20,
                }}
            >
                <Form
                    layout="vertical"
                    onFinish={handleInventoryOutSubmit}
                    requiredMark={false}
                    autoComplete="off"
                    scrollToFirstError
                    form={form}
                    initialValues={{
                        date: moment(),
                        items: [],
                    }}
                >
                    <Row gutter={[20]}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                name="date"
                                label="Date *"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select date!",
                                        type: "object",
                                    },
                                ]}
                            >
                                <DatePicker format={"YYYY-MM-DD"} className="w-100" size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }, index) => (
                                            <Row key={key} gutter={[15]}>
                                                <Col span={11}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "item"]}
                                                        label="Item Name *"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Missing item name",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            showSearch
                                                            placeholder="Select an Item"
                                                            optionFilterProp="children"
                                                            size="large"
                                                            filterOption={(input, option) =>
                                                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                                                            }
                                                            options={inventoryItems.map((item) => ({
                                                                value: item._id,
                                                                label: item.name,
                                                                unit: item.unit,
                                                            }))}
                                                            onChange={(value, option) => {
                                                                // Get the index of the current field
                                                                const fieldIndex = fields.findIndex((field) => field.key === key);

                                                                // Update the unit value for the current field
                                                                const updatedItemUnits = [...itemUnits];
                                                                updatedItemUnits[fieldIndex] = option?.unit ?? "";

                                                                // Update the item units
                                                                setItemUnits(updatedItemUnits);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={10} lg={11}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "qty", "unit"]}
                                                        label={
                                                            itemUnits[index]
                                                                ? `Item Quantity (in ${itemUnits[index]}) *`
                                                                : "Item Quantity *"
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Missing item quantity",
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Item Quantity" size="large" />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={1} xs={3}>
                                                    <div className="d-flex align-items-center justify-content-center h-100" role="button">
                                                        <FiMinusCircle onClick={() => remove(name)} size={22} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                block
                                                size="large"
                                                className="d-flex align-items-center justify-content-center gap-2"
                                            >
                                                <PlusOutlined />
                                                <span>Add Item</span>
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    block
                                    size="large"
                                    loading={inventoryOutLoading}
                                    disabled={inventoryOutLoading}
                                >
                                    Create Inventory Out Record
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Layout>
    );
};

export default InventoryOut;
