import React, { useState } from 'react';

//importing all components
import EditCoupon from '../Components/Coupon/EditCoupon';
import { Progress } from 'antd';
import Layout from '../Components/Layout';

const Coupons = (props) => {

    const [coupon, setCoupon] = useState({ code: '', description: '', discount: '', quantity: '' });
    const [editCoupon, setEditCoupon] = useState({ boolean: false, couponID: '' });

    //defining onSubmitHandler function
    const onSubmitHandler = () => {

    }


    //defining onSubmitHandler function
    const onChangeHandler = (e) => {
        setCoupon({ ...coupon, [e.target.name]: e.target.value });
    }

    //defining editCouponTrigger function
    const editCouponTrigger = (couponID) => {
        setEditCoupon({
            boolean: true,
            couponID: couponID
        });
    }

    //defining closeEditCoupon function
    const closeEditCoupon = () => {
        setEditCoupon({
            boolean: false,
            couponID: ''
        });
    }

    return (
        <>

            <Layout>
                <h2 className="breadcrumb-custom"> <span>Dashboard</span> {'>'} <span>Products</span> {'>'} <span> Coupon Managment</span></h2>

                <div className="dashboard-full">
                    <div className="dashboard-half">

                        <div className="dashboard-page-header">
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__left">
                                <h3 className="dashboard-page-header__left--title">Add New Coupon Code</h3>
                            </div>
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__right">

                                {/* <select className="bselect" name="" id="">
    <option value="Follow Up">Follow Up</option>
    <option value="In Transit">In Transit</option>
    <option value="Delivered">Delivered</option>
</select> */}

                                {/* <form style={{ flex: "0 0 100%" }} >
<input className="binput" placeholder="Search By Name" type="text" name="" id="" />
</form> */}

                                {/* <button style={{ flex: "0 0 100%" }} className="bbutton bbutton-primary"> <svg className="admin-svg white" viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg> Add New SubCategory</button> */}
                            </div>
                        </div>

                        {/* Create New Category Form */}
                        <div className="dashboard-card">

                            <form onSubmit={onSubmitHandler}>

                                <label className="blabel">Coupon Code</label>
                                <input value={coupon.code} onChange={onChangeHandler} className="binput" type="text" name="code" required />

                                <label className="blabel">Category Description</label>
                                <textarea value={coupon.description} onChange={onChangeHandler} placeholder={'Description is private and not mandatory'} className="ctext-area" name="description" required cols="30" rows="2"></textarea>

                                <label className="blabel">Coupon Discount (Percentage)</label>
                                <input value={coupon.discount} onChange={onChangeHandler} className="binput" type="number" name="discount" required />

                                <label className="blabel">Coupon Quantity</label>
                                <input value={coupon.quantity} onChange={onChangeHandler} className="binput" type="number" name="quantity" required />


                                <button type='submit' style={{ width: "100%", marginTop: "4rem" }} className="bbutton bbutton-primary">Create Coupon</button>

                            </form>

                        </div>

                    </div>
                    <div className="dashboard-half">

                        {/* List of All Categories */}
                        <div className="dashboard-page-header">
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__left">
                                <h3 className="dashboard-page-header__left--title">All Coupons</h3>
                            </div>
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__right">

                                {/* <select className="bselect" name="" id="">
                        <option value="Follow Up">Follow Up</option>
                        <option value="In Transit">In Transit</option>
                        <option value="Delivered">Delivered</option>
                    </select> */}

                                <form style={{ flex: "0 0 100%" }} >
                                    <input className="binput" placeholder="Search By Code" type="text" name="" id="" />
                                </form>

                                {/* <button style={{flex: "0 0 100%"}} className="bbutton bbutton-primary"> <svg className="admin-svg white" viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg> Add New Product</button> */}
                            </div>
                        </div>

                        {/* All Categories Here */}
                        <div className="dashboard-table-wrapper">
                            <table class="table">
                                <tbody>
                                    <tr style={{ border: "1px solid var(--color-grey-green)" }}>
                                        <th scope="row">Code</th>
                                        <th scope="row"> Description</th>
                                        <th scope="row">Discount</th>
                                        <th style={{ width: '200px' }} scope="row">Usage</th>
                                        <th scope="row">Action</th>
                                    </tr>

                                    {/* Doing The Loop Here */}
                                    {/* {
                    categories.map(category=>{
                        return <CategoryItem key={category._id} category={category} />
                    })
                } */}

                                    <tr className="align-middle">
                                        <td>BLACKFRIDAY</td>
                                        <td>Flat blackfriday discount</td>
                                        <td>10%</td>
                                        <td> <Progress percent={70} /> <br /> 10 of 30 Remaining </td>
                                        <td onClick={() => editCouponTrigger(1234)}><svg style={{ color: 'var(--color-primary-db)', cursor: 'pointer' }} viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg></td>
                                    </tr>

                                    <tr className="align-middle">
                                        <td>BLACKFRIDAY</td>
                                        <td>Flat blackfriday discount</td>
                                        <td>10%</td>
                                        <td> <Progress percent={30} /> <br /> 10 of 30 Remaining </td>
                                        <td onClick={() => editCouponTrigger(1234)}><svg style={{ color: 'var(--color-primary-db)', cursor: 'pointer' }} viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg></td>
                                    </tr>

                                    <tr className="align-middle">
                                        <td>BLACKFRIDAY</td>
                                        <td>Flat blackfriday discount</td>
                                        <td>10%</td>
                                        <td> <Progress percent={90} /> <br /> 10 of 30 Remaining </td>
                                        <td onClick={() => editCouponTrigger(1234)}><svg style={{ color: 'var(--color-primary-db)', cursor: 'pointer' }} viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg></td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>

                        {/* Connecting Edit Coupon Component */}
                        {
                            editCoupon.boolean ? <EditCoupon drawerClass={'pop-drawer'}
                                drawerBodyClass={'pop-drawer__body'}
                                closeEditCoupon={closeEditCoupon}
                                width={35}
                            /> : <EditCoupon />
                        }

                    </div>
                </div>
            </Layout>

        </>
    );
}

export default Coupons;