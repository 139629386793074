import React, { useEffect, useState } from 'react';
import { Badge, Button, Input, InputNumber, message, Modal } from 'antd';
import {
    UserAddOutlined
} from '@ant-design/icons';
import moment from 'moment';
import axios from '../../config/axios';

const CustomerItem = ({user, setSingleUser, refreshData}) => {

    const [promoModal, setPromoModal] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [promoDiscount, setPromoDiscount] = useState('');
    const [promoCount, setPromoCount] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log(user.orders.length, `- Length`);
    }, []);

    const rewardPromoHandler = () => {
        if (!promoCode) {
            message.error('Promo Code is required!');
            return;
        }

        if (!promoDiscount) {
            message.error('Promo Discount is required!');
            return;
        }

        if (!promoCount) {
            message.error('Promo Count is required!');
            return;
        }

        if (parseInt(promoDiscount) <= 0) {
            message.error('Promo Discount must be greater than zero.')
            return;
        }

        if (parseInt(promoCount) <= 0) {
            message.error('Promo Count must be greater than zero.')
            return;
        }

        let payload = {
            userID: user._id,
            promo_code: promoCode,
            promo_discount: promoDiscount,
            promo_count: promoCount
        }

        setLoading(true);

        axios.post('/admin/crm/promo/give/v1', payload, {withCredentials: true}).then(response => {
            message.success('Promo Rewarded Successfully! SMS Sent');
            setPromoModal(false);
            refreshData();
        }).catch(error => {
            message.error(error.response.data.msg);
        }).finally(() => {
            setLoading(false);
        })
    }

    const revokePromo = () => {
        axios.patch(`/admin/crm/promo/revoke/v1`, {userID: user._id}, {withCredentials: true}).then(response => {
            message.success('Promo Revoked Successfully!');
            refreshData();
        })
    }

    return (
        <>

            <tr className="align-middle">
                <td style={{fontSize: '10px'}}>{moment(user.dateOfRegistration).format('MMMM D, YYYY -LT ')}</td>
                <td><span style={{color: '#7FD238', fontWeight: '500', textTransform: 'uppercase'}}>{user.name}</span>
                </td>
                <td>{user.phoneNumber}</td>
                {/*<td>{user.email ? user.email : "N/A"}</td>*/}
                {/*<td onClick={() => setSingleUser(user)}>*/}
                {/*    <div className="payment-box"></div>*/}
                {/*    <Badge*/}
                {/*        style={{backgroundColor: user.orders.length === 0 ? 'var(--color-red)' : user.orders.length < 3 ? 'var(--color-ok)' : 'var(--color-gold)'}}*/}
                {/*        showZero count={user.orders.length}/>*/}
                {/*</td>*/}
                {/*<td> {`District: ${user.district}, Sub: ${user.subDistrict}, ADRL1: ${user.addressLineOne ? user.addressLineOne : 'N/A'}, ADRL2: ${user.addressLineTwo ? user.addressLineTwo : 'N/A'}`}</td>*/}
                <td>
                    {
                        user.promo_code ?
                            <div className={'d-flex flex-column'}>
                                <span>Promo: {user.promo_code}</span>
                                <span>Discount: {user.promo_discount} %</span>
                                <span>Remaining Count:  <Badge count={user.promo_count}/></span>
                                <Button onClick={revokePromo} type={'danger'} danger={true}
                                        style={{marginTop: '.5rem'}}>Revoke</Button>
                            </div>
                            :
                            <div>
                                <Button onClick={() => setPromoModal(true)} type={'primary'}>Reward Promo</Button>
                            </div>
                    }
                </td>
                <td>
                    <button onClick={() => setSingleUser(user)} className="cbutton"><UserAddOutlined/></button>
                </td>
                {/* <td> <div style={{ color: '#00BDD5' }} className="status"> <span style={{ backgroundColor: '#00BDD5' }} className="status-span"></span> Follow Up </div> </td>
                <td> <button onClick={openDrawer} className="cbutton"> <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="box-open" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" ><path fill="currentColor" d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z" /></svg> </button> </td>
             */}
            </tr>


            {
                <Modal
                    open={promoModal}
                    centered={true}
                    footer={null}
                    onCancel={() => setPromoModal(false)}
                >
                    <div className={'pt-5'}>
                        <label htmlFor="">Promo Code</label>
                        <Input onChange={e => setPromoCode(e.target.value)} placeholder={'Enter Promo Code Here'}
                               type={'text'}/>

                        <br/>
                        <br/>
                        <label htmlFor="">Discount Percentage</label>
                        <InputNumber onChange={e => setPromoDiscount(e)} style={{width: '100%'}}
                                     placeholder={'Enter Promo Discount %'} type={'text'}/>

                        <br/>
                        <br/>
                        <label htmlFor="">Promo Count </label>
                        <InputNumber onChange={e => setPromoCount(e)} style={{width: '100%'}}
                                     placeholder={'Enter Promo Max Usage Qty'} type={'text'}/>

                        <br/><br/>
                        <Button loading={loading} disabled={loading} onClick={rewardPromoHandler} type={'primary'}>Reward
                            Promo</Button>
                    </div>
                </Modal>
            }

        </>
    );
}

export default CustomerItem;