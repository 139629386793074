import React, { useState } from 'react';

const EditCoupon = (props) => {

    const [coupon, setCoupon] = useState({ code: 'BLACKFRIDAY', description: 'Balckfriday flat discount', discount: '10', quantity: '30' });

    //defining onSubmitHandler function
    const onSubmitHandler = () => {

    }


    //defining onSubmitHandler function
    const onChangeHandler = (e) => {
        setCoupon({ ...coupon, [e.target.name]: e.target.value });
    }


    //defining closeEditCoupon function
    const closeEditCoupon = () => {
        props.closeEditCoupon();
    }

    return (
        <>

            <div className={props.drawerClass || 'pop-drawer closed'}> </div>
            <div style={{ width: `${props.width}vw` }} className={props.drawerBodyClass || `pop-drawer__body-closed`}>
                <div className="pop-drawer__body__wrapper">
                    <h2>Edit Coupon</h2>

                    <form onSubmit={onSubmitHandler}>

                        <label className="blabel">Coupon Code</label>
                        <input value={coupon.code} onChange={onChangeHandler} className="binput" type="text" name="code" required />

                        <label className="blabel">Category Description</label>
                        <textarea value={coupon.description} onChange={onChangeHandler} placeholder={'Description is private and not mandatory'} className="ctext-area" name="description" required cols="30" rows="2"></textarea>

                        <label className="blabel">Coupon Discount (Percentage)</label>
                        <input value={coupon.discount} onChange={onChangeHandler} className="binput" type="number" name="discount" required />

                        <label className="blabel">Coupon Quantity</label>
                        <input value={coupon.quantity} onChange={onChangeHandler} className="binput" type="number" name="quantity" required />


                    </form>


                </div>

                <div className="pop-drawer__footer">
                    <button onClick={closeEditCoupon} style={{ width: '100%', flex: '0 0 48%', color: 'red' }} className="bbutton">Cancel</button>
                    <button style={{ width: '100%', flex: '0 0 48%' }} className="bbutton bbutton-primary">Update Coupon</button>
                </div>
            </div>

        </>
    );
}


export default EditCoupon;