import React from 'react';
import { useState, useEffect } from 'react';
import axios from '../../config/axios';

//imoprting all components
import {
    Col,
    Drawer,
    Row,
    Select,
    Tag,
    Badge,
    message,
    Timeline
} from 'antd';
import {
    ShoppingCartOutlined,
    UserOutlined,
    DropboxOutlined,
    CreditCardOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import OrderCard from '../Order/OrderCard';

const ReturnDetails = ({ singleReturnID, setSingleReturnID }) => {

    const [returnX, setReturnX] = useState();
    const [order, setOrder] = useState({});
    const [visibility, setVisibility] = useState(false);
    const [loading, setLoading] = useState(true);
    const { Option, OptGroup } = Select;

    useEffect(() => {

        if (singleReturnID) {
            //opening drawer if orderID prop is passed
            setVisibility(true);
            //setLoading(true);

            //fetching return details
            axios.get(`/admin/returns/v1/${singleReturnID}`, { withCredentials: true }).then(response => {

                setReturnX(response.data.return);

                //fetching order details
                axios.get(`/admin/orders/v1/filterByOrderID/${response.data.return.orderID}`, { withCredentials: true }).then(responseV2 => {

                    setOrder(responseV2.data.order);
                    setLoading(false);

                }).catch(error => {
                    if (error.response.status === 404) {
                        message.error({
                            content: `No order associated with this id was found!`,
                        })
                    }
                })


            }).catch(error => {
                if (error.response.status === 404) {
                    message.error({
                        content: `No order associated with this id was found!`,
                    })
                }
            })

        }



    }, [singleReturnID]);

    //defining onClose function
    const onCloseHandler = () => {

        if (singleReturnID) {
            setSingleReturnID(null);
        }

        setVisibility(false);
        setLoading(true);

    }


    //defining printLabel function
    const printInvoice = (orderID) => {
        // axios(`/admin/microservices/v1/generate-order-invoice/${orderID}`, {
        //     method: 'GET',
        //     responseType: 'blob',
        //     withCredentials: true
        // }).then(response => {
        //     //Create a Blob from the PDF Stream
        //     const file = new Blob(
        //         [response.data],
        //         { type: 'application/pdf' });
        //     //Build a URL from the file
        //     var fileURL = URL.createObjectURL(file);
        //     //Open the URL on new Window

        //     document.querySelector('#pdf-frame').src = '';
        //     document.querySelector('#pdf-frame').src = fileURL;
        //     fileURL = URL.revokeObjectURL(file);

        //     window.setTimeout(function () {
        //         document.querySelector('#pdf-frame').contentWindow.print();
        //     }, 100)

        // }).catch(error => {
        //     console.log(error);
        // })

    }

    return (
        <>

            <iframe id="pdf-frame"
                style={{ display: 'none' }}
            ></iframe>

            <Drawer
                width={840}
                placement="right"
                closable={true}
                onClose={onCloseHandler}
                visible={visibility}
            >
                {
                    loading ?
                        <div style={{ height: '60vh' }} className="loader"><img src="/loader.svg" alt="" /></div>
                        :
                        <>
                            <h3 className="drawer-title">Return Details</h3>

                            <div className="ant-drawer-body-wrapper">

                                <p className="title-primary">
                                    <ShoppingCartOutlined />
                                    Return/Refund Basic Info
                                    <span
                                        style={{ textAlign: 'right', marginLeft: 'auto', color: 'var(--color-red)', cursor: 'pointer' }}
                                        className="am"
                                        onClick={() => printInvoice(order.orderID)}
                                    >
                                        <svg viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round" ><polyline points="6 9 6 2 18 2 18 9" /><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><rect x={6} y={14} width={12} height={8} /></svg>
                                        Print Order Invoice</span>
                                </p>
                                <Row>

                                    <Col md={24} lg={12}>


                                        <div className="info">
                                            <span className="info__1">Order ID: </span>
                                            <span className="info__2">{returnX.orderID}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Order Timestamp: </span>
                                            <span className="info__2">{moment(returnX.orderTimestamp).format('MMM D, YYYY - LT')}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Return Timestamp: </span>
                                            <span className="info__2">{moment(returnX.timestamp).format('MMM D, YYYY - LT')}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Return Reason: </span>
                                            <span className="info__2">{returnX.reason}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Return Method: </span>
                                            <span className="info__2"> <Tag color="error">{returnX.method}</Tag> </span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Return Status: </span>
                                            <Select value={returnX.status} style={{ width: 300, border: order.status === 7 ? '1px solid red' : null, marginTop: '1rem' }}>
                                                <Option value={1}>Ordered</Option>
                                                <Option value={2}>Processing</Option>
                                                <Option value={3}>In Transit</Option>
                                                <Option value={4}>RBC</Option>
                                                <Option value={5}>Picked</Option>
                                                <Option value={6}>Delivered</Option>
                                                <Option value={7}>Cancelled</Option>
                                            </Select>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Total Return Amount: </span>
                                            <span className="info__2">
                                                <Badge overflowCount={100000000000} style={{ backgroundColor: 'var(--color-awesome)' }} count={returnX.totalAmount + ' BDT'} />
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={24} lg={12}>

                                    </Col>
                                </Row>

                                <p style={{ marginTop: '2rem' }} className="title-primary">
                                    <UserOutlined />
                                    Customer Info
                                </p>

                                <Row>
                                    <Col md={24} lg={12}>
                                        <div className="info">
                                            <span className="info__1">Full Name: </span>
                                            <span className="info__2">{order.contact.name}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">District: </span>
                                            <span className="info__2">{order.deliveryAddress.district}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Sub District: </span>
                                            <span className="info__2">{order.deliveryAddress.subDistrict}</span>
                                        </div>


                                    </Col>
                                    <Col md={24} lg={12}>
                                        <div className="info">
                                            <span className="info__1">Area: </span>
                                            <span className="info__2">{order.deliveryAddress.area}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Road Number: </span>
                                            <span className="info__2">{order.deliveryAddress.roadNumber}</span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">House Number: </span>
                                            <span className="info__2">{order.deliveryAddress.houseNumber}</span>
                                        </div>
                                    </Col>
                                </Row>

                                <p style={{ marginTop: '2rem' }} className="title-primary"> <DropboxOutlined />Return Products</p>

                                <Row>
                                    <Col md={24} lg={12}>


                                        <OrderCard
                                            products={returnX.products}
                                        />

                                    </Col>
                                    <Col md={24} lg={12}>

                                    </Col>
                                </Row>

                                <p style={{ marginTop: '2rem' }} className="title-primary"> <CreditCardOutlined /> Order Payment Details </p>
                                <Row>
                                    <Col md={24} lg={12}>
                                        <div className="info">
                                            <span className="info__1">Payment Status: </span>
                                            <span className="info__2"> <Tag color={order.payment.status === 'Paid' ? '#108ee9' : 'error'} >{order.payment.status}</Tag> </span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">Payment Method: </span>
                                            <span className="info__2"> <Tag color={order.payment.method ? 'success' : 'error'} >{order.payment.method || 'N/A'}</Tag> </span>
                                        </div>
                                        <div className="info">
                                            <span className="info__1">TransactionID: </span>
                                            <span className="info__2"> <Tag color={order.payment.transactionID ? '#f50' : 'error'} >{order.payment.transactionID || 'N/A'}</Tag> </span>
                                        </div>

                                    </Col>
                                    <Col md={24} lg={12}>
                                    </Col>
                                </Row>

                                <p style={{ marginTop: '2rem' }} className="title-primary"> <ClockCircleOutlined /> Order Events </p>
                                <Row>
                                    <Col md={24} lg={24}>

                                        <Timeline mode={'left'}>
                                            {
                                                order.events.map(event => {
                                                    return <Timeline.Item color="var(--color-ok)" key={event.timestamp} > {moment(event.timestamp).format('MMMM D, YYYY-LT')} - {event.event} </Timeline.Item>
                                                })
                                            }

                                        </Timeline>

                                    </Col>
                                </Row>

                                <p style={{ marginTop: '2rem' }} className="title-primary"> <ClockCircleOutlined /> Return Events </p>
                                <Row>
                                    <Col md={24} lg={24}>

                                        <Timeline mode={'left'}>
                                            {
                                                returnX.events.map(event => {
                                                    return <Timeline.Item color="var(--color-ok)" key={event.timestamp}>{moment(event.timestamp).format('MMMM D, YYYY-LT')} - {event.event} </Timeline.Item>
                                                })
                                            }

                                        </Timeline>

                                    </Col>
                                </Row>
                            </div>
                        </>
                }
            </Drawer>

        </>
    );

}

export default ReturnDetails;