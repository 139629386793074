import React, {useEffect, useState} from 'react';
import {Badge, message, Popconfirm, Switch, Tag} from 'antd';
import {Link} from 'react-router-dom';
import axios from '../../config/axios';
import {CheckOutlined, CloseOutlined, SkinOutlined} from '@ant-design/icons';

const ProductItem = ({product, refresh, setSingleProduct}) => {

  const [productVisibility, setProductVisibility] = useState()

  useEffect(() => {
    setProductVisibility(product.visibility);
  }, []);

  //defining confirm and cancel function
  const confirm = e => {
    axios.delete(`/admin/products/v1/delete/${product._id}`, {withCredentials: true}).then(response => {
      console.log(response.data);
      refresh();
      message.success({
        content: `Product Deleted Successfully!`,

      })
    })
  }

  const cancel = e => {
    console.log("No");
  }

  //defining onChangeVisibilityHandler function
  const onChangeVisibilityHandler = checked => {
    console.log(checked);
    setProductVisibility(checked);

    //updating product visibility
    let formData = new FormData;
    formData.append('visibility', checked);

    axios.put(`/admin/products/v1/quick-update/visibility/${product._id}`, formData, {withCredentials: true}).then(response => {
      message.success({
        content: `Product Visibility Updated Successfully!`,
      })
    })
  }

  return (
    <>


      <tr className="align-middle">
        {/*<td style={{width: '200px'}} className={'hide_tablet'}>*/}
        {/*  {*/}
        {/*    product.variant.map(variantItem => {*/}
        {/*      return <div key={variantItem.sku} className="badge-info">*/}
        {/*        <Tag style={{width: '120px', fontSize: '10px'}}*/}
        {/*             color={variantItem.stock > 0 ? '#108ee9' : 'error'}>{`${variantItem.sku}`}</Tag>*/}
        {/*        <Tag style={{width: '30px'}}*/}
        {/*             color={variantItem.stock > 0 ? '#87d068' : 'error'}>{`${variantItem.stock}`}</Tag>*/}
        {/*      </div>*/}
        {/*    })*/}
        {/*  }*/}
        {/*  {*/}
        {/*    product.variant.length === 0 ?*/}
        {/*      <Tag color="red">No Variant Added</Tag>*/}
        {/*      : null*/}
        {/*  }*/}
        {/*</td>*/}
        <td><img className="productImageTable" src={product.featuredImage} width="80px" alt=""/></td>
        <td style={{maxWidth: '250px'}}> {product.name} </td>
        <td> {product.category.name} </td>
        {/*<td> {product.brand?.name} </td>*/}
        <td>{product.salePrice} ৳</td>
        <td>
          <Switch
            checkedChildren={<CheckOutlined/>}
            unCheckedChildren={<CloseOutlined/>}
            onChange={onChangeVisibilityHandler}
            checked={productVisibility}
          />
        </td>
        <td >
          <Tag color={'processing'}>
            {product.unitSold}
          </Tag>
          {/*<Badge*/}
          {/*  style={{backgroundColor: product.unitSold === 0 ? 'var(--color-red)' : product.unitSold < 3 ? 'var(--color-ok)' : 'var(--color-gold)'}}*/}
          {/*  showZero count={product.unitSold}/>*/}
        </td>
        <td>
          {/*<Link to={`/products/edit-gallery/${product._id}/0`} target={"_blank"}>*/}
          {/*  <button>Edit Gallery</button>*/}
          {/*</Link>*/}
          <Link to={"/products/edit/" + product._id}>
            <svg style={{color: 'var(--color-primary-db)', cursor: 'pointer'}} viewBox="0 0 24 24" width={18}
                 height={18} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round"
                 strokeLinejoin="round">
              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/>
              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>
            </svg>
          </Link>
          <Link to={"/products/product-gallery/" + product._id}>
            <svg style={{color: '#B7EB8F', cursor: 'pointer', marginLeft: '1rem'}} viewBox="0 0 24 24" width={18}
                 height={18} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round"
                 strokeLinejoin="round" className="css-i6dzq1">
              <circle cx={12} cy={12} r={3}/>
              <path
                d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
            </svg>
          </Link>
          <Popconfirm
            title="Sure about deleting this product?"
            onConfirm={confirm}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <svg style={{color: 'red', cursor: 'pointer', marginLeft: '1rem'}} viewBox="0 0 24 24" width={18}
                 height={18} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round"
                 strokeLinejoin="round">
              <polyline points="3 6 5 6 21 6"/>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/>
              <line x1={10} y1={11} x2={10} y2={17}/>
              <line x1={14} y1={11} x2={14} y2={17}/>
            </svg>
          </Popconfirm>
        </td>
        <td>
          <td>
            <button onClick={() => setSingleProduct(product)} className="cbutton"><SkinOutlined/></button>
          </td>
        </td>
      </tr>

    </>
  );
}


export default ProductItem;