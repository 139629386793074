import React, { useEffect, useState } from 'react';
import axios from '../../config/axios';
import styles from './Invoice.module.scss';
import { Col, Row } from "antd";
import moment from '../../config/moment';

const KitchenToken = ({order}) => {

    const [loading, setLoading] = useState(true);

    const getOrderSubTotal = () => {
        let subTotal = 0;
        order.products.forEach(item => {
            subTotal = subTotal + (item.price * item.quantity);
        })

        return subTotal;
    }

    return (
        <div className={styles.invoice}>
            {
                order &&
                <>
                    <div className={styles.header}>
                        {/*<img src="/logo.svg" alt=""/>*/}
                        {/*<span>Vapex: Premium Disposable Vapes and Cafe</span>*/}
                    </div>
                    <div className={'d-flex justify-content-center align-items-center flex-column fs-4'}>
                        <span>Order: {order.orderID}</span>
                        <span>Token: {order.token}</span>
                        <span>Table: {order.table_no ? order.table_no : 'N/A'}</span>
                    </div>

                    <table className={styles.table}>
                        <tbody>
                        <tr>
                            <th>SL</th>
                            <th>Item</th>
                            <th>Rate</th>
                            <th>Qty</th>
                            <th style={{textAlign: 'right'}}>Amount</th>
                        </tr>
                        {
                            order.products.map((item, index) => {
                                return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.price}৳</td>
                                    <td>{item.quantity}</td>
                                    <td style={{textAlign: 'right'}}>{item.quantity * item.price}৳</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>

                    <div className={styles.summary}>
                        <div>
                            <span>Sub Total:</span>
                            <span>{getOrderSubTotal()}৳</span>
                        </div>
                        <div>
                            <span>+ Delivery Charge:</span>
                            <span>{order.deliveryCharge > 0 ? `${order.deliveryCharge}৳` : 'N/A'}</span>
                        </div>
                        <div>
                            <span>- Discount:</span>
                            <span>{order.discount > 0 ? `${order.discount}৳` : 'N/A'}</span>
                        </div>
                        <div className={styles.hr}/>
                        <div>
                            <span>Total:</span>
                            <span>{order.totalBill}৳</span>
                        </div>
                    </div>

                    <div className={styles.footer}>
                        <b>Powered by Codenine POS</b>
                        <em>pos.codenine.tech</em>
                    </div>

                </>
            }
        </div>
    )
        ;
};

export default KitchenToken;