import { useState, useEffect } from 'react';
import React from 'react';
import axios from '../config/axios';


//importing all components
import EditCategory from '../Components/Category/EditCategory';
import { message } from 'antd';
import Layout from '../Components/Layout';
import { Link } from 'react-router-dom';
import EditBrand from '../Components/Category/EditBrand';


const Brands = (props) => {

    const [brand, setBrand] = useState({ name: '', description: '' });
    const [brandImagePreview, setBrandImagePreview] = useState(null);
    const [brands, setBrands] = useState([]);
    const [editBrand, setEditBrand] = useState({ boolean: false, brand: null });
    const [refresher, setRefresher] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        axios.get(`/admin/category/brands/v1`, { withCredentials: true }).then(response => {
            setBrands(response.data.brands);
        })
    }, [refresher]);

    //defining refresh function
    const refresh = () => {
        setRefresher(Math.random());
    }


    //defining onChangeHandler function
    const onChangeHandler = (e) => {
        e.preventDefault();

        setBrand({ ...brand, [e.target.name]: e.target.value });
    }

    const editCategoryTrigger = (theBrand) => {
        //console.log(theCategory);
        setEditBrand({
            boolean: true,
            brand: theBrand
        });
    }

    const closeEditCategory = () => {
        setEditBrand({
            boolean: false,
            categoryID: ''
        });
    }

    //defining onSubmitHandler function
    const onSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        let payload = brand;
        payload.image = brandImagePreview;

        axios.post(`/admin/category/brands/v1/create`, payload, { withCredentials: true }).then(response => {
            if (response.status === 200) {
                refresh();
                setLoading(false);
                message.success({
                    content: `Category Was Created Successfully!`,
                    style: {
                        marginTop: '85vh',
                        marginRight: '70vw'
                    },
                })
            }
        }).catch((error) => {
            if (error) {
                console.log(error.response.status);
                setLoading(false);
            }
        });
    }

    //defining image upload onchange handler
    const imageUploadOnchangeHandler = (e) => {
        // setImageLoader(true);

        let formData = new FormData;
        formData.append('image', e.target.files[0]);
        // const config = {
        //     headers: {
        //         'content-type': 'multipart/form-data',
        //         "Access-Control-Allow-Origin": "*"
        //     }
        // }

        axios.post(`/admin/microservices/v1/imageUpload`, formData, { withCredentials: true }).then(response => {

            // setImageLoader(false);
            setBrandImagePreview(response.data.info.Location);

        });

    }

    return (
        <>
            <Layout>


                <h2 className="breadcrumb-custom"> <span>Dashboard</span> {'>'} <span>Products</span> {'>'} <span> Brand Managment</span></h2>
                <div className="dashboard-full">
                    <div className="dashboard-half">

                        <div className="dashboard-page-header">
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__left">
                                <h3 className="dashboard-page-header__left--title">Add New Brand</h3>
                            </div>
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__right">

                                {/* <select className="bselect" name="" id="">
                                    <option value="Follow Up">Follow Up</option>
                                    <option value="In Transit">In Transit</option>
                                    <option value="Delivered">Delivered</option>
                                </select> */}

                                {/* <form style={{ flex: "0 0 100%" }} >
                                <input className="binput" placeholder="Search By Name" type="text" name="" id="" />
                            </form> */}


                                {/* <button onClick={() => props.history.push('/sub-categories')} style={{ flex: "0 0 100%" }} className="bbutton bbutton-primary"> <svg className="admin-svg white" viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg> Add New SubCategory</button> */}

                            </div>
                        </div>

                        {/* Create New Category Form */}
                        <div className="dashboard-card">

                            <form onSubmit={onSubmitHandler}>

                                <label className="blabel">Brand Image</label>
                                <div className="file-input-master-wrapper">
                                    <label className="file-input-box" htmlFor="file-6">
                                        <svg className="file-input-svg" xmlns="http://www.w3.org/2000/svg" width={20} height={17} viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg>
                                        <input onChange={imageUploadOnchangeHandler} type="file" id="file-6" className="binput-file" />
                                        <span className="file-input-label">Drag or Click to upload your image</span>
                                    </label>
                                    <div className="file-input-master-wrapper__gallery">
                                        {
                                            brandImagePreview ? <img className="file-input-master-wrapper__gallery--image" src={brandImagePreview} alt="" /> : null
                                        }
                                    </div>
                                </div>

                                <label className="blabel">Brand Name</label>
                                <input onChange={onChangeHandler} className="binput" type="text" name="name" required />

                                <label className="blabel">Brand Description</label>
                                <textarea onChange={onChangeHandler} className="ctext-area" name="description" required cols="30" rows="2"></textarea>


                                {
                                    loading ? <button disabled style={{ width: "100%", marginTop: "4rem" }} className="bbutton bbutton-loading"> <img src="/three-dot.svg" width="12%" alt="" /> </button>
                                        :
                                        <button type='submit' style={{ width: "100%", marginTop: "4rem" }} className="bbutton bbutton-primary">Create Brand</button>
                                }


                            </form>

                        </div>

                    </div>
                    <div className="dashboard-half">

                        {/* List of All Categories */}
                        <div className="dashboard-page-header">
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__left">
                                <h3 className="dashboard-page-header__left--title">All Brands</h3>
                            </div>
                            <div style={{ flex: "0 0 50%" }} className="dashboard-page-header__right">

                                {/* <select className="bselect" name="" id="">
                                    <option value="Follow Up">Follow Up</option>
                                    <option value="In Transit">In Transit</option>
                                    <option value="Delivered">Delivered</option>
                                </select> */}

                                {/* <form style={{ flex: "0 0 100%" }} >
                                    <input className="binput" placeholder="Search By Name" type="text" name="" id="" />
                                </form> */}

                                {/* <button style={{flex: "0 0 100%"}} className="bbutton bbutton-primary"> <svg className="admin-svg white" viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg> Add New Product</button> */}
                            </div>
                        </div>

                        {/* All Categories Here */}
                        <div className="dashboard-table-wrapper">
                            <table class="table">
                                <tbody>
                                    <tr style={{ borderBottom: "1px solid var(--color-grey-green)" }}>
                                        {/* <th scope="row">ID</th> */}
                                        <th scope="row">Name</th>
                                        <th scope="row">Image</th>
                                        <th scope="row">Slug</th>
                                        <th scope="row">Description</th>
                                        <th scope="row">Action</th>
                                    </tr>

                                    {/* Doing The Loop Here */}
                                    {
                                        brands.map(brand => {
                                            return (
                                                <tr key={brand._id} className="align-middle">
                                                    {/* <td> {category._id} </td> */}
                                                    <td> {brand.name} </td>
                                                    <td>
                                                        <img width={'50px'} src={brand.image} alt="" />
                                                    </td>
                                                    <td> {brand.slug} </td>
                                                    <td> {brand.description} </td>
                                                    <td onClick={() => editCategoryTrigger(brand)}><svg style={{ color: 'var(--color-primary-db)', cursor: 'pointer' }} viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg></td>
                                                </tr>
                                            )
                                        })
                                    }


                                </tbody>
                            </table>
                        </div>

                        {/* Connecting Edit Category Component */}
                        {
                            editBrand.boolean ? <EditBrand drawerClass={'pop-drawer'}
                                drawerBodyClass={'pop-drawer__body'}
                                closeEditCategory={closeEditCategory}
                                width={40}
                                refresh={refresh}
                                brand={editBrand.brand}
                            /> : <EditBrand brand={''} />
                        }

                    </div>
                </div>
            </Layout>

        </>
    );
}
export default Brands;