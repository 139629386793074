import { Button, Card, DatePicker, Radio, Table, message } from "antd";
import { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { IoRefreshSharp } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import AddInventoryModal from "../../AddInventoryModal/AddInventoryModal";
import Layout from "../../Components/Layout";
import axios from "../../config/axios";
import moment from "../../config/moment";

const Inventory = () => {
    // get tab from query params
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tabValue = searchParams.get("tab");

    const [activeView, setActiveView] = useState(() => (tabValue === "out" ? "out" : "in"));
    const [inventoryIn, setInventoryIn] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        loading: true,
        moreLoading: false,
        date: moment(),
        refresh: 0,
    });

    const [inventoryOut, setInventoryOut] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        loading: true,
        moreLoading: false,
        date: moment(),
        refresh: 0,
    });
    const [addInventoryModalShow, setAddInventoryModalShow] = useState(false);

    // get inventory in records
    useEffect(() => {
        (async () => {
            try {
                setInventoryIn((prev) => ({ ...prev, moreLoading: true }));
                const res = await axios.get("/admin/inventory/in/v1", {
                    withCredentials: true,
                    params: {
                        limit: inventoryIn.limit,
                        page: inventoryIn.page,
                        date: inventoryIn.date ? inventoryIn.date.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    },
                });
                setInventoryIn((prev) => ({ ...prev, data: res.data.inventory_in_records, total: res.data.count }));
            } catch (error) {
                message.error("Error while fetching inventory in records.");
            } finally {
                setInventoryIn((prev) => ({ ...prev, moreLoading: false, loading: false }));
            }
        })();
    }, [inventoryIn.date, inventoryIn.limit, inventoryIn.page, inventoryIn.refresh, activeView]);

    // get inventory out records
    useEffect(() => {
        (async () => {
            try {
                setInventoryOut((prev) => ({ ...prev, moreLoading: true }));
                const res = await axios.get("/admin/inventory/out/v1", {
                    withCredentials: true,
                    params: {
                        limit: inventoryOut.limit,
                        page: inventoryOut.page,
                        date: inventoryOut.date ? inventoryOut.date.format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
                    },
                });
                setInventoryOut((prev) => ({ ...prev, data: res.data.inventory_out_records, total: res.data.count }));
            } catch (error) {
                message.error("Error while fetching inventory out records.");
            } finally {
                setInventoryOut((prev) => ({ ...prev, moreLoading: false, loading: false }));
            }
        })();
    }, [inventoryOut.date, inventoryOut.limit, inventoryOut.page, inventoryOut.refresh, activeView]);

    return (
        <Layout>
            <Card
                bordered={false}
                bodyStyle={{
                    padding: 15,
                }}
            >
                <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
                    <h3 className="mb-0">Inventory Records</h3>
                    <div className="d-flex gap-2 flex-wrap">
                        <Link to={"/inventory-in"}>
                            <Button type="primary" className="d-flex align-items-center justify-content-center gap-2" size="large">
                                <AiOutlinePlusCircle size={20} />
                                <span>Add Inventory In Record</span>
                            </Button>
                        </Link>

                        <Link to={"/inventory-out"}>
                            <Button type="primary" className="d-flex align-items-center justify-content-center gap-2" size="large">
                                <AiOutlinePlusCircle size={20} />
                                <span>Add Inventory Out Record</span>
                            </Button>
                        </Link>

                        <Button
                            type="primary"
                            className="d-flex align-items-center justify-content-center gap-2"
                            onClick={() => setAddInventoryModalShow(true)}
                            size="large"
                        >
                            <AiOutlinePlusCircle size={20} />
                            <span>Add Inventory Items</span>
                        </Button>
                    </div>
                </div>
            </Card>
            <br />
            <Card
                bordered={false}
                bodyStyle={{
                    padding: 15,
                }}
            >
                <Radio.Group
                    defaultValue={activeView}
                    buttonStyle="solid"
                    onChange={(e) => {
                        setActiveView(e.target.value);
                        if (e.target.value === "in") {
                            window.history.replaceState(null, null, `/inventory`);
                        } else {
                            window.history.replaceState(null, null, `?tab=out`);
                        }
                    }}
                    size="large"
                >
                    <Radio.Button value="in">Inventory In Records</Radio.Button>
                    <Radio.Button value="out">Inventory Out Records</Radio.Button>
                </Radio.Group>

                <div className="mt-4">
                    {activeView === "in" && (
                        <>
                            {inventoryIn.loading ? (
                                <div className="d-flex align-items-center justify-content-center" style={{ height: "60vh" }}>
                                    <img src="/loader.svg" alt="loader" height={20} />
                                </div>
                            ) : (
                                <Table
                                    loading={inventoryIn.moreLoading}
                                    title={() => (
                                        <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
                                            <div className="d-flex gap-2 align-items-center">
                                                <h4 className="mb-0">Inventory In Records</h4>
                                                <IoRefreshSharp
                                                    size={22}
                                                    role="button"
                                                    onClick={() => setInventoryIn((prev) => ({ ...prev, refresh: Math.random() }))}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <label htmlFor="date" className="fw-bold">
                                                    Filter by Date:{" "}
                                                </label>
                                                <DatePicker
                                                    onChange={(date, _) => {
                                                        setInventoryIn({ ...inventoryIn, date });
                                                    }}
                                                    value={inventoryIn.date}
                                                    format={"YYYY-MM-DD"}
                                                    size="large"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    bordered
                                    dataSource={inventoryIn.data}
                                    rowKey={(record) => record._id}
                                    columns={[
                                        {
                                            title: "Date",
                                            dataIndex: "date",
                                            key: "date",
                                            render: (date) => moment(date).format("DD.MM.YYYY - LT"),
                                        },
                                        {
                                            title: "Added By",
                                            dataIndex: "submitted_by",
                                            key: "submitted_by",
                                            render: (submitted_by) => submitted_by.name,
                                        },
                                        {
                                            title: "Items",
                                            dataIndex: "items",
                                            key: "items",
                                            render: (items, record) => (
                                                <div className="custom-table-cell">
                                                    <Table
                                                        dataSource={items}
                                                        columns={[
                                                            {
                                                                title: "Name",
                                                                dataIndex: "item",
                                                                key: "item",
                                                                render: (item) => item.name,
                                                            },
                                                            {
                                                                title: "Quantity",
                                                                dataIndex: "item",
                                                                key: "item",
                                                                render: (items) => {
                                                                    const item = record.items.find((i) => i.item._id === items._id);
                                                                    return item.qty;
                                                                },
                                                            },
                                                            {
                                                                title: "Unit",
                                                                dataIndex: "item",
                                                                key: "item",
                                                                render: (item) => item.unit,
                                                            },
                                                        ]}
                                                        pagination={false}
                                                        size="small"
                                                        bordered
                                                        rowKey={(record) => record.item._id}
                                                        className="custom-item-table"
                                                    />
                                                </div>
                                            ),
                                        },
                                    ]}
                                    pagination={{
                                        pageSize: inventoryIn.limit,
                                        total: inventoryIn.total,
                                        showSizeChanger: false,
                                        onChange: (page, _) => {
                                            setInventoryIn({ ...inventoryIn, page });
                                        },
                                    }}
                                />
                            )}
                        </>
                    )}

                    {activeView === "out" && (
                        <>
                            {inventoryOut.loading ? (
                                <div className="d-flex align-items-center justify-content-center" style={{ height: "60vh" }}>
                                    <img src="/loader.svg" alt="loader" height={20} />
                                </div>
                            ) : (
                                <Table
                                    loading={inventoryOut.moreLoading}
                                    title={() => (
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex gap-2 align-items-center">
                                                <h4 className="mb-0">Inventory Out Records</h4>
                                                <IoRefreshSharp
                                                    size={22}
                                                    role="button"
                                                    onClick={() => setInventoryOut((prev) => ({ ...prev, refresh: Math.random() }))}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <label htmlFor="date" className="fw-bold">
                                                    Filter by Date:{" "}
                                                </label>
                                                <DatePicker
                                                    onChange={(date, _) => {
                                                        setInventoryOut({ ...inventoryOut, date });
                                                    }}
                                                    value={inventoryOut.date}
                                                    format={"YYYY-MM-DD"}
                                                    size="large"
                                                />
                                            </div>
                                        </div>
                                    )}
                                    bordered
                                    dataSource={inventoryOut.data}
                                    rowKey={(record) => record._id}
                                    columns={[
                                        {
                                            title: "Date",
                                            dataIndex: "date",
                                            key: "date",
                                            render: (date) => moment(date).format("DD.MM.YYYY - LT"),
                                        },
                                        {
                                            title: "Added By",
                                            dataIndex: "submitted_by",
                                            key: "submitted_by",
                                            render: (submitted_by) => submitted_by.name,
                                        },
                                        {
                                            title: "Items",
                                            dataIndex: "items",
                                            key: "items",
                                            render: (items, record) => (
                                                <div className="custom-table-cell">
                                                    <Table
                                                        dataSource={items}
                                                        columns={[
                                                            {
                                                                title: "Name",
                                                                dataIndex: "item",
                                                                key: "item",
                                                                render: (item) => item.name,
                                                            },
                                                            {
                                                                title: "Quantity",
                                                                dataIndex: "item",
                                                                key: "item",
                                                                render: (items) => {
                                                                    const item = record.items.find((i) => i.item._id === items._id);
                                                                    return item.qty;
                                                                },
                                                            },
                                                            {
                                                                title: "Unit",
                                                                dataIndex: "item",
                                                                key: "item",
                                                                render: (item) => item.unit,
                                                            },
                                                        ]}
                                                        pagination={false}
                                                        size="small"
                                                        bordered
                                                        rowKey={(record) => record.item._id}
                                                        className="custom-item-table"
                                                    />
                                                </div>
                                            ),
                                        },
                                    ]}
                                    pagination={{
                                        pageSize: inventoryOut.limit,
                                        total: inventoryOut.total,
                                        showSizeChanger: false,
                                        onChange: (page, _) => {
                                            setInventoryOut({ ...inventoryOut, page });
                                        },
                                    }}
                                />
                            )}
                        </>
                    )}
                </div>
            </Card>
            {addInventoryModalShow && <AddInventoryModal showModal={addInventoryModalShow} setShowModal={setAddInventoryModalShow} />}
        </Layout>
    );
};

export default Inventory;
