import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Card, Form, Input, InputNumber, Modal, Select, Spin, Table, message } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import Layout from "../../Components/Layout";
import axios from "../../config/axios";

const ContactSubmission = () => {
    const [loading, setLoading] = useState(true);
    const [moreLoading, setMoreLoading] = useState(false);
    const [submissions, setSubmissions] = useState({
        data: [],
        page: 1,
        total: 0,
        limit: 10,
        phone: "",
    });
    const [statusUpdateLoading, setStatusUpdateLoading] = useState(false);
    const [statusUpdateId, setStatusUpdateId] = useState(null);
    const [showModal, setShowModal] = useState({
        view: false,
        edit: false,
        view_id: null,
        edit_id: null,
    });
    const [refresh, setRefresh] = useState(0);

    // fetch submissions
    useEffect(() => {
        (async () => {
            try {
                setMoreLoading(true);
                const res = await axios.get("/admin/contact/v1", {
                    withCredentials: true,
                    params: {
                        page: submissions.page,
                        limit: submissions.limit,
                        phone: submissions.phone ? submissions.phone : undefined,
                    },
                });
                setSubmissions((prev) => ({
                    ...prev,
                    data: res.data.results,
                    total: res.data.count,
                }));
            } catch (error) {
                message.error("Error while fetching submissions!");
            } finally {
                setLoading(false);
                setMoreLoading(false);
            }
        })();
    }, [submissions.phone, submissions.limit, submissions.page, refresh]);

    // handle status change
    const handleStatusChange = async (value, id) => {
        try {
            setStatusUpdateLoading(true);
            await axios.patch(
                `/admin/contact/v1/${id}`,
                {
                    status: value,
                },
                {
                    withCredentials: true,
                }
            );
            message.success("Status updated successfully!");
        } catch (error) {
            message.error("Error while updating status!");
        } finally {
            setStatusUpdateLoading(false);
        }
    };

    // handle delete submission
    const handleDeleteSubmission = (id) => {
        Modal.confirm({
            title: "Do you Want to delete this record?",
            icon: <ExclamationCircleFilled />,
            content: "This action cannot be undone.",
            async onOk() {
                await axios.delete(`/admin/contact/v1/${id}`, {
                    withCredentials: true,
                });
                setRefresh((prev) => prev + 1);
            },
            centered: true,
            okText: "Delete",
            okButtonProps: {
                danger: true,
            },
        });
    };

    return (
        <Layout>
            <Card
                bordered={false}
                bodyStyle={{
                    padding: 15,
                }}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">Contact Us Submission</h3>
                </div>
            </Card>
            <br />
            <div>
                {loading ? (
                    <Card bordered={false}>
                        <div className="d-flex align-items-center justify-content-center" style={{ height: "60vh" }}>
                            <img src="/loader.svg" alt="loader" height={20} />
                        </div>
                    </Card>
                ) : (
                    <Card
                        bordered={false}
                        bodyStyle={{
                            padding: 15,
                        }}
                    >
                        <div className="d-flex justify-content-end mb-4">
                            <InputNumber
                                type="number"
                                onChange={(value) => {
                                    setSubmissions((prev) => ({
                                        ...prev,
                                        phone: value,
                                        page: 1,
                                    }));
                                    setMoreLoading(true);
                                }}
                                style={{ width: 200 }}
                                placeholder="Search by phone number"
                            />
                        </div>
                        <Table
                            dataSource={submissions.data}
                            columns={[
                                {
                                    title: "Name",
                                    dataIndex: "name",
                                    key: "name",
                                },
                                {
                                    title: "Phone",
                                    dataIndex: "phone",
                                    key: "phone",
                                },
                                {
                                    title: "Email",
                                    dataIndex: "email",
                                    key: "email",
                                },
                                {
                                    title: "Description",
                                    dataIndex: "description",
                                    key: "description",
                                    render: (description) =>
                                        description.length <= 40 ? description : description.slice(0, 40) + "...",
                                },
                                {
                                    title: "Status",
                                    dataIndex: "status",
                                    key: "status",
                                    render: (status, record) => (
                                        <Select
                                            defaultValue={status}
                                            className="w-100"
                                            onChange={(value) => {
                                                setStatusUpdateId(record._id);
                                                handleStatusChange(value, record._id);
                                            }}
                                            options={[
                                                {
                                                    value: "pending",
                                                    label: "Pending",
                                                },
                                                {
                                                    value: "resolved",
                                                    label: "Resolved",
                                                },
                                            ]}
                                            loading={statusUpdateLoading && record._id === statusUpdateId}
                                            disabled={statusUpdateLoading && record._id === statusUpdateId}
                                        />
                                    ),
                                },
                                {
                                    title: "Action",
                                    dataIndex: "action",
                                    key: "action",
                                    render: (_, record) => (
                                        <div className="d-flex align-items-center gap-3">
                                            <AiOutlineEye
                                                size={22}
                                                role="button"
                                                title="View"
                                                onClick={() => {
                                                    setShowModal((prev) => ({
                                                        ...prev,
                                                        view: true,
                                                        view_id: record._id,
                                                    }));
                                                }}
                                            />
                                            <FiEdit
                                                size={20}
                                                role="button"
                                                title="Edit"
                                                onClick={() => {
                                                    setShowModal((prev) => ({
                                                        ...prev,
                                                        edit: true,
                                                        edit_id: record._id,
                                                    }));
                                                }}
                                            />
                                            <AiOutlineDelete
                                                size={20}
                                                role="button"
                                                title="Delete"
                                                onClick={() => {
                                                    handleDeleteSubmission(record._id);
                                                }}
                                            />
                                        </div>
                                    ),
                                },
                            ]}
                            rowKey={(record) => record._id}
                            loading={moreLoading}
                            bordered
                            pagination={{
                                pageSize: 10,
                                total: submissions.total,
                                onChange: (page) => {
                                    setSubmissions((prev) => ({
                                        ...prev,
                                        page,
                                    }));
                                    setMoreLoading(true);
                                },
                            }}
                        />
                    </Card>
                )}
            </div>
            {showModal.view && (
                <ViewSubmission setShowModal={setShowModal} showModal={showModal} id={showModal.view_id} />
            )}
            {showModal.edit && (
                <EditSubmission
                    setShowModal={setShowModal}
                    showModal={showModal}
                    id={showModal.edit_id}
                    setRefresh={setRefresh}
                />
            )}
        </Layout>
    );
};

export default ContactSubmission;

const ViewSubmission = ({ setShowModal, showModal, id }) => {
    const [loading, setLoading] = useState(true);
    const [submission, setSubmission] = useState({});
    const [error, setError] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`/admin/contact/v1/${id}`, {
                    withCredentials: true,
                });
                setSubmission(res.data);
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    return (
        <Modal
            title="View Submission"
            open={showModal}
            onCancel={() =>
                setShowModal((prev) => ({
                    ...prev,
                    view: false,
                }))
            }
            footer={null}
            centered
            bodyStyle={{
                backgroundColor: "white",
                borderRadius: 10,
            }}
        >
            {loading ? (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                        height: 150,
                    }}
                >
                    <Spin />
                </div>
            ) : error ? (
                <div
                    style={{
                        height: 150,
                    }}
                    className="d-flex align-items-center justify-content-center"
                >
                    <h3>Something went wrong</h3>
                </div>
            ) : (
                <div className="d-flex flex-column gap-2">
                    <div className="d-flex gap-2">
                        <div className="fw-bold">Name: </div>
                        <div>{submission.name}</div>
                    </div>
                    <div className="d-flex gap-2">
                        <div className="fw-bold">Phone: </div>
                        <div>{submission.phone}</div>
                    </div>
                    <div className="d-flex gap-2">
                        <div className="fw-bold">Email: </div>
                        <div>{submission.email ? submission.email : "Not Provided"}</div>
                    </div>
                    <div className="d-flex gap-2">
                        <div className="fw-bold">Description: </div>
                        <div>{submission.description}</div>
                    </div>
                    <div className="d-flex gap-2">
                        <div className="fw-bold">Status: </div>
                        <div className="text-capitalize">{submission.status}</div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

const EditSubmission = ({ setShowModal, showModal, id, setRefresh }) => {
    const [loading, setLoading] = useState(true);
    const [submission, setSubmission] = useState({});
    const [error, setError] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`/admin/contact/v1/${id}`, {
                    withCredentials: true,
                });
                setSubmission(res.data);
            } catch (error) {
                setError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    // Update Submission
    const [updateLoading, setUpdateLoading] = useState(false);

    const handleUpdateSubmission = async (values) => {
        try {
            setUpdateLoading(true);
            await axios.patch(`/admin/contact/v1/${id}`, values, {
                withCredentials: true,
            });
            message.success("Submission updated successfully");
            setShowModal((prev) => ({
                ...prev,
                edit: false,
            }));
            setRefresh((prev) => prev + 1);
        } catch (error) {
            message.error("Error updating submission");
        } finally {
            setUpdateLoading(false);
        }
    };

    return (
        <Modal
            title="Edit Submission"
            open={showModal}
            onCancel={() =>
                setShowModal((prev) => ({
                    ...prev,
                    edit: false,
                }))
            }
            footer={null}
            centered
            bodyStyle={{
                backgroundColor: "white",
                borderRadius: 10,
            }}
        >
            {loading ? (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                        height: 150,
                    }}
                >
                    <Spin />
                </div>
            ) : error ? (
                <div
                    style={{
                        height: 150,
                    }}
                    className="d-flex align-items-center justify-content-center"
                >
                    <h3>Something went wrong</h3>
                </div>
            ) : (
                <Form
                    layout="vertical"
                    initialValues={{
                        ...submission,
                    }}
                    onFinish={handleUpdateSubmission}
                    autoComplete="off"
                    requiredMark={false}
                    scrollToFirstError
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: "Please input name!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[
                            {
                                required: true,
                                message: "Please input phone!",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: false,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Status"
                        name="status"
                        rules={[
                            {
                                required: true,
                                message: "Please select status!",
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select status"
                            options={[
                                {
                                    label: "Pending",
                                    value: "pending",
                                },
                                {
                                    label: "Resolved",
                                    value: "resolved",
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[
                            {
                                required: true,
                                message: "Please input description!",
                            },
                        ]}
                    >
                        <Input.TextArea rows={3} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block loading={updateLoading} disabled={updateLoading}>
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </Modal>
    );
};
