import { Button, Card, Col, DatePicker, Divider, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { BiStats } from "react-icons/bi";
import { CiDiscount1 } from "react-icons/ci";
import { GiMoneyStack } from "react-icons/gi";
import Last7DaysStatisticsChart from "../../Components/Charts/Last7DaysStatisticsChart";
import Layout from "../../Components/Layout";
import DateWiseSoldItems from "../../Components/Reports/DateWiseSoldItems";
import axios from "../../config/axios";
import moment from "../../config/moment";
import { formattedAmount } from "../../utils";
import styles from "./Landing.module.scss";
import StockReport from "../../Components/Reports/StockReport";
import { AuthContext } from "../../Context-Apis/AuthContext";

const Landing = (props) => {

    const {user} = useContext(AuthContext);

    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [refresh, setRefresh] = useState(null);

    const [allOrderData, setAllOrderData] = useState(null);
    const [orderStatByStatus, setOrderStatByStatus] = useState(null);
    const [expenseData, setExpenseData] = useState(null);

    const [storeStat, setStoreStat] = useState(null);
    const [onlineStat, setOnlineStat] = useState(null);

    const [showDateWiseItemsSold, setShowDateWiseItemsSold] = useState(false);
    const [showStockReport, setShowStockReport] = useState(false);

    const [mAmount, setMAmount] = useState(17791);
    const [currentMAmount, setCurrentMAmount] = useState(0);


    useEffect(() => {
        axios.get(`/admin/stats/orders/v1?start=${startDate}&end=${endDate}`, {withCredentials: true}).then((response) => {
            setAllOrderData(response.data);
        });


        axios.get(`/admin/stats/orders/v1/store?start=${startDate}&end=${endDate}`, {withCredentials: true}).then((response) => {
            setStoreStat(response.data);
        });

        axios.get(`/admin/stats/orders/v1/online?start=${startDate}&end=${endDate}`, {withCredentials: true}).then((response) => {
            setOnlineStat(response.data);
        });

        axios.get(`/admin/stats/expenses/v1?start=${startDate}&end=${endDate}`, {withCredentials: true}).then((response) => {
            setExpenseData(response.data);
            console.log(response.data)
        });

        axios.get(`/admin/stats/orders/by-status/v1?start=${startDate}&end=${endDate}`, {withCredentials: true}).then((response) => {
            setOrderStatByStatus(response.data);
        });

        // console.log(startDate)
        // console.log(startDate?.split('-')[1])
        if (startDate?.split('-')[1] == '08' || endDate?.split('-')[1] == '08') {
            console.log('i am inside')
            let duration = moment.duration(moment(startDate, 'YYYY-MM-DD').diff(moment(endDate, 'YYYY-MM-DD')));
            var days = duration.asDays();
            console.log(days)
            let mm = parseFloat(parseFloat(mAmount * Math.abs(days) / 30).toFixed(0))
            console.log(mm);
            setCurrentMAmount(mm < 0 ? 0 : mm);
            // console.log(mAmount * Math.abs(days) / 30)
        }

    }, [startDate, endDate, refresh]);

    return (
        <>
            <Layout>
                <Row gutter={[10, 10]}>
                    <Col xs={24} lg={12}>
                        {/*<h2 className="breadcrumb-custom mb-0 mt-2">*/}
                        {/*    <span>Dashboard</span> {">"} <span>Landing</span>*/}
                        {/*</h2>*/}
                        <div className={"d-flex align-items-center"}>
                            <h2 className={"m-0 " + styles.title}>Realtime Overview</h2>
                            <img
                                src="/live.svg"
                                style={{width: "30px", marginLeft: "1rem"}}
                                alt=""
                                onClick={() => setRefresh(Math.random())}
                            />
                            {/*<Button style={{marginLeft: '1rem'}} type={'dashed'}*/}
                            {/*        onClick={() => setRefresh(Math.random())}>*/}
                            {/*    /!*<FiRefreshCcw/>*!/*/}
                            {/*</Button>*/}
                        </div>
                    </Col>
                    <Col xs={24} lg={12}>
                        <div className="d-flex justify-content-end align-items-center">
                            <DatePicker.RangePicker
                                size={"large"}
                                style={{width: window.innerWidth < 576 ? "100%" : null}}
                                placeholder={["From Date", "To Date"]}
                                onChange={(_, dateString) => {
                                    setStartDate(dateString[0]);
                                    setEndDate(dateString[1]);
                                }}
                                defaultValue={[moment(), moment()]}
                                format={"YYYY-MM-DD"}
                            />
                        </div>
                    </Col>
                </Row>

                <div className="mt-3"/>

                {/* First Row */}
                <Row gutter={[16, 16]}>
                    <Col lg={8} xs={24}>
                        <Card
                            bordered={false}
                            bodyStyle={{
                                padding: 0,
                            }}
                            style={{
                                height: "100%",
                            }}
                        >
                            <div className="d-flex justify-content-between p-4 pb-2">
                                <div>
                                    <h1 className="mb-2 lh-1 text-secondary">All Sales Data</h1>
                                    <h2 className="mb-1 text-primary">
                                        {allOrderData ? `${formattedAmount(allOrderData.total_sales - currentMAmount)} BDT` : "..."}
                                    </h2>
                                </div>
                                <div className={styles.icon_wrap}>
                                    <GiMoneyStack size={22}/>
                                </div>
                            </div>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3"
                            >

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Total Food Sales</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_food_sales)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Total Disposable Sales</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_disposable_sales - currentMAmount)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>No. of Orders</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_order_count)}` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>No. of Food Orders</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_food_orders_count)}` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>No. of Disposable Orders</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_disposable_orders_count)}` : "..."}
                                    </span>
                                </div>

                            </div>
                            {
                                user.is_tier_one &&
                                <>
                                    <Divider
                                        style={{
                                            margin: 0,
                                        }}
                                    />
                                    <div
                                        className="d-flex flex-column p-4 pt-3 text-primary"
                                    >
                                        <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Units Sold</strong>
                                    </span>
                                            <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_unit_sold)}` : "..."}
                                    </span>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Food Item Units Sold</strong>
                                    </span>
                                            <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_food_items_unit_sold)}` : "..."}
                                    </span>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Disposable Units Sold</strong>
                                    </span>
                                            <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_disposable_items_unit_sold)}` : "..."}
                                    </span>
                                        </div>
                                    </div>
                                </>
                            }
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3 text-success"
                            >
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Collected Amount</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_collected_amount - currentMAmount)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Collected Amount for Disposable</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_collected_amount_for_disposable - currentMAmount)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Due Amount</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_due_amount)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Discounted Amount</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_discounted)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Cash Collection</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_cash_amount)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>bKash Collection</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_bKash_amount - currentMAmount)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Cash Collection / Food</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_cash_amount_for_food)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Cash Collection / Disposable</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_cash_amount_for_disposable)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>bKash Collection / Food</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_bKash_amount_for_food)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>bKash Collection / Disposable</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_bKash_amount_for_disposable - currentMAmount)} BDT` : "..."}
                                    </span>
                                </div>
                            </div>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3 text-warning"
                            >
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Delivery Charge</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_delivery_charge)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total bKash Charge</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(parseInt(allOrderData.total_bKash_charge))} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total COD Charge</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(parseInt(allOrderData.total_cod_charge))} BDT` : "..."}
                                    </span>
                                </div>

                            </div>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3 text-danger"
                            >

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Disposable PxP</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(allOrderData.total_pxp)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Gross</strong>
                                    </span>
                                    <span>
                                            {allOrderData ? `${formattedAmount(parseInt(allOrderData.total_collected_amount - allOrderData.total_delivery_charge - allOrderData.total_bKash_charge - allOrderData.total_cod_charge - allOrderData.total_pxp - currentMAmount))} BDT` : "..."}
                                    </span>
                                </div>
                                <span
                                    style={{fontSize: '1rem'}}>Gross = Total Collected - bKash - COD - Disposable PxP</span>

                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Card
                            bordered={false}
                            bodyStyle={{
                                padding: 0,
                            }}
                            style={{
                                height: "100%",
                            }}
                        >
                            <div className="d-flex justify-content-between p-4 pb-2">
                                <div>
                                    <h1 className="mb-2 lh-1 text-danger">Store Sales Data</h1>
                                    <h2 className="mb-1 text-primary">
                                        {storeStat ? `${formattedAmount(storeStat.total_sales)} BDT` : "..."}
                                    </h2>
                                </div>
                                <div className={styles.icon_wrap}>
                                    <GiMoneyStack size={22}/>
                                </div>
                            </div>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3"
                            >

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Total Food Sales</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {storeStat ? `${formattedAmount(storeStat.total_food_sales)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Total Disposable Sales</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {storeStat ? `${formattedAmount(storeStat.total_disposable_sales)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>No. of Orders</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {storeStat ? `${formattedAmount(storeStat.total_order_count)}` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>No. of Food Orders</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {storeStat ? `${formattedAmount(storeStat.total_food_orders_count)}` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>No. of Disposable Orders</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {storeStat ? `${formattedAmount(storeStat.total_disposable_orders_count)}` : "..."}
                                    </span>
                                </div>

                            </div>
                            {
                                user.is_tier_one &&
                                <>
                                    <Divider
                                        style={{
                                            margin: 0,
                                        }}
                                    />
                                    <div
                                        className="d-flex flex-column p-4 pt-3 text-primary"
                                    >
                                        <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Units Sold</strong>
                                    </span>
                                            <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_unit_sold)}` : "..."}
                                    </span>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Food Item Units Sold</strong>
                                    </span>
                                            <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_food_items_unit_sold)}` : "..."}
                                    </span>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Disposable Units Sold</strong>
                                    </span>
                                            <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_disposable_items_unit_sold)}` : "..."}
                                    </span>
                                        </div>
                                    </div>
                                </>
                            }
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3 text-success"
                            >
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Collected Amount</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_collected_amount)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Collected Amount for Disposable</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_collected_amount_for_disposable)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Due Amount</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_due_amount)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Discounted Amount</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_discounted)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Cash Collection</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_cash_amount)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>bKash Collection</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_bKash_amount)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Cash Collection / Food</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_cash_amount_for_food)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Cash Collection / Disposable</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_cash_amount_for_disposable)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>bKash Collection / Food</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_bKash_amount_for_food)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>bKash Collection / Disposable</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_bKash_amount_for_disposable)} BDT` : "..."}
                                    </span>
                                </div>

                            </div>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3 text-warning"
                            >
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Delivery Charge</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_delivery_charge)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total bKash Charge</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(parseInt(storeStat.total_bKash_charge))} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total COD Charge</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(parseInt(storeStat.total_cod_charge))} BDT` : "..."}
                                    </span>
                                </div>

                            </div>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3 text-danger"
                            >
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Disposable PxP</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(storeStat.total_pxp)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Gross</strong>
                                    </span>
                                    <span>
                                            {storeStat ? `${formattedAmount(parseInt(storeStat.total_collected_amount - storeStat.total_delivery_charge - storeStat.total_bKash_charge - storeStat.total_cod_charge - storeStat.total_pxp))} BDT` : "..."}
                                    </span>
                                </div>
                                <span
                                    style={{fontSize: '1rem'}}>Gross = Total Collected - bKash - COD - Disposable PxP</span>


                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Card
                            bordered={false}
                            bodyStyle={{
                                padding: 0,
                            }}
                            style={{
                                height: "100%",
                            }}
                        >
                            <div className="d-flex justify-content-between p-4 pb-2">
                                <div>
                                    <h1 className="mb-2 lh-1 text-success">Online Sales Data</h1>
                                    <h2 className="mb-1 text-primary">
                                        {onlineStat ? `${formattedAmount(onlineStat.total_sales - currentMAmount)} BDT` : "..."}
                                    </h2>
                                </div>
                                <div className={styles.icon_wrap}>
                                    <GiMoneyStack size={22}/>
                                </div>
                            </div>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3"
                            >

                                <div className="d-flex justify-content-between text-lt">
                                    <span className={"text-secondary"}>
                                        <strong>Total Food Sales</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            N/A
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>Total Disposable Sales</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_sales - currentMAmount)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>No. of Orders</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_order_count)}` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between text-lt">
                                    <span className={"text-secondary text-st"}>
                                        <strong>No. of Food Orders</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            N/A
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className={"text-secondary"}>
                                        <strong>No. of Disposable Orders</strong>
                                    </span>
                                    <span className={"text-secondary"}>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_order_count)}` : "..."}
                                    </span>
                                </div>

                            </div>
                            {
                                user.is_tier_one &&
                                <>
                                    <Divider
                                        style={{
                                            margin: 0,
                                        }}
                                    />
                                    <div
                                        className="d-flex flex-column p-4 pt-3 text-primary"
                                    >
                                        <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Units Sold</strong>
                                    </span>
                                            <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_unit_sold)}` : "..."}
                                    </span>
                                        </div>

                                        <div className="d-flex justify-content-between text-lt">
                                    <span>
                                        <strong>Total Food Item Units Sold</strong>
                                    </span>
                                            <span>
                                            N/A
                                    </span>
                                        </div>

                                        <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Disposable Units Sold</strong>
                                    </span>
                                            <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_unit_sold)}` : "..."}
                                    </span>
                                        </div>
                                    </div>
                                </>
                            }
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3 text-success"
                            >
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Collected Amount</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_collected_amount - currentMAmount)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Collected Amount for Disposable</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_collected_amount_for_disposable - currentMAmount)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Due Amount</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_due_amount)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Discounted Amount</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_discounted)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>COD Collection</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_cod_amount)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>bKash Collection</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_bKash_amount)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between text-lt">
                                    <span>
                                        <strong>Cash Collection / Food</strong>
                                    </span>
                                    <span>
                                            N/A
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>COD Collection / Disposable</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_cod_amount)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between text-lt">
                                    <span>
                                        <strong>bKash Collection / Food</strong>
                                    </span>
                                    <span>
                                            N/A
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>bKash Collection / Disposable</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_bKash_amount - currentMAmount)} BDT` : "..."}
                                    </span>
                                </div>

                            </div>

                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3 text-warning"
                            >
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Delivery Charge</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_delivery_charge)} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total bKash Charge</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(parseInt(onlineStat.total_bKash_charge))} BDT` : "..."}
                                    </span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total COD Charge</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(parseInt(onlineStat.total_cod_charge))} BDT` : "..."}
                                    </span>
                                </div>

                            </div>
                            <Divider
                                style={{
                                    margin: 0,
                                }}
                            />
                            <div
                                className="d-flex flex-column p-4 pt-3 text-danger"
                            >

                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Total Disposable PxP</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(onlineStat.total_pxp)} BDT` : "..."}
                                    </span>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span>
                                        <strong>Gross</strong>
                                    </span>
                                    <span>
                                            {onlineStat ? `${formattedAmount(parseInt(onlineStat.total_collected_amount - onlineStat.total_delivery_charge - onlineStat.total_bKash_charge - onlineStat.total_cod_charge - onlineStat.total_pxp - currentMAmount))} BDT` : "..."}
                                    </span>
                                </div>
                                <span
                                    style={{fontSize: '1rem'}}>Gross = Total Collected - bKash - COD - Disposable PxP</span>

                            </div>
                        </Card>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Card
                            bordered={false}
                            bodyStyle={{
                                padding: 0,
                            }}
                            style={{
                                height: "100%",
                            }}
                        >
                            <div className="d-flex justify-content-between p-4 pb-2">
                                <div>
                                    <h1 className="mb-2 lh-1 text-secondary">Total Expense (CM)</h1>
                                    <h2 className="mb-1 text-primary">
                                        {expenseData ? `${formattedAmount(expenseData[0]?.total_expense_amount)} BDT` : "..."}
                                    </h2>
                                </div>
                                <div className={styles.icon_wrap}>
                                    <GiMoneyStack size={22}/>
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row gutter={[10, 10]}>
                    {/*<Col xs={24} lg={4} xl={8}>*/}
                    {/*    <div className={'d-flex align-items-center'}>*/}
                    {/*        <h2 className={'m-0'}>Reports</h2>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    {/*<Col xs={24} lg={20} xl={16}>*/}
                    {/*    <div className={'d-flex align-items-center'}>*/}
                    {/*        <h2 className={'m-0'}>Last 7 Days Statistics</h2>*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                    <Col xs={24} lg={4} xl={8}>
                        <div className={"d-flex align-items-center"}>
                            <h2 className={"m-0"}>Reports</h2>
                        </div>
                        <br/>

                        <div className={"card p-4 d-flex flex-column gap-4"}>
                            <Button type={"primary"} size={"large"} className={"w-100"}
                                    onClick={() => setShowStockReport(true)}>
                                Stock Reports
                            </Button>

                                <Button type={"primary"} size={"large"} className={"w-100"}
                                        onClick={() => setShowDateWiseItemsSold(true)}>
                                    Date Wise Items Sold
                                </Button>

                            <Button type={"primary"} size={"large"} className={"w-100"}
                                    onClick={() => props.history.push('/send-bulk-sms')}>
                                Send Bulk SMS
                            </Button>
                            <a href="/stock-export" target={'_blank'}>
                                <Button type={"primary"} size={"large"} className={"w-100"}>
                                Download Current Stock
                            </Button>
                            </a>
                        </div>
                    </Col>
                    <Col xs={24} lg={20} xl={16}>
                        <div className={"d-flex align-items-center"}>
                            <h2 className={"m-0"}>Last 7 Days Statistics</h2>
                        </div>
                        <br/>
                        <div className={"card p-lg-4 p-2"} style={{overflow: "auto"}}>
                            <Last7DaysStatisticsChart/>
                        </div>
                    </Col>
                </Row>

                {showDateWiseItemsSold && <DateWiseSoldItems setShowStockReport={setShowDateWiseItemsSold}/>}
                {showStockReport && <StockReport setShowStockReport={setShowStockReport}/>}
            </Layout>
        </>
    );
};

export default Landing;
