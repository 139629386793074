import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "antd/dist/custom-antd.css";
import * as serviceWorker from "./serviceWorker";
import AuthProvider from "./Context-Apis/AuthContext";

// Replace ReactDOM.render with createRoot
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
    <React.StrictMode>
        <AuthProvider>
            <App />
        </AuthProvider>
    </React.StrictMode>
);

// Unregister service worker
serviceWorker.unregister();
