import React from 'react';
import { useState, useEffect } from 'react';
import axios from '../config/axios';

//importing all components
import { SketchPicker } from 'react-color';
import { message, Checkbox } from 'antd';
import Layout from '../Components/Layout';
import {
    Row,
    Col
} from 'antd';

const CreateProduct = (props) => {

    const [product, setProduct] = useState({ name: '', description: '', category: '', brand: '', subCategory: '', regularPrice: '', salePrice: '', texture: 'print', otherFunctions: '' });
    const [featuredImage, setFeaturedImage] = useState(null);
    const [tags, setTags] = useState([]);
    const [tagHolder, setTagHolder] = useState('');
    const [colors, setColors] = useState([]);
    const [colorHolder, setColorHolder] = useState('');
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [shamimBhaiRefresh, setShamimBhaiRefresh] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isRefundable, setIsRefundable] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [genders, setGenders] = useState([]);

    const [details, setDetails] = useState({
        dimension: '',
        weight: '',
        typeOfLeather: '',
        madeIn: 'Bangladesh'
    });


    useEffect(() => {

        axios.get(`/admin/category/v1`, { withCredentials: true }).then(response => {
            setCategories(response.data.categories);
        });

        axios.get(`/admin/category/brands/v1`, { withCredentials: true }).then(response => {
            setBrands(response.data.brands);
        });

        axios.get(`/admin/category/sub/v1`, { withCredentials: true }).then(response => {
            setSubCategories(response.data.subCategories);
        });

    }, []);


    //window/tab closing warning event listener
    // window.addEventListener("beforeunload", (ev) => {
    //     ev.preventDefault();
    //     return ev.returnValue = 'Are you sure you want to close?';
    // });


    const shamimBhai = () => {
        // console.log(product);
        // console.log(colors);
        // console.log(tags);
        console.log(genders);
    }





    function removeItemOnce(arr, value) {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }



    //defining tagInputOnChange function
    const tagInputOnChange = (e) => {
        e.preventDefault();
        setTagHolder(e.target.value);
    }


    //defining tagInputHandler function
    const tagInputHandler = (e) => {
        e.preventDefault();

        setTags(tags => tags.concat(tagHolder));
        setTagHolder('');
    }


    //defining rmeoveTag function
    const removeTag = (tag) => {

        setTags(removeItemOnce(tags, tag));
        setShamimBhaiRefresh(Math.random());
    }

    useEffect(() => {
        setTags(tags);
    }, [shamimBhaiRefresh])


    //defining removeColor function
    const removeColor = (color) => {

        setColors(removeItemOnce(colors, color));
        setShamimBhaiRefresh(Math.random());
    }



    //defining colorOnChange function
    const colorOnChange = (e) => {
        //setColorHolder(color.hex)
        setColorHolder(e.target.value);
    }


    //defining colorInputOnChange function
    const colorInputOnChange = (e) => {
        e.preventDefault();
    }


    //defining colorInputHandler function
    const colorInputHandler = (e) => {
        e.preventDefault();

        if (colorHolder === '') {
            message.error({
                content: 'Please enter name of a color'
            })
        } else {
            setColors(colors => colors.concat(colorHolder));
            setColorHolder('');
        }
    }

    //defininf productOnChange function
    const productOnChange = (e) => {
        e.preventDefault();
        console.log(e.target.value);

        setProduct({ ...product, [e.target.name]: e.target.value });
    }


    //defining onChangeIsRefundableHandler function
    const onChangeIsRefundableHandler = (e) => {
        setIsRefundable(e.target.checked);
    }



    //defining image upload onchange handler
    const imageUploadOnchangeHandler = (e) => {
        setImageLoader(true);

        let formData = new FormData;
        formData.append('image', e.target.files[0]);
        // const config = {
        //     headers: {
        //         'content-type': 'multipart/form-data',
        //         "Access-Control-Allow-Origin": "*"
        //     }
        // }

        axios.post(`/admin/microservices/v1/imageUpload`, formData, { withCredentials: true }).then(responese => {

            setImageLoader(false);
            setFeaturedImage(responese.data.info.Location);

        });

    }

    //defining productDetailsOnChange function
    const productDetailsOnChange = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value });
    }


    //defining onSubmitHandler function
    const onSubmitHandler = () => {

        console.log(details);

        setLoading(true);

        let formData = new FormData;

        formData.append('name', product.name);
        formData.append('description', product.description);
        formData.append('brand', product.brand);
        formData.append('category', product.category);
        formData.append('subCategory', product.subCategory);
        formData.append('isRefundable', isRefundable);
        formData.append('regularPrice', product.regularPrice);
        formData.append('salePrice', product.salePrice);
        formData.append('colors', JSON.stringify(colors));
        formData.append('tags', JSON.stringify(tags));
        formData.append('featuredImage', featuredImage);
        formData.append('texture', product.texture);
        formData.append('details', JSON.stringify(details));

        axios.post(`/admin/products/v1/create/flow-1`, formData, { withCredentials: true }).then(responese => {

            setLoading(false);

            message.success({
                content: `Product Was Created Successfully!`,
            })

            setTimeout(() => {
                props.history.push(`/products/product-gallery/${responese.data.productID}`);
            }, 500)
        }).catch(error => {

            setLoading(false);

            if (error.response.status === 500) {
                message.error({
                    content: `Please fill all the fields carefully!`,
                })
            }
        })
    }



    return (
        <>
            <Layout>
                <h2 className="breadcrumb-custom"> <span>Dashboard</span> {'>'} <span>Products</span> {'>'} <span> Create Product</span></h2>
                <div className="dashboard-full">

                    <div style={{ backgroundColor: 'var(--color-white)', padding: '2rem' }} className="dashboard-half">

                        <label onClick={shamimBhai} className="blabel">Product Name</label>
                        <input onChange={productOnChange} name="name" className="binput" type="text" />

                        <label className="blabel">Product Featured Image</label>
                        <div className="file-input-master-wrapper">
                            <label className="file-input-box" htmlFor="file-6">
                                <svg className="file-input-svg" xmlns="http://www.w3.org/2000/svg" width={20} height={17} viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg>
                                <input onChange={imageUploadOnchangeHandler} type="file" id="file-6" className="binput-file" />
                                <span className="file-input-label">Drag or Click to upload your image</span>
                            </label>
                            <div style={{ justifyContent: 'center' }} className="file-input-master-wrapper__gallery">
                                {
                                    featuredImage ? <img className="file-input-master-wrapper__gallery--image" src={featuredImage} alt="" /> : null
                                }

                                {
                                    imageLoader ? <div style={{ height: '100px' }} className="loader"><img src="/loader.svg" alt="" /></div> : null
                                }
                            </div>
                        </div>



                        <label className="blabel">Product Description</label>
                        <textarea onChange={productOnChange} className="ctext-area" name="description" id="" cols="30" rows="10"></textarea>

                        {/* <label className="blabel">Is Product Refundable</label> */}
                        <Checkbox style={{ marginTop: '2rem' }} onChange={onChangeIsRefundableHandler}>Is Product Refundable</Checkbox>



                        <label className="blabel">Product Category</label>
                        <select onChange={productOnChange} className='bselect' name="category">
                            <option value="ps">Please Select</option>
                            {
                                categories.map(category => {
                                    return <option value={category._id}> {category.name} </option>
                                })
                            }

                        </select>

                        <label className="blabel">Product SubCategory</label>
                        <select onChange={productOnChange} className='bselect' name="subCategory">
                            <option value="ps">Please Select</option>
                            {
                                subCategories.map(subCategory => {
                                    return <option value={subCategory._id}> {subCategory.name} </option>
                                })
                            }
                        </select>


                    </div>


                    <div style={{ backgroundColor: 'var(--color-white)', padding: '2rem' }} className="dashboard-half">

                        <label className="blabel">Product Brand</label>
                        <select onChange={productOnChange} className='bselect' name="brand">
                            <option value="ps">Please Select</option>
                            {
                                brands.map(brand => {
                                    return <option value={brand._id}> {brand.name} </option>
                                })
                            }

                        </select>


                        {/* <label className="blabel">Product Dimension</label>
                        <input onChange={productDetailsOnChange} placeholder="L x W x H" name="dimension" className="binput" type="text" />

                        <label className="blabel">Product Weight</label>
                        <input onChange={productDetailsOnChange} name="weight" className="binput" type="number" />

                        <label className="blabel">Product Leather Type</label>
                        <input onChange={productDetailsOnChange} name="typeOfLeather" className="binput" type="text" /> */}

                        <label className="blabel">Product Made In (Country)</label>
                        <input onChange={productDetailsOnChange} name="madeIn" className="binput" type="text" />

                        <label className="blabel">Product Regular Price</label>
                        <input onChange={productOnChange} name="regularPrice" className="binput" type="number" />

                        <label className="blabel">Product Sale Price</label>
                        <input onChange={productOnChange} name="salePrice" className="binput" type="number" />



                        <label className="blabel">Product SEO Tags</label>
                        <div className="tags-wrapper">
                            {
                                tags.map(tag => {
                                    return <span key={tag} className="tag"> {tag} <svg onClick={() => removeTag(tag)} viewBox="0 0 24 24" width={14} height={14} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round" ><line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg></span>
                                })
                            }
                        </div>
                        <form onSubmit={tagInputHandler}>
                            <input value={tagHolder} onChange={tagInputOnChange} placeholder="Type A Tag and Hit Enter" className="binput" type="text" />
                        </form>



                        <label className="blabel">Product Available Variants (Color/Flavour)</label>
                        <div className="tags-wrapper">
                            {
                                colors.map(color => {
                                    return <span style={{ backgroundColor: `${color}` }} className="tag"> {color} <svg onClick={() => removeColor(color)} viewBox="0 0 24 24" width={14} height={14} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round" ><line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg></span>
                                })
                            }
                        </div>
                        <div style={{ display: 'flex', alignItems: 'flex-start' }} className="product-color-box">
                            {/* <SketchPicker color={colorHolder} /> */}
                            <form onSubmit={colorInputHandler}>
                                <input style={{ maxWidth: "200px", marginLeft: '0px' }} value={colorHolder} onChange={colorOnChange} placeholder="Variant Name" className="binput" type="text" />
                                <button type="submit" style={{ width: "200px", marginLeft: '0px', marginTop: '2rem' }} className="bbutton bbutton-primary">Enter Variant</button>
                            </form>
                        </div>



                        {
                            loading ? <button disabled style={{ marginTop: "4rem" }} className="bbutton bbutton-loading"> <img src="/three-dot.svg" width="12%" alt="" /> </button>
                                :
                                <button onClick={onSubmitHandler} type='submit' style={{ marginTop: "4rem" }} className="bbutton bbutton-primary">Create Product & Proceed To Next Step</button>
                        }

                    </div>
                </div>

            </Layout>

        </>
    );
}

export default CreateProduct;