import React, { useState } from 'react';
import { Select, Tag, message, Button, Modal } from 'antd';
import moment from 'moment';
import axios from '../../config/axios';
import { BsCheckCircleFill } from 'react-icons/bs';
import { RiCloseCircleFill } from 'react-icons/ri';
import { MdDirectionsBike } from 'react-icons/md';
import {SiMoneygram} from 'react-icons/si';


const {Option, OptGroup} = Select;

const OrderItem = ({order, setSingleOrder, setSingleUserID, refresh}) => {

    const [zones, setZones] = useState([]);
    const [areas, setAreas] = useState([]);
    const [loading, setLoading] = useState(false);

    const [cities, setCities] = useState([]);
    const [city, setCity] = useState(null);
    const [zone, setZone] = useState(null);
    const [area, setArea] = useState(null);
    const [msg, setMsg] = useState(null);
    const [pathaoData, setPathaoData] = useState(null);

    const getCities = () =>{
        setLoading(true);
        axios.get(`/admin/deliveries/pathao/cities`, {withCredentials: true}).then(response => {
            setCities(response.data.pathao_cities);
        }).catch(error => {
            message.error(error.response.data.msg);
        }).finally(() => {
            setLoading(false);
        })
    }

    const getZones = (city_id) => {
        setCity(city_id);
        setLoading(true);
        axios.get(`/admin/deliveries/pathao/zones?city_id=${city_id}`, {withCredentials: true}).then(response => {
            setZones(response.data.zones)
        }).catch(error => {
            message.error(error.response.data.msg);
        }).finally(() => {
            setLoading(false);
        })
    }


    const createConsignment = () => {
        if (!city) {
            message.error('City is required!');
            return;
        }

        if (!zone) {
            message.error('Zone is required!');
            return;
        }

        // if (!area) {
        //     message.error('Area is required!');
        //     return;
        // }
        //
        setLoading('placing');

        let payload = {
            city: city,
            zone
        }

        axios.post(`/admin/deliveries/pathao/${order.orderID}`, payload, {withCredentials: true}).then(response => {
            setMsg(response.data.msg);
            setPathaoData(response.data.pathao_data);
        }).catch(error => {
            message.error(error.response.data.msg);
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleChange = value => {
        console.log(value);

        axios.put(`/admin/orders/v1/update/${order._id}/${value}`, {}, {withCredentials: true}).then(response => {
            if (response.status === 200) {
                refresh();
                message.success({
                    content: `Order status updated and user was notified!`,
                    style: {
                        marginTop: '85vh',
                        marginRight: '70vw'
                    },
                })
            }
        })
    }
    return (
        <>
            <tr className="align-middle">
                <td className={'d-flex flex-column gap-1'}>
                    <div style={{
                        backgroundColor: 'var(--color-primary-db)',
                        color: 'white',
                        width: 'fit-content',
                        padding: '2px 5px',
                        borderRadius: '5px',
                        fontSize: '11px'
                    }}>
                        {order.orderID}
                        {order.pathao_webhook_data}
                    </div>
                    <span>Token: {order.token}</span>
                    <span>Table: {order.table ? order.table : 'N/A'}</span>
                </td>
                <td> <span className={'text-uppercase'}
                           onClick={() => setSingleUserID(order.customer)}
                           style={{color: '#7FD238', fontWeight: '500', cursor: 'pointer'}}>
                    {order.contact.name}
                    <br/>
                    0{order.contact.phoneNumber}
                </span></td>
                <td>
                    <Tag
                        color={order.sales_channel === 'online' ? 'var(--color-primary-db)' : 'green'}>{order.sales_channel.toUpperCase()}</Tag>
                </td>
                <td>
                    <div className={'d-flex flex-column'}>
                        <span style={{whiteSpace: 'nowrap'}}>{order.totalBill} <span className="taka">৳</span></span>
                        {
                            order.redeemed_promo &&
                            <div className={'d-flex flex-column'}>
                                <span style={{whiteSpace: 'nowrap'}}>Promo: {order.redeemed_promo}</span>
                                <span style={{whiteSpace: 'nowrap'}}>Discount: {order.discount}<span
                                    className="taka"> ৳</span></span>
                            </div>
                        }
                    </div>
                </td>
                <td>
                    <div className={'d-flex flex-column align-items-center'}>
                        <Tag className={'mb-2'}
                             style={{textTransform: 'uppercase'}}
                             color={order.status == 'pending' ? 'orange' : order.status == 'processing' ? 'purple' : order.status == 'delivered' ? 'green' : 'red'}>{order.status}

                        </Tag>
                        {
                            order.sales_channel === 'store' ?
                                <Select value={order.status}
                                        style={{width: 100, border: order.status === 7 ? '1px solid red' : null}}
                                        onChange={handleChange}>
                                    <Option value={'pending'}>Pending</Option>
                                    <Option value={'delivered'}>Delivered</Option>
                                    <Option value={'cancelled'}>Cancelled</Option>

                                </Select>
                                :
                                <Select value={order.status}
                                        style={{width: 100, border: order.status === 7 ? '1px solid red' : null}}
                                        onChange={handleChange}>
                                    {
                                        order.status === 'delivered' ?
                                            <Option value={'cancelled'}>Cancelled</Option>
                                            : order.status === 'processing' ?
                                                <>
                                                    <Option value={'delivered'}>Delivered</Option>
                                                    <Option value={'cancelled'}>Cancelled</Option>
                                                </>
                                                : order.status === 'pending' ?
                                                    <>
                                                        <Option value={'processing'}>Processing</Option>
                                                        <Option value={'cancelled'}>Cancelled</Option>
                                                    </>
                                                    :
                                                    <>

                                                        <Option value={'pending'}>Pending</Option>
                                                        {
                                                            order.sales_channel === 'online' &&
                                                            <Option value={'processing'}>Processing</Option>
                                                        }
                                                        <Option value={'delivered'}>Delivered</Option>
                                                        <Option value={'cancelled'}>Cancelled</Option>
                                                    </>
                                    }
                                </Select>
                        }
                    </div>
                </td>
                <td>
                    <div className={'d-flex justify-content-start ps-4 flex-grow-1'}>
                        {
                            order.payment.status === 'paid' ?
                                <BsCheckCircleFill size={15}
                                                   color={'green'}/>
                                : order.payment.status === 'due' ?
                                <RiCloseCircleFill size={20}
                                                   color={'red'}/>
                            :
                            <SiMoneygram size={15} />
                        }
                    </div>

                </td>
                <td>
                    <div className={'d-flex flex-column'}>
                        <span>{moment(order.timestamp).format('LT')}</span>
                        <span>{moment(order.timestamp).format('DD-MM-YY')}</span>
                    </div>
                </td>

                <td>
                    {
                        order.sales_channel === 'online' ?
                            <>
                                {
                                    order.tracking?.code ?
                                        <div className={'d-flex flex-column'}>
                                            <span style={{textTransform: 'uppercase'}}>{order.tracking.courier} </span>
                                            <span style={{fontSize: '10px'}}>{order.tracking.code}</span>
                                        </div>
                                        :
                                        <Button
                                            type={'link'}
                                            loading={loading}
                                            disabled={loading}
                                            onClick={getCities}
                                        >
                                            <MdDirectionsBike size={20}
                                                              color={'var(--color-primary)'}/>
                                        </Button>
                                }
                            </>
                            :
                            <span>N/A</span>
                    }
                </td>

                <td>
                    <div className={'d-flex gap-2'}>
                        <button onClick={() => setSingleOrder(order)}
                                className="cbutton">
                            <svg aria-hidden="true"
                                 focusable="false"
                                 data-prefix="fas"
                                 data-icon="box-open"
                                 role="img"
                                 xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 640 512">
                                <path fill="currentColor"
                                      d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z"/>
                            </svg>
                        </button>
                    </div>
                </td>
            </tr>


            {
                cities.length > 0 &&
                <Modal
                    open={true}
                    footer={null}
                    title={'Create Pathao Consignment'}
                    onCancel={() => refresh()}
                >
                    {
                        !msg ?
                            <div>
                                <b>User Given Address:</b>
                                <div className={'row'}>
                                    <div className={'col-4 d-flex flex-column gap-2'}>
                                        <span>District: {order.deliveryAddress.district}</span>
                                        <span>Sub District: {order.deliveryAddress.subDistrict}</span>
                                    </div>
                                    <div className={'col-8 d-flex flex-column gap-2'}>
                                        <span>Address Line One: {order.deliveryAddress.addressLineOne}</span>
                                        <span>Address Line Two: {order.deliveryAddress.addressLineTwo}</span>
                                    </div>
                                </div>

                                <br/>
                                <span className={'mb-1 d-block'}>Delivery City</span>
                                <Select
                                    style={{width: '100%'}}
                                    placeholder={'Select Delivery City'}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(value) => getZones(value)}
                                    size={'large'}
                                >
                                    {
                                        cities.map((city, index) => {
                                            return <Select.Option value={city.city_id}
                                                                  key={index}>{city.city_name}</Select.Option>
                                        })
                                    }
                                </Select>

                                <br/>
                                <br/>
                                <span className={'mb-1 d-block'}>Delivery Zone</span>
                                <Select
                                    style={{width: '100%'}}
                                    placeholder={'Select Delivery Zone'}
                                    showSearch={true}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    loading={loading === 'area'}
                                    size={'large'}
                                    onChange={value => setZone(value)}
                                >
                                    {
                                        zones.map((zone, index) => {
                                            return <Select.Option value={zone.zone_id}
                                                                  key={index}>{zone.zone_name}</Select.Option>
                                        })
                                    }
                                </Select>
                                <br/>
                                <br/>
                                <Button
                                    size={'large'}
                                    type={'primary'}
                                    style={{width: '100%'}}
                                    onClick={createConsignment}
                                    loading={loading === 'placing'}
                                    disabled={loading === 'placing'}
                                >
                                    Create Consignment
                                </Button>
                            </div>
                            :
                            <div className={'d-flex flex-column align-items-center'}>
                                <img src="/success.png"
                                     alt=""
                                     style={{width: '100px'}}/>
                                <br/>
                                <b className={'fs-2 text-center'}>{msg}</b>
                                <br/>
                                <b>Delivery Charge: {pathaoData.delivery_fee} Taka</b>
                                <br/>
                                <a href={`/label/${order.orderID}`}
                                   target={'_blank'}>
                                    <Button type={'primary'}
                                            danger={true}
                                            ghost={true}
                                            size={'large'}>Print
                                        Label</Button>
                                </a>
                            </div>
                    }
                </Modal>
            }
        </>
    )
}

export default OrderItem;