import { Drawer, Popover } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiCategory, BiCategoryAlt } from "react-icons/bi";
import { BsBoxSeam } from "react-icons/bs";
import { FaClipboardList } from "react-icons/fa";
import { FiLogOut, FiUsers } from "react-icons/fi";
import { GiHamburgerMenu, GiSmokeBomb } from "react-icons/gi";
import { IoOptionsOutline } from "react-icons/io5";
import { MdContactMail, MdDashboard, MdInventory, MdMoneyOffCsred, MdPointOfSale } from "react-icons/md";
import { SlClose } from "react-icons/sl";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../Context-Apis/AuthContext";
import axios from "../config/axios";

const Layout = (props) => {
    const [className, setClassName] = useState({
        mc: "admin-main-content--alt",
        sb: "admin-sidebar--alt",
        am: "admin-sidebar__menu",
    });

    const [showDrawer, setShowDrawer] = useState(false);

    useEffect(() => {
        if (localStorage.getItem("sidebar-pref") === "admin-main-content--alt") {
            setClassName({
                mc: "admin-main-content--alt",
                sb: "admin-sidebar--alt",
                am: "admin-sidebar__menu",
            });
        } else if (localStorage.getItem("sidebar-pref") === "admin-main-content") {
            setClassName({
                mc: "admin-main-content",
                sb: "admin-sidebar",
                am: "admin-sidebar__menu--alt",
            });
        }

        if (sessionStorage.getItem("cherry")) {
        } else {
            sessionStorage.setItem("cherry", "popped");
        }
    }, [props]);

    //defining layoutTrigger function
    const sidebarOpen = () => {
        if (className.mc === "admin-main-content") {
            setClassName({
                mc: "admin-main-content--alt",
                sb: "admin-sidebar--alt",
                am: "admin-sidebar__menu",
            });
            localStorage.setItem("sidebar-pref", "admin-main-content--alt");
        } else {
            setClassName({
                mc: "admin-main-content",
                sb: "admin-sidebar",
                am: "admin-sidebar__menu--alt",
            });
            localStorage.setItem("sidebar-pref", "admin-main-content");
        }
    };

    //defining logoutHandler function

    return (
        <>
            {/* ⛏🦇 Dashboard Header ⛏🦇 */}
            <header className="admin-header">
                <div className="admin-sidebar__header">
                    {/*open drawer*/}
                    <GiHamburgerMenu size={25} className={"show_tablet mr-3"} onClick={() => setShowDrawer(true)} />

                    <Link to="/">
                        <div className="logo" style={{ marginLeft: "3px" }}>
                            <img src="/logo.svg" alt="" height={"40px"} />
                        </div>
                    </Link>

                    {/*open sidebar*/}
                    <svg
                        onClick={sidebarOpen}
                        style={{
                            fill: "var(--color-grey-dark-2)",
                            color: "var(--color-grey-dark-2)",
                            marginLeft: "2rem",
                            cursor: "pointer",
                        }}
                        className="admin-svg hide_tablet"
                        viewBox="64 64 896 896"
                        focusable="false"
                        data-icon="menu-unfold"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 000-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0014.4 7z" />
                    </svg>
                </div>
                <div className="header-wrapper">
                    <Link to="/orders">
                        <button
                            className="bbutton"
                            style={{
                                marginRight: "5px",
                                color: "var(--color-primary)",
                            }}
                        >
                            Orders
                        </button>
                    </Link>
                    <Link to="/create-order">
                        <button className="bbutton bbutton-primary" style={{ marginRight: "0px" }}>
                            <AiOutlinePlusCircle size={20} />
                            Create Order
                        </button>
                    </Link>
                </div>
            </header>

            <div className={"hide_tablet"}>
                <SidebarComponent className={className} />
            </div>

            {showDrawer && <SidebarDrawer open={showDrawer} setOpen={setShowDrawer} className={className} />}

            {/* ⛏🦇 Main Content Section ⛏🦇 */}
            <section className={className.mc}>{props.children}</section>
        </>
    );
};
export default Layout;

const SidebarDrawer = ({ open, setOpen, className }) => {
    return (
        <Drawer placement="left" open={open} onClose={() => setOpen(false)} width={270} closable={false}>
            <div className={"d-flex justify-content-between align-items-center"}>
                <Link to="/">
                    <div className="logo">
                        <img src="/logo.svg" alt="" height={"40px"} />
                    </div>
                </Link>

                <SlClose size={22} className={"cursor-pointer"} onClick={() => setOpen(false)} />
            </div>

            <div className={"mt-4"}>
                <SidebarComponent className={className} />
            </div>
        </Drawer>
    );
};

// sidebar

const SidebarComponent = ({ className }) => {
    const location = useLocation();
    const { user } = useContext(AuthContext);
    const sidebar_icon_class = "admin-sidebar__menu__item--icon";

    const logoutHandler = () => {
        axios
            .get(`/user/v1/logout`, {
                withCredentials: true,
            })
            .then((_) => {
                localStorage.removeItem("sidebar_pref");
                window.location.replace("https://dashboard.vapexbd.com/login");
            });
    };

    const sidebarItems = [
        {
            title: "Dashboard",
            icon: <MdDashboard className={sidebar_icon_class} />,
            link: "/",
            match_route: ["/"],
        },
        {
            title: "Sales & Orders",
            icon: <MdPointOfSale className={sidebar_icon_class} />,
            link: "/orders",
            match_route: ["/orders"],
        },
        {
            title: "Products",
            icon: <FaClipboardList className={sidebar_icon_class} />,
            link: "/products",
            match_route: ["/products"],
        },
        {
            title: "Customers",
            icon: <FiUsers className={sidebar_icon_class} />,
            link: "/customers",
            match_route: ["/customers"],
        },
        {
            title: "Expense",
            icon: <MdMoneyOffCsred className={sidebar_icon_class} />,
            link: "/create-expense",
            match_route: ["/create-expense", "/expenses"],
        },
        {
            title: "Inventory",
            icon: <MdInventory className={sidebar_icon_class} />,
            link: "/inventory",
            match_route: ["/inventory-in", "/inventory-out", "/inventory"],
        },
        {
            title: "Contact Submissions",
            icon: <MdContactMail className={sidebar_icon_class} />,
            link: "/contact-submissions",
            match_route: ["/contact-submissions"],
        },
    ];

    const extraOptions = [
        {
            title: "Brands",
            icon: <GiSmokeBomb className={sidebar_icon_class} />,
            link: "/brands",
            match_route: ["/brands"],
        },
        {
            title: "Categories",
            icon: <BiCategory className={sidebar_icon_class} />,
            link: "/categories",
            match_route: ["/categories"],
        },
        {
            title: "Sub Categories",
            icon: <BiCategoryAlt className={sidebar_icon_class} />,
            link: "/sub-categories",
            match_route: ["/sub-categories"],
        },
    ];

    return (
        <>
            {/* ⛏🦇 Dashboard Sidebar ⛏🦇 */}
            <nav className={className.sb}>
                <div className="admin-sidebar__user-box">
                    <h3 style={{ marginBottom: "1.5rem" }} className="admin-sidebar__user-box__greeting">
                        Howdy! {user.name}
                    </h3>
                    <Link to="/orders">
                        <button className="bbutton bbutton-primary">
                            <BsBoxSeam size={20} />
                            Check All Orders
                        </button>
                    </Link>
                </div>

                <ul className="admin-sidebar__menu">
                    <label className="admin-sidebar__menu__title">M E N U</label>

                    {sidebarItems.map((item, index) => (
                        <Link to={item.link} key={index}>
                            <li
                                className={
                                    item.match_route.includes(location.pathname)
                                        ? "admin-sidebar__menu__item active"
                                        : "admin-sidebar__menu__item"
                                }
                            >
                                {item.icon}
                                <span className="admin-sidebar__menu__item--title">{item.title}</span>
                            </li>
                        </Link>
                    ))}

                    <Popover
                        trigger={"click"}
                        content={
                            <div className={"d-flex flex-column gap-3"}>
                                {extraOptions.map((option, index) => {
                                    return (
                                        <Link to={option.link} key={index}>
                                            {option.icon}
                                            {option.title}
                                        </Link>
                                    );
                                })}
                            </div>
                        }
                        placement={"right"}
                    >
                        <li className="admin-sidebar__menu__item">
                            <IoOptionsOutline className="admin-sidebar__menu__item--icon" />
                            <span className="admin-sidebar__menu__item--title">Options</span>
                        </li>
                    </Popover>
                    <li onClick={logoutHandler} className="admin-sidebar__menu__item">
                        <FiLogOut className="admin-sidebar__menu__item--icon" />
                        <span className="admin-sidebar__menu__item--title">Log Out</span>
                    </li>
                </ul>
            </nav>

            {/* short menu */}
            <nav className="short-menu">
                <ul>
                    {sidebarItems.map((item, index) => (
                        <Link to={item.link} key={index}>
                            <li
                                className={
                                    item.match_route.includes(location.pathname)
                                        ? "short-menu__item sm-active"
                                        : "short-menu__item"
                                }
                            >
                                {item.icon}
                            </li>
                        </Link>
                    ))}

                    <Popover
                        trigger={"click"}
                        content={
                            <div className={"d-flex flex-column gap-3"}>
                                {extraOptions.map((option, index) => {
                                    return (
                                        <Link to={option.link} key={index}>
                                            {option.icon}
                                            {option.title}
                                        </Link>
                                    );
                                })}
                            </div>
                        }
                        placement={"right"}
                    >
                        <li className="admin-sidebar__menu__item">
                            <IoOptionsOutline size={20} />
                        </li>
                    </Popover>

                    <li className="admin-sidebar__menu__item">
                        <FiLogOut size={20} />
                    </li>
                </ul>
            </nav>
        </>
    );
};
