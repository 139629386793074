import React, { useState } from 'react';
import axios from '../../config/axios';

//importing all components
import VariantItem from './VariantItem'

const VariantsCard = ({ variants, productID, refresh }) => {

    return (
        <>
            <table className="table">
                <tbody>
                    <tr style={{ border: "1px solid var(--color-grey-green)" }}>
                        <th scope="row">SKU</th>
                        <th scope="row">Name</th>
                        <th scope="row">PxP</th>
                        <th scope="row">Is Hidden?</th>
                        <th scope="row">Stock</th>
                        <th scope="row">Discount</th>
                        <th scope="row">Action</th>
                    </tr>


                    {/* Doing The Loop Here */}
                    {
                        variants.map(variant => {
                            return <VariantItem
                                variant={variant}
                                productID={productID}
                                key={'variantitem'}
                                refresh={refresh}
                            />
                        })
                    }


                </tbody>
            </table>
            {
                variants.length === 0 ?
                    <div className="no-resource">
                        <img src="https://res.cloudinary.com/binary-poets/image/upload/v1608047931/static/image-404_vqmghx.png" alt="" />
                        <label htmlFor="">No variant of this product were added to database</label>
                    </div>
                    :
                    null
            }

        </>

    );
}

export default VariantsCard;