import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from '../config/axios';


//importing all components
import {Pagination} from 'antd';
import Layout from '../Components/Layout';
import ProductItem from '../Components/Products/ProductItem';
import ProductDetails from '../Components/Products/ProductDetails';


const Products = () => {

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresher, setRefresher] = useState(null);
  const [singleProduct, setSingleProduct] = useState(null);
  const [sort, setSort] = useState('latest');
  const [stat, setStat] = useState({totalPage: '', totalData: ''});
  const [page, setPage] = useState(1);


  //using useEffect to get all products
  useEffect(() => {
    setLoading(true);
    axios.get(`/admin/products/v1/${page}/?sort=${sort}`, {withCredentials: true}).then(response => {

      console.log(response.data);

      setProducts(response.data.products);
      setStat({
        totalPage: response.data.totalPage,
        totalData: response.data.totalProduct
      });
      setLoading(false);

    });
  }, [refresher, sort, page])

  //defining paginationHandler function
  const paginationHandler = (page) => {
    console.log(page);
    setPage(page);
  }

  //the refresh function
  const refresh = () => {
    setRefresher(Math.random());
  }

  //defining onChangeSort handler
  const onChangeSort = (e) => {
    setSort(e.target.value);
  }

  return (
    <>
      <Layout>

        <h2 className="breadcrumb-custom"><span>Dashboard</span> {'>'} <span>Products</span></h2>

        <div className="dashboard-page-header">
          <div className="dashboard-page-header__left">
            <h3 className="dashboard-page-header__left--title">All Products</h3>
          </div>
          <div className="dashboard-page-header__right">

            <select onChange={onChangeSort} className="bselect" name="" id="">
              <option value="latest">Sort By Latest</option>
              <option value="most-sold">Sort By Most Sold</option>
              <option value="least-sold">Sort By Least Sold</option>
            </select>

            <form>
              <input className="binput" placeholder="Search By Name" type="text" name="" id=""/>
            </form>

            <Link to='/products/create'>
              <button className="bbutton bbutton-primary">
                <svg className="admin-svg white" viewBox="0 0 24 24" width={24} height={24} stroke="currentColor"
                     strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <line x1={12} y1={5} x2={12} y2={19}/>
                  <line x1={5} y1={12} x2={19} y2={12}/>
                </svg>
                Add New Product
              </button>
            </Link>
          </div>
        </div>


        {/* All Products Here */}
        <div className="dashboard-table-wrapper">
          {
            loading ?
              <div style={{height: '60vh'}} className="loader"><img src="/loader.svg" alt=""/></div>

              :
              <>
                <table className="table">
                  <tbody>
                  <tr style={{borderBottom: "1px solid var(--color-grey-green)"}}>
                    {/*<th scope="row" className={'hide_tablet'}>SKU & Stock</th>*/}
                    <th scope="row">Image</th>
                    <th scope="row">Name</th>
                    <th scope="row">Category</th>
                    {/*<th scope="row">Brand</th>*/}
                    <th scope="row">Sale Price</th>
                    <th scope="row">Visibility</th>
                    <th scope="row">Unit Sold</th>
                    <th scope="row">Action</th>
                    <th scope="row">Details</th>
                  </tr>


                  {/* Doing The Loop Here */}
                  {
                    products.map(product => {
                      return <ProductItem
                        key={product._id}
                        product={product}
                        refresh={refresh}
                        setSingleProduct={setSingleProduct}
                      />
                    })
                  }

                  </tbody>
                </table>


              </>
          }

          {/* conditionally rendering pagination */}
          {
            products.length === 0 ?
              null
              :
              <div className="pagination">
                <Pagination showSizeChanger={false} pageSize={15} total={stat.totalData} onChange={paginationHandler}/>

                <span> Total <span className='pagination-span'>{stat.totalData}</span> Products </span>
              </div>
          }

        </div>


        {/* Single Product Details Drawer */}
        {

          <ProductDetails
            singleProduct={singleProduct}
            setSingleProduct={setSingleProduct}
          />
        }

      </Layout>
    </>
  );
}
export default Products;