import React, { useEffect, useState } from 'react';
import axios from '../../config/axios';
import styles from './Label.module.scss';
import { Col, Row } from "antd";
import moment from '../../config/moment';
import Barcode from 'react-barcode';

const Label = (props) => {

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);

    useEffect(() => {
        // console.log(props.match.params.invoiceID);
        axios.get(`/admin/orders/v1/${props.match.params.invoiceID}`, {withCredentials: true}).then(response => {
            console.log(response.data);
            setOrder(response.data.order);
            setTimeout(() => {
                window.print()
            }, 200);
        })
    }, []);

    const getOrderSubTotal = () => {
        let subTotal = 0;
        order.products.forEach(item => {
            subTotal = subTotal + (item.price * item.quantity);
        })

        return subTotal;
    }

    return (
        <div className={styles.label}>
            {
                order &&
                <div className={styles.body}>
                    <Row>
                        <Col span={12}>
                            <div className={'d-flex flex-column gap-1 p-2 ' + styles.right_b}>
                                <span className={styles.badge}>From,</span>
                                <img style={{width: '100px', marginBottom: '2px', marginTop: '2px'}}
                                     src="https://codenine-bucket.blr1.cdn.digitaloceanspaces.com/vapex/static/vapex-logo-black-white.webp"
                                     alt=""/>
                                <span>Y/21 Rajia Sultana Road, Mohammadpur</span>
                                <span>www.vapexbd.com</span>
                                <span>01798557540</span>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div className={'d-flex flex-column p-2'}>
                                <span className={styles.badge}>Ship To,</span>
                                <span>{order.contact.name}</span>
                                <span style={{ overflowWrap: 'break-word' }}>{order.deliveryAddress.addressLineOne}</span>
                                {
                                    order.deliveryAddress.addressLineTwo &&
                                    <span>{order.deliveryAddress.addressLineTwo}</span>
                                }
                                <span>{order.deliveryAddress.subDistrict}</span>
                                <span>0{order.contact.phoneNumber}</span>
                            </div>
                        </Col>
                        <Col span={24}>
                            <div className={styles.hr}/>
                        </Col>
                        <Col span={6}>
                            <div className={'d-flex flex-column p-2 ' + styles.right_b}>
                                <span className={'w-bold'}>Shipping:</span>
                                <span>{order.tracking.courier.toUpperCase()}</span>
                                {/*<span>{order.tracking.code}</span>*/}
                            </div>
                        </Col>
                        <Col span={14}>
                            <Barcode
                                value={order.tracking.code}
                                // width={.77}
                                width={1}
                                height={40}
                                format={'CODE39'}
                            />
                        </Col>
                        <Col span={24}>
                            <div className={styles.hr}/>
                        </Col>
                        <Col span={24}>
                            <div className={'d-flex flex-column p-2'}>
                                <div className={'d-flex justify-content-between'}>
                                    <span>Order ID: <span className={'fw-bold'}>{order.orderID}</span></span>
                                    <span>{moment(order.timestamp).format('DD-MM-YY LT')}</span>
                                </div>
                                <div style={{fontSize: '13px', fontWeight: 'bold'}}>
                                    Notes: {order.orderNote}
                                </div>
                                <table className={styles.table}>
                                    <tbody>
                                    <tr>
                                        <th>SL</th>
                                        <th>Item</th>
                                        <th>Qty</th>
                                        <th style={{textAlign: 'right'}}>Amount</th>
                                    </tr>
                                    {
                                        order.products.map((item, index) => {
                                            return <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.name.includes('-') ? item.name : item.name + '-' + item.color}</td>
                                                <td>{item.quantity}</td>
                                                <td style={{textAlign: 'right'}}>{item.quantity * item.price}৳</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                                <div className={'d-flex flex-column align-items-end'} style={{position: 'relative'}}>
                                    <span className={'fw-bold'}>Delivery Charge: {order.deliveryCharge}৳</span>
                                    <span className={'fw-bold'}>Discount: {order.discount}৳</span>
                                    <span className={'fw-bold'}>Order Total: {order.totalBill}৳</span>
                                    <span className={styles.payment_status}>{order.payment.status}</span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
        ;
};

export default Label;