import { Button, Form, Input, Modal, message } from "antd";
import { useState } from "react";
import axios from "../config/axios";

const AddInventoryModal = ({ showModal, setShowModal, setRefresh }) => {
    const [addItemLoading, setAddItemLoading] = useState(false);

    const handleAddInventoryItem = async (values) => {
        try {
            setAddItemLoading(true);
            await axios.post("/admin/inventory/items/v1", values, { withCredentials: true });
            message.success("Item added successfully!");
            setShowModal(false);
            setRefresh(Math.random());
        } catch (error) {
            message.error("Something went wrong! Please try again later.");
        } finally {
            setAddItemLoading(false);
        }
    };

    return (
        <Modal title="Add Inventory Items" open={showModal} onCancel={() => setShowModal(false)} centered footer={null}>
            <Form onFinish={handleAddInventoryItem} layout="vertical" autoComplete="off" scrollToFirstError requiredMark={false}>
                <Form.Item
                    label="Item Name"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: "Please input item name!",
                        },
                    ]}
                >
                    <Input size="large" />
                </Form.Item>
                <Form.Item
                    label="Item Unit"
                    name="unit"
                    rules={[
                        {
                            required: true,
                            message: "Please input item unit!",
                        },
                    ]}
                >
                    <Input size="large" />
                </Form.Item>

                <Form.Item className="pt-2 mb-2">
                    <Button type="primary" htmlType="submit" block size="large" loading={addItemLoading} disabled={addItemLoading}>
                        Add Item
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddInventoryModal;
