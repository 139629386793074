import { Button, Card, DatePicker, Table, message } from "antd";
import Layout from "../../Components/Layout";
import { Link } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useEffect, useState } from "react";
import axios from "../../config/axios";
import moment from "../../config/moment";

const Expenses = () => {
    const [loading, setLoading] = useState(true);
    const [moreLoading, setMoreLoading] = useState(false);
    const [expenses, setExpenses] = useState({
        data: [],
        page: 1,
        total: 0,
        limit: 20,
        date: "",
    });

    // fetch expenses
    useEffect(() => {
        (async () => {
            try {
                setMoreLoading(true);
                const res = await axios.get("/admin/inventory/expenses/v1", {
                    withCredentials: true,
                    params: {
                        page: expenses.page,
                        limit: expenses.limit,
                        date: expenses.date ? expenses.date : undefined,
                    },
                });
                setExpenses((prev) => ({
                    ...prev,
                    data: res.data.expense_records,
                    total: res.data.count,
                }));
            } catch (error) {
                console.log(error);
                message.error("Error while fetching expenses!");
            } finally {
                setLoading(false);
                setMoreLoading(false);
            }
        })();
    }, [expenses.date, expenses.limit, expenses.page]);

    return (
        <Layout>
            <Card
                bordered={false}
                bodyStyle={{
                    padding: 15,
                }}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">All Expense Record</h3>
                    <Link to={"/create-expense"}>
                        <Button type="primary" className="d-flex align-items-center justify-content-center gap-2">
                            <AiOutlinePlusCircle size={20} />
                            <span>Create New Expense Record</span>
                        </Button>
                    </Link>
                </div>
            </Card>
            <br />
            <div>
                {loading ? (
                    <Card bordered={false}>
                        <div className="d-flex align-items-center justify-content-center" style={{ height: "60vh" }}>
                            <img src="/loader.svg" alt="loader" height={20} />
                        </div>
                    </Card>
                ) : (
                    <Card
                        bordered={false}
                        bodyStyle={{
                            padding: 15,
                        }}
                    >
                        <div className="d-flex justify-content-end mb-4">
                            <DatePicker
                                onChange={(_, dateString) => {
                                    setExpenses((prev) => ({ ...prev, date: dateString }));
                                }}
                                placeholder="Filter by date"
                                format={"YYYY-MM-DD"}
                                size="large"
                            />
                        </div>
                        <Table
                            dataSource={expenses.data}
                            columns={[
                                {
                                    title: "Created By",
                                    dataIndex: "submitted_by",
                                    key: "submitted_by",
                                    render: (submitted_by) => <span>{submitted_by.name}</span>,
                                },
                                {
                                    title: "Date",
                                    dataIndex: "date",
                                    key: "date",
                                    render: (date) => <span>{moment(date).format("DD MMM YYYY")}</span>,
                                },
                                {
                                    title: "Category",
                                    dataIndex: "category",
                                    key: "category",
                                },
                                 {
                                    title: "Expense For",
                                    dataIndex: "expense_for",
                                    key: "expense_for",
                                     render: (item)=><span style={{textTransform: 'capitalize'}}>{item}</span>
                                },
                                {
                                    title: "Amount(৳)",
                                    dataIndex: "amount",
                                    key: "amount",
                                },
                                {
                                    title: "Amount Source",
                                    dataIndex: "amount_source",
                                    key: "amount_source",
                                },
                                {
                                    title: "Description",
                                    dataIndex: "description",
                                    key: "description",
                                },
                            ]}
                            className="custom-ant-table"
                            rowKey={(record) => record._id}
                            loading={moreLoading}
                            bordered
                            pagination={{
                                pageSize: 20,
                                total: expenses.total,
                                onChange: (page) => {
                                    window.scrollTo(0,0)
                                    setExpenses((prev) => ({
                                        ...prev,
                                        page,
                                    }));
                                    setMoreLoading(true);
                                },
                            }}
                        />
                    </Card>
                )}
            </div>
        </Layout>
    );
};

export default Expenses;