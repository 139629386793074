import React from 'react';
import { useEffect, useState } from 'react';
import axios from '../config/axios';

//importing all components
import { message, Tag } from 'antd';
import Layout from '../Components/Layout';

//importing icons
import { AiOutlineCloseCircle } from "react-icons/ai";

const EditGallery = (props) => {

    const [gallery, setGallery] = useState([]);
    const [color, setColor] = useState('');
    const [refresh, setRefresh] = useState();
    const [calculating, setCalculating] = useState(true);
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState({});
    const [forceReRender, setForceReRender] = useState(null);

    useEffect(() => {
        axios.get(`/admin/products/v1/single/${props.match.params.productID}`, { withCredentials: true }).then(response => {
            // console.log(props.match.params.galleryIndex);
            console.log(response.data.product);
            setProduct(response.data.product);
            setGallery(response.data.product.gallery[props.match.params.galleryIndex].images);
            setCalculating(false);
        });
    }, [refresh]);

    useEffect(() => {
        setGallery(gallery);
    }, [forceReRender]);


    function removeItemOnce(arr, value) {
        var index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }


    //defining removeImageFromGallery function
    const removeImageFromGallery = image => {
        console.log(gallery);
        setGallery(removeItemOnce(gallery, image));
        setForceReRender(Math.random());
    }


    //defining handleGalleryDrop function
    const handleGalleryDrop = (e, images, color) => {
        console.log(images);

        for (let i = 0; i < images.length; i++) {
            console.log(images[i].name);

            let formData = new FormData;
            formData.append('image', images[i]);
            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            axios.post(`/admin/microservices/v1/imageUpload`, formData, config).then(responese => {
                console.log(responese.data.info);
                setGallery(gallery => gallery.concat(responese.data.info.Location));
            });
        }
    }


    //defining onSubmitHandler function
    const onSubmitHandler = () => {


        setLoading(true);

        let formData = new FormData;

        formData.append('productID', props.match.params.productID);
        formData.append('gallery', JSON.stringify(gallery));
        formData.append('color', product.gallery[props.match.params.galleryIndex].color);
        formData.append('galleryIndex', props.match.params.galleryIndex);

        axios.put(`/admin/products/v1/gallery-update/${props.match.params.productID}`, formData, { withCredentials: true }).then(responese => {

            setLoading(false);

            message.success({
                content: `Product Gallery Added Successfully!`,
            })

            setTimeout(() => {
                props.history.push('/products');
            }, 500);

        });

    }

    return (
        <>


            <Layout>
                {
                    calculating ? <div style={{ height: '80vh' }} className="loader"><img src="/loader.svg" alt="" /></div>
                        :
                        <>
                            <div style={{ padding: '0 4rem' }}>
                                <h2 className="breadcrumb-custom"> <span>Dashboard</span> {'>'} <span>Products</span> {'>'} <span> Edit Product Gallery</span></h2>
                                <h1 style={{ marginBottom: '0', fontSize: '1.7rem' }}>Add Gallery of Product ID: {props.match.params.productID} <br /> Name: {product.name} </h1>
                                <h1 style={{ display: 'flex', alignItems: 'center' }}>Galleries Added:  <Tag style={{ marginLeft: '.5rem' }} color="error" > {product.gallery ? product.gallery.length : null} of total {product.colors ? product.colors.length : null} </Tag> </h1>
                            </div>

                            <div style={{ backgroundColor: 'var(--color-grey-green)', padding: '4rem', display: 'flex', flexDirection: 'column' }} className="dashboard-full">


                                <label className="blabel">Add Gallery For Color Variant - <Tag color="#108ee9"> {product.gallery[props.match.params.galleryIndex].color} </Tag></label>
                                <div style={{ width: '100%' }} className="file-input-master-wrapper">
                                    <label multiple className="file-input-box">
                                        <svg className="file-input-svg" xmlns="http://www.w3.org/2000/svg" width={20} height={17} viewBox="0 0 20 17"><path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z" /></svg>
                                        <input className="s-file-upload" onChange={(e) => handleGalleryDrop(e, e.target.files, color)} type="file" id="file-6" multiple />
                                        <span className="file-input-label">Please Select 5 Images of same height and width</span>
                                    </label>
                                    <div className="file-input-master-wrapper__gallery">
                                        {
                                            gallery.map(image => {
                                                return <div className="image-div-with-delete-button" onClick={() => removeImageFromGallery(image)} key={image}>
                                                    <AiOutlineCloseCircle size={20} />
                                                    <img style={{ width: '100%' }} className="file-input-master-wrapper__gallery--image" src={image} alt="" />
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>



                                {
                                    loading ? <button disabled style={{ marginTop: "4rem", width: '100%' }} className="bbutton bbutton-loading"> <img src="/three-dot.svg" width="5%" alt="" /> </button>
                                        :
                                        <button onClick={onSubmitHandler} style={{ marginTop: '4rem', width: '100%' }} className="bbutton bbutton-primary">Update Gallery</button>
                                }

                            </div>
                        </>
                }

            </Layout>
        </>
    );
}

export default EditGallery;