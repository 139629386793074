import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from '../../config/axios';

//importing all components
import { message, Drawer, Row, Col, Tag, Badge, Checkbox } from 'antd';
import {
    FileImageOutlined
} from '@ant-design/icons';
import VariantsCard from '../../Components/Products/VariantsCard';

//importing icons
import { FiEdit } from "react-icons/fi";


const ProductDetails = ({ singleProduct, setSingleProduct, singleProductID, setSingleProductID, history }) => {

    const [product, setProduct] = useState({});
    const [visibility, setVisibility] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if (singleProduct) {
            setVisibility(true);
            setProduct(singleProduct);
            setLoading(false);
        } else if (singleProductID) {
            console.log(singleProductID);
            //opening drawer if productID prop is passed
            setVisibility(true);
            //setLoading(true);

            axios.get(`/admin/products/v1/single/${singleProductID}`, { withCredentials: true }).then(response => {

                setProduct(response.data.product);
                console.log(response.data.product);
                setLoading(false);

            }).catch(error => {
                if (error.response.status === 404) {
                    message.error({
                        content: `No product associated with this id was found!`,
                    })
                }
            })
        }

    }, [singleProductID, singleProduct]);


    //defining onClose function
    const onCloseHandler = () => {

        if (setSingleProduct) {
            setSingleProduct(null);
        }

        if (setSingleProductID) {
            setSingleProductID(null);
        }

        setVisibility(false);
        setLoading(true);

    }

    return (
        <>

            <Drawer
                width={840}
                placement="right"
                closable={true}
                onClose={onCloseHandler}
                visible={visibility}
            >
                {
                    loading ?
                        <div style={{ height: '60vh' }} className="loader"><img src="/loader.svg" alt="" /></div>
                        :
                        <>
                            <h3 className="drawer-title">Product Info</h3>
                            <div className="ant-drawer-body-wrapper">

                                <p style={{ marginTop: '2rem' }} className="title-primary">
                                    <FileImageOutlined />
                                    Product Info
                                </p>
                                <Row>
                                    <Col span={24}>
                                        <div className="info">
                                            {/* <span className="info__1">Featured Image: </span> */}
                                            <br />
                                            <img width="320px" src={product.featuredImage} alt="" />
                                        </div>

                                        <div className="info">
                                            <span className="info__1">Product Name: </span>
                                            <span className="info__2">{product.name}</span>
                                        </div>

                                        <div className="info">
                                            <span className="info__1">Product Description: </span>
                                            <span className="info__2">{product.description}</span>
                                        </div>

                                        <div className="info">
                                            <span className="info__1">Is Product Refundable: </span>
                                            <Checkbox style={{ marginTop: '2rem' }} checked={product.isRefundable}></Checkbox>
                                        </div>

                                        <div className="info">
                                            <span className="info__1">Product Category: </span>
                                            {/* <Tag color="var(--color-awesome)">{product.category}</Tag> */}
                                            <span className="info__2">{product.category.name}</span>
                                        </div>

                                        <div className="info">
                                            <span className="info__1">Product Brand: </span>
                                            {/* <Tag color="var(--color-ok)">{product.subCategory}</Tag> */}
                                            <span className="info__2">{product.brand.name}</span>
                                        </div>

                                        <div className="info">
                                            <span className="info__1">Product Sale Price: </span>
                                            <Tag color="var(--color-red)">{product.salePrice} BDT</Tag>
                                        </div>

                                        <div className="info">
                                            <span className="info__1">Product Regular Price: </span>
                                            <Tag color="var(--color-gold)">{product.regularPrice} BDT</Tag>
                                        </div>

                                        <div className="info">
                                            <span className="info__1">Product Tags: </span>
                                            {
                                                product.tags.map(tag => {
                                                    return <Badge style={{ marginRight: '.5rem', backgroundColor: 'var(--color-primary-db)' }} count={tag} />
                                                })
                                            }
                                        </div>

                                        <div className="info">
                                            <span className="info__1">Product Avaliable Colors: </span>
                                            {
                                                product.colors.map(color => {
                                                    return <Badge style={{ marginRight: '.5rem', backgroundColor: 'transparent', color: 'var(--color-primary-db)', border: '1px solid var(--color-primary-db)' }} count={color} />
                                                })
                                            }
                                        </div>




                                        {/* <div className="file-input-master-wrapper__gallery">
                                            {
                                                gallery.map(image => {
                                                    return <img className="file-input-master-wrapper__gallery--image" src={image} alt="" />
                                                })
                                            }
                                        </div> */}


                                    </Col>
                                </Row>

                                <p style={{ marginTop: '2rem' }} className="title-primary">
                                    <FileImageOutlined />
                                    Product Gallery
                                </p>

                                <Row>
                                    <Col span={24}>
                                        {
                                            product.gallery.map((galleryItem, index) => {
                                                return (
                                                    <>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <Tag icon={<FileImageOutlined />} color="default">
                                                                {galleryItem.color}
                                                            </Tag>
                                                            <Link to={`/products/edit-gallery/${product._id}/${index}`}>
                                                                <FiEdit size={18} style={{ marginBottom: '.3rem', cursor: 'pointer' }} color={'var(--color-red)'} />
                                                            </Link>
                                                        </div>
                                                        <br />
                                                        <div className="file-input-master-wrapper__gallery">
                                                            {
                                                                galleryItem.images.map(image => {
                                                                    return <img className="file-input-master-wrapper__gallery--image" src={image} alt="" />
                                                                })
                                                            }
                                                        </div>
                                                        <br />
                                                    </>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>

                                <p style={{ marginTop: '2rem' }} className="title-primary">
                                    <FileImageOutlined />
                                    Product Variants
                                </p>

                                <VariantsCard
                                    variants={product.variant}
                                />
                            </div>


                        </>
                }


            </Drawer>



        </>
    )
}

export default ProductDetails;