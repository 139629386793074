import axios from 'axios';

// const BASE_URL = 'http://localhost:5000/api';
const BASE_URL ='https://api.vapexbd.com/api';

//creating an axios instance
const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 15000,
});

axiosInstance.interceptors.request.use((config) => {

    if (config.withCredentials) {
        config.headers["accesstoken"] = localStorage.getItem('sidebar_pref');
    }

    return config;
});


export default axiosInstance;
