import React, { useState, useContext } from 'react';
import axios from '../config/axios';
import { AuthContext } from '../Context-Apis/AuthContext';
import {
    message
} from 'antd';

const Login = (props) => {

    const [user, setUser] = useState({ phoneNumber: '', password: '' });
    const [loading, setLoading] = useState(false);
    const authContext = useContext(AuthContext);

    //defining onChange handler 
    const onChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }


    //defining onSubmitHandler function
    const onSubmitHandler = (e) => {
        e.preventDefault();

        if (!user.phoneNumber || !user.password) {
            message.error({
                content: 'Please provide username and password'
            })
        } else {
            setLoading(true);

            axios.post(`/user/v1/login`, user, { withCredentials: true }).then(response => {


                if (response.data.user.role.includes('UN7vBlHb')) {

                    authContext.setUser(response.data.user);
                    authContext.setIsAuthenticated(true);
                    localStorage.setItem('sidebar_pref', response.data.token);
                    props.history.push('/');

                } else {
                    setLoading(false);
                    message.error({
                        content: 'Unauthorized'
                    })
                }

            }).catch(error => {

                setLoading(false);

                if (error.response.status === 401) {
                    message.error({
                        content: 'Invalid credentials'
                    })
                } else if (error.response.status === 429) {
                    message.error({
                        content: 'Please contact our engineering team 01792056627!',
                        duration: 20
                    })
                }
            })
        }
    }

    return (
        <>
            <div className="login-container">
                <img src="/logo.svg" alt="" style={{width: '100px'}}/>
                <form onSubmit={onSubmitHandler}>
                    <input required onChange={onChange} value={user.phoneNumber} name="phoneNumber" placeholder="Phone Number" style={{ marginBottom: '2rem' }} className="binput" maxLength="11" minLength="11" type="number" />
                    <input required onChange={onChange} value={user.password} name="password" placeholder="Password" className="binput" type="password" />


                    {
                        loading ? <button disabled style={{ width: "100%", marginTop: "4rem" }} className="bbutton bbutton-loading"> <img src="/three-dot.svg" width="12%" alt="" /> </button>
                            :
                            <button style={{ marginTop: '4rem', width: '100%' }} className="bbutton bbutton-primary">Login</button>
                    }
                </form>
            </div>
        </>
    );
}

export default Login;